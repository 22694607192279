import * as React from 'react';
import NoAccountHeader from "../Navbars/NoAccountHeader"
import NoAccountFooter from "../Navbars/NoAccountFooter"
import styles from './UnderMaintenance.module.css';

export default function UnderMaintenance(props) {





    return (<>
        <div>
            <NoAccountHeader />
           
            <div>
                <div className='ContentContainer' style={{backgroundImage: 'linear-gradient(-76.5297deg, rgb(6, 33, 61) 29.3842%, rgb(31, 182, 215) 100%)', minHeight:'500px'  }}>
                    <div className='ContentArea' style={{alignSelf:'center'}}>
                    <div style={{ textAlign: 'center', padding: '50px 0', }}>
                        <div style={{ fontSize: '24px', marginBottom: '20px', color:'var(--intertek-yellow-1)' }}>Our System is Currently Under Maintenance</div>
                        <img src='/assets/images/industry-mining.svg' style={{ height: '200px' }} />
                    </div>

                    
                    </div>
                </div>

                {/* <div className='ContentContainer'>
                    <div className='ContentArea' style={{ alignSelf: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        </div>
                    </div>
                </div> */}
            </div>
            <NoAccountFooter />
        </div>

        
    </>)
}