import * as React from 'react';
import { UserContext } from '../../../UserContext';
import styles from './AcceptanceForm.module.css';
import SignatureCanvas from 'react-signature-canvas'
import IconButton from '../../../IconButton/IconButton';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import OutlineButton from '../../../Buttons/OutlineButton';
import CustomerService from '../../../../services/CustomerService';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function AcceptanceForm(props) {
    const { user, onUserContextChanged, userContextChanged } = React.useContext(UserContext);
    const sigCanvasRef = React.useRef(null);
    const [hasSignature, setHasSignature] = React.useState(false);
    const [signedDate, setSignedDate] = React.useState('');
    const [companyName, setCompanyName] = React.useState(user.viewingQuote.signedBy !== null ? user.viewingQuote.companyName : '');
    const [signedByName, setSignedByName] = React.useState(user.viewingQuote.signedBy !== null ? user.viewingQuote.signedBy : '');
    const [formErrors, setFormErrors] = React.useState({});
    const [signatureImage, setSignatureImage] = React.useState('');
    const signedTimestamp = React.useRef(null);
    
    React.useEffect(() => {
        if (user.viewingQuote.signedBy !== null) {
            const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            setSignedDate(convertToLocalTime(user.viewingQuote.signedDateTimeUTC));
            CustomerService.file('GET', '', 'CustomerQuote/GetSignature?quoteId=' + user.viewingQuote.id)
                .then(async (blob) => {
                    // Create an object URL and set it as the image source
                    setSignatureImage(URL.createObjectURL(blob));
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    }, []);

   

    const convertToLocalTime = (utcDate) => {
        // Get the user's current time zone
        const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      
        // Convert the date from UTC to the local time zone
        console.log(dayjs.utc(utcDate).tz(currentTimezone).format('dddd, MMMM D, YYYY h:mm A'));
        return dayjs.utc(utcDate).tz(currentTimezone).format('dddd, MMMM D, YYYY h:mm A');
    };
    

    function handleSignatureEnd(event) {
        setHasSignature(true);
        const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setSignedDate(new dayjs().format('dddd, MMMM D, YYYY h:mm A') + ' ' + currentTimezone);
        signedTimestamp.current = dayjs();
    }

    function clearSignature() {
        sigCanvasRef.current.clear();
        setHasSignature(false);
        setSignedDate('');
        signedTimestamp.current=null;
    }

    function validateAndSave() {
        let errors = {};
        let isValid = true;
        if (!hasSignature && user.viewingQuote.signedBy === null) {
            errors.signature = 'Missing Signature';
            isValid = false;
        }

        if (signedByName.trim() == '') {
            errors.signedByName = 'Required';
            isValid = false;
        }
        if (companyName.trim() == '') {
            errors.companyName = 'Required';
            isValid = false;
        }

        if (isValid) {
            props.onAccepted(signedByName.trim(), companyName.trim(), sigCanvasRef.current.toDataURL(), signedTimestamp.current.utc().toISOString())
        }
        setFormErrors(errors);
    }

    return (<div componentname='AcceptanceForm'>
        <div style={{backgroundColor:'var(--intertek-blue-2)', padding:'4px 12px 4px 12px', marginTop:'2px', marginBottom:'2px'}}>
            <span style={{color:'var(--intertek-blue-7)', fontWeight:'bold'}}>* To Accept this proposal,</span>
            <span style={{color:'red'}}> please have an authorized representative complete and sign this authorization page and return it along with the entire proposal and completed Sample Submission Form to the attention of your Intertek Representative noted below. A copy of your signed purchase order is required prior to scheduling this project. If the project is to take place at an Intertek facility, please make sure to provide sample dispostion instructions.</span>
        </div>
        <div style={{ backgroundColor: '#f5f5f5', width: '100%' }}>
            <div className={styles.section}>
                <div className={styles.label}>
                    Accepted By:
                </div>
                <div >
                    {user.viewingQuote.signedBy !== null ? (
                        <div>
                            <img src={signatureImage} />
                        </div>
                    ) : (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <SignatureCanvas
                                        ref={sigCanvasRef}
                                        onEnd={handleSignatureEnd}
                                        canvasProps={{
                                            width: 500,
                                            height: 100,
                                            className: `${styles.signaturePad} ${formErrors.signature !== undefined ? styles.signatureError : ''
                                                }`
                                        }}
                                    />
                                    {formErrors.signature !== undefined && (
                                        <div className={styles.error}>{formErrors.signature}</div>
                                    )}
                                </div>
                                <div>
                                    {hasSignature && (
                                        <IconButton
                                            style={{ alignSelf: 'center' }}
                                            src="/assets/images/delete_dark.svg"
                                            mouseOver="/assets/images/delete_light.svg"
                                            height={28}
                                            width={25}
                                            onClick={() => clearSignature()}
                                        />
                                    )}
                                </div>

                            </div>
                            <div style={{ fontStyle: 'italic' }}>
                                Use your mouse to provide your signature
                            </div>
                     </>
                    )}
                </div>
            </div>
            <div className={styles.section}>
                <div className={styles.label}>
                    Name:
                </div>
                <div style={{display:'flex'}}>
                   <TextField  disabled={user.viewingQuote.status != 'ReadyForAcceptance'} size={'small'} error={formErrors.signedByName !== undefined} helperText={formErrors.signedByName} sx={{width:'500px'}} value={signedByName} onChange={(e) => setSignedByName(e.target.value)}/>
                </div>
                
            </div>

            <div className={styles.section}>
                <div className={styles.label}>
                    Being an authorized representative of:
                </div>
                <div style={{display:'flex'}}>
                   <TextField disabled={user.viewingQuote.status != 'ReadyForAcceptance'} size={'small'} sx={{width:'500px'}} error={formErrors.companyName !== undefined} helperText={formErrors.companyName} value={companyName} onChange={(e) => setCompanyName(e.target.value)}/>
                </div>
                <div className='Text-Dark'>
                    Legal entity name of the Company who is authorizing commencement of this quotation
                </div>
                
            </div>
            <div className={styles.section}>
                <div className={styles.label}>
                    Signed Date:
                </div>
                <div style={{display:'flex'}}>
                   <TextField size={'small'} sx={{width:'500px'}} disabled value={signedDate}/>
                </div>
            </div>
        </div>
        <div style={{ display: 'flex', marginTop:'10px' }}>
            <div style={{ backgroundColor: 'var(--intertek-blue-2)', padding: '4px 12px 4px 12px', marginTop: '2px', marginBottom: '2px' }}>
                <span style={{ color: 'var(--intertek-blue-7)', fontWeight: 'bold' }}>* By signing this proposal,</span>
                <span style={{ color: 'var(--intertek-blue-7)' }}> you confirm that you have read and accept the terms and conditions within and attached and authorize Intertek to proceed with the work as outlined in this document.</span>
            </div>
            {user.viewingQuote.status == 'ReadyForAcceptance' &&
                <div style={{ marginLeft: '40px', alignSelf: 'center' }}>
                    <OutlineButton onClick={validateAndSave}>Next</OutlineButton>
                </div>
            }
        </div>
    </div>)
}