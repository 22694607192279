import React from 'react';
import styles from './SampleSubmission.module.css';
import { UserContext } from '../../../UserContext';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import LimitedTextField from '../../../Common/LimitedTextField';
import { CheckBox } from '@mui/icons-material';
import OutlineButton from '../../../Buttons/OutlineButton';
import YellowButton from '../../../Buttons/YellowButton';
import { useSnackbar } from 'notistack'
import JsBarcode from 'jsbarcode';
import ReactToPrint from 'react-to-print';

export default function SampleSubmission(props) {
    const { user, onUserContextChanged, onQuoteChanged } = React.useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar()
    const [samples, setSamples] = React.useState([]);
    const [additionalInformation, setAdditionalInformation] = React.useState('');
    const [sampleCondition, setSampleCondition] = React.useState('');
    const [contactName, setContactName] = React.useState('');
    const [contactEmail, setContactEmail] = React.useState('');
    const [contactPhone, setContactPhone] = React.useState('');
    const [sampleDisposition, setSampleDisposition]= React.useState('');

    const [returnShippingMethod, setReturnShippingMethod]= React.useState('');
    const [returnInfoAccountNumber, setReturnInfoAccountNumber]= React.useState('');
    const [returnInfoInsurance, setReturnInfoInsurance]= React.useState('');
    const [returnInfoOther, setReturnInfoOther]= React.useState('');

    const printAreaRef = React.useRef();
    const barcodeRef = React.useRef(null);

    const [formErrors, setFormErrors] = React.useState({
        samples:[]
    });
   
    React.useEffect(() => {
        if (user.viewingQuote.sampleSubmission != null) {
            // fill in any previously entered data.
            setAdditionalInformation(user.viewingQuote.sampleSubmission.additionalInformation);
            setSampleCondition(user.viewingQuote.sampleSubmission.sampleCondition);
            setContactName(user.viewingQuote.sampleSubmission.contactName == null ? '' : user.viewingQuote.sampleSubmission.contactName);
            setContactEmail(user.viewingQuote.sampleSubmission.contactEmail == null ? '' : user.viewingQuote.sampleSubmission.contactEmail);
            setContactPhone(user.viewingQuote.sampleSubmission.contactPhone == null ? '' : user.viewingQuote.sampleSubmission.contactPhone);
            setSampleDisposition(user.viewingQuote.sampleSubmission.sampleDisposition == null ? '' : user.viewingQuote.sampleSubmission.sampleDisposition);

            setReturnShippingMethod(user.viewingQuote.sampleSubmission.returnShippingMethod == null ? '' : user.viewingQuote.sampleSubmission.returnShippingMethod);
            setReturnInfoAccountNumber(user.viewingQuote.sampleSubmission.returnInfoAccountNumber == null ? '' : user.viewingQuote.sampleSubmission.returnInfoAccountNumber);
            setReturnInfoInsurance(user.viewingQuote.sampleSubmission.returnInfoInsurance == null ? '' : user.viewingQuote.sampleSubmission.returnInfoInsurance);
            setReturnInfoOther(user.viewingQuote.sampleSubmission.returnInfoOther == null ? '' : user.viewingQuote.sampleSubmission.returnInfoOther);

            let tempSamples = [...user.viewingQuote.sampleSubmission.items];
            // always show a minimum of 4 rows
            while (tempSamples.length < 4) {
                tempSamples.push({
                    id: 0,
                    sampleSubmissionId: 0,
                    customerQuoteId: user.viewingQuote.id,
                    partName: '',
                    partNumber: '',
                    quantity: '',
                    description: ''
                })
            }

            setSamples(tempSamples);
        }
        else {
            // create some empty records
            let emptySamples = [
                {
                    id:0,
                    sampleSubmissionId:0,
                    customerQuoteId: user.viewingQuote.id,
                    partName: '',
                    partNumber: '',
                    quantity:'',
                    description:''
                },
                {
                    id:0,
                    sampleSubmissionId:0,
                    customerQuoteId: user.viewingQuote.id,
                    partName: '',
                    partNumber: '',
                    quantity:'',
                    description:''
                },
                {
                    id:0,
                    sampleSubmissionId:0,
                    customerQuoteId: user.viewingQuote.id,
                    partName: '',
                    partNumber: '',
                    quantity:'',
                    description:''
                },
                {
                    id:0,
                    sampleSubmissionId:0,
                    customerQuoteId: user.viewingQuote.id,
                    partName: '',
                    partNumber: '',
                    quantity:'',
                    description:''
                }
            ];
            setSamples(emptySamples);
        }

        if (barcodeRef.current) {
            JsBarcode(barcodeRef.current, user.viewingQuote.intertekQuoteNumber, {
              format: "CODE39", // Specify the barcode format as CODE39
              lineColor: "#000000",
              width: 1,
              height: 50,
              displayValue: true
            });
        }
    }, [])

    React.useEffect(() => {

        if (user.viewingQuote.sampleSubmission == null || user.viewingQuote.sampleSubmission == undefined) {
            user.viewingQuote.sampleSubmission = {
                items:[]
            };
        }
        user.viewingQuote.sampleSubmission.additionalInformation = additionalInformation;
        user.viewingQuote.sampleSubmission.sampleCondition = sampleCondition;
        user.viewingQuote.sampleSubmission.contactName = contactName;
        user.viewingQuote.sampleSubmission.contactEmail = contactEmail;
        user.viewingQuote.sampleSubmission.contactPhone = contactPhone;
        user.viewingQuote.sampleSubmission.sampleDisposition = sampleDisposition;
        user.viewingQuote.sampleSubmission.returnShippingMethod = returnShippingMethod;
        user.viewingQuote.sampleSubmission.returnInfoAccountNumber = returnInfoAccountNumber;
        user.viewingQuote.sampleSubmission.returnInfoInsurance = returnInfoInsurance;
        user.viewingQuote.sampleSubmission.returnInfoOther = returnInfoOther;

        user.viewingQuote.sampleSubmission.items = [];

        for (let i = 0; i < samples.length; i++)
        {
            if (samples[i].partName.trim() != '' || samples[i].partNumber.trim() != '' || samples[i].quantity.trim() != '' || samples[i].description.trim() != '') 
            {
                user.viewingQuote.sampleSubmission.items.push(
                    {
                        id: 0,
                        sampleSubmissionId: 0,
                        customerQuoteId: user.viewingQuote.id,
                        partName: samples[i].partName,
                        partNumber: samples[i].partNumber,
                        quantity: samples[i].quantity,
                        description: samples[i].description
                    }
                )
            }
        }

    }, [samples,additionalInformation,sampleCondition,contactName,contactEmail,contactPhone,sampleDisposition,returnShippingMethod,returnInfoAccountNumber,returnInfoInsurance,returnInfoOther])

    const handleBeforePrint = () => {
        if (!validateForm()) {
          return Promise.reject(); // Prevents printing
        }
        return Promise.resolve(); // Allows printing
      };

    function validateForm() {
        let errors = {};

        let isValid = true;
        if (sampleCondition.trim() == '') {
            errors['sampleCondition'] = 'Required';
            isValid = false;
        }

        if (contactEmail.trim() != '' || contactName != '' || contactPhone != '') {
            // if ANY are populated, make sure all of them are popuplated
            if (contactEmail.trim() == '') {
                errors['contactEmail'] = 'Required';
                isValid = false;
            }

            if (contactName.trim() == '') {
                errors['contactName'] = 'Required';
                isValid = false;
            }

            if (contactPhone.trim() == '') {
                errors['contactPhone'] = 'Required';
                isValid = false;
            }
        }

        if (sampleDisposition.trim() == '') {
            errors['sampleDisposition'] = 'Required';
            isValid = false;
        }

        if (sampleDisposition == 'Return') {

            if (returnShippingMethod.trim() == '') {
                errors['returnShippingMethod'] = 'Required';
                isValid = false;
            }
            if (returnInfoAccountNumber.trim() == '') {
                errors['returnInfoAccountNumber'] = 'Required';
                isValid = false;
            }
            if (returnInfoInsurance.trim() == '') {
                errors['returnInfoInsurance'] = 'Required';
                isValid = false;
            }
           
        }

        // check for any samples missing data
        errors['samples'] = [];
        for (let i = 0; i < samples.length; i++) {
            let sampleError = {}
            if (samples[i].partName.trim() == '' && samples[i].partNumber.trim() == '' && samples[i].quantity.trim() == '') 
            {
                // empty row. ignore
                
            }
            else {
                
                
                if (samples[i].partName.trim() == '') {
                    sampleError['partName'] = 'Required';
                    isValid = false;
                }

                if (samples[i].partNumber.trim() == '') {
                    sampleError['partNumber'] = 'Required';
                    isValid = false;
                }

                if (samples[i].quantity.trim() == '') {
                    sampleError['quantity'] = 'Required';
                    isValid = false;
                }
            }
            errors['samples'].push(sampleError);
        }
        
        setFormErrors(errors);

        if (isValid) {
           props.onSubmit();
        }
        else {
            enqueueSnackbar('Please fix the errors and try again.', { variant: 'error' });
        }

        return isValid;
    }

    const handleSampleItemChange = (e, rowIndex, key) => {

        const newData = [...samples];
        newData[rowIndex][key] = e.target.value;
        setSamples(newData);

    };

    const handleAddBlankSampleItem = () => {
        const newData = [...samples];
        newData.push({
            id: 0,
            sampleSubmissionId: 0,
            customerQuoteId: user.viewingQuote.id,
            partName: '',
            partNumber: '',
            quantity: '',
            description: ''
        })
        setSamples(newData);
    }

    const handleSampleConditionChange = (e) => {
        setSampleCondition(e.target.value);
    }


    return (<div componentname='SampleSubmission'>
        <div ref={printAreaRef} >
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <div style={{alignSelf:'center'}}>
                    To prevent delays of sample assignment to your project, submit the Sample Submission form. Print and include this with your samples.
                </div>
                <div className='print-only'>
                    <svg ref={barcodeRef}></svg>
                </div>
            </div>
        
            <div style={{ display: 'flex' }}>
                <table border={1} className={styles.SampleTable}>
                    <thead>
                        <tr>
                            <th>Part Name</th>
                            <th>Part Number</th>
                            <th>Quantity</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {samples.map((sample, idx) => {
                            return (
                                <tr key={'SampleItem_' + idx}>
                                    <td className={formErrors['samples'].length > idx && formErrors['samples'][idx]['partName'] != undefined ? styles.Error : null}>
                                        <input
                                            type="text"
                                            value={sample.partName}
                                            onChange={(e) => handleSampleItemChange(e, idx, 'partName')}
                                            className={styles.editableCell}
                                        />
                                    </td>
                                    <td className={formErrors['samples'].length > idx && formErrors['samples'][idx]['partNumber'] != undefined ? styles.Error : null}>
                                        <input
                                            type="text"
                                            value={sample.partNumber}
                                            onChange={(e) => handleSampleItemChange(e, idx, 'partNumber')}
                                            className={styles.editableCell}
                                        />
                                    </td>
                                    <td className={formErrors['samples'].length > idx && formErrors['samples'][idx]['quantity'] != undefined ? styles.Error : null}>
                                        <input
                                            type="text"
                                            value={sample.quantity}
                                            onChange={(e) => handleSampleItemChange(e, idx, 'quantity')}
                                            className={styles.editableCell}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={sample.description}
                                            onChange={(e) => handleSampleItemChange(e, idx, 'description')}
                                            className={styles.editableCell}
                                        />
                                    </td>
                                </tr>)
                        })}
                        <tr>
                            <td colSpan={4}>
                                <div className='Text-Dark'>Additional Information</div>
                                <div style={{ minHeight: '70px' }}>
                                    <textarea
                                        rows={4}
                                        value={additionalInformation}
                                        onChange={(e) => setAdditionalInformation(e.target.value)}
                                        className={styles.editableCell}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className={`Text-Dark ${formErrors['sampleCondition'] !== undefined ? styles.Error : ''}`}><span style={{ color: 'red' }}>*</span> Condition of Samples:</div>
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        name="sample-condition-group"
                                        value={sampleCondition}
                                        onChange={handleSampleConditionChange}
                                    >
                                        <FormControlLabel labelPlacement='start' value="Production" control={<Radio />} label={<span className='Text-Dark'>Production</span>} />
                                        <span style={{ borderRight: '1px solid #000', height: '100%', minHeight: '42px', marginLeft: '10px', marginRight: '10px' }}></span>
                                        <FormControlLabel labelPlacement='start' value="Prototype" control={<Radio />} label={<span className='Text-Dark'>Prototype</span>} />
                                    </RadioGroup>
                                </FormControl>
                            </td>
                            <td>
                                <div className={'Text-Dark'}>Specify if other:</div>
                            </td>
                            <td>
                                <input
                                    type="text" maxLength={50}
                                    value={sampleCondition == 'Production' || sampleCondition == 'Prototype' ? '' : sampleCondition}
                                    onChange={(e) => setSampleCondition(e.target.value)}
                                    className={styles.editableCell}
                                />
                            </td>
                        </tr>

                    </tbody>
                </table>
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingLeft: '1px', paddingBottom: '158px' }} className='no-print'>
                    <IconButton onClick={() => handleAddBlankSampleItem()} size='small' sx={{ backgroundColor: 'var(--intertek-blue-1)', alignSelf: 'flex-end' }}>
                        <AddIcon />
                    </IconButton>
                </div>
            </div>
            <div className={styles.section} style={{ marginTop: '5px' }}>
                <div className={'Text-Dark'} style={{ marginBottom: '10px' }}>Sample Submission Contact (if different from quote recipient)</div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ alignSelf: 'center', marginRight: '10px' }} className={`Text-Dark ${formErrors['contactName'] !== undefined ? styles.Error : ''}`}>Name</div>
                        <LimitedTextField onChange={(e) => setContactName(e.target.value)} value={contactName} maxLength={50} size='small' placeholder='Full Name' style={{ minWidth: '280px' }} sx={{ '& .MuiInputBase-root': { bgcolor: '#ffffff' } }} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ alignSelf: 'center', marginRight: '10px' }} className={`Text-Dark ${formErrors['contactEmail'] !== undefined ? styles.Error : ''}`}>Email </div>
                        <LimitedTextField onChange={(e) => setContactEmail(e.target.value)} value={contactEmail} maxLength={100} size='small' placeholder='Email' style={{ minWidth: '280px' }} sx={{ '& .MuiInputBase-root': { bgcolor: '#ffffff' } }} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ alignSelf: 'center', marginRight: '10px' }} className={`Text-Dark ${formErrors['contactPhone'] !== undefined ? styles.Error : ''}`}>Phone</div>
                        <LimitedTextField onChange={(e) => setContactPhone(e.target.value)} value={contactPhone} maxLength={20} size='small' placeholder='Phone' style={{ minWidth: '280px' }} sx={{ '& .MuiInputBase-root': { bgcolor: '#ffffff' } }} />
                    </div>
                </div>
            </div>

            <div className={styles.section} style={{ marginTop: '5px', display: 'flex' }}>
                <div style={{ marginRight: '25px' }} className='Text-Dark'>Sample Returns:</div>
                <div>Test samples not consumed during testing may be disposed or returned after completion of testing.</div>
            </div>


            <table border={1} className={styles.SampleTable}>
                <tbody>
                    <tr>
                        <td className='Text-Dark'>
                            <FormControlLabel labelPlacement='start' onClick={() => setSampleDisposition('Dispose')} control={<Radio checked={sampleDisposition == 'Dispose'} onClick={() => setSampleDisposition('Dispose')} />} label={<span className='Text-Dark'>Disposal of Samples</span>} />
                        </td>
                        <td colSpan={2}>
                            if required, sample disposal fees will be invoiced in addition to quoted project fees
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan={5} className='Text-Dark'>
                            <FormControlLabel labelPlacement='start' onClick={() => setSampleDisposition('Return')} control={<Radio checked={sampleDisposition == 'Return'} onClick={() => setSampleDisposition('Return')} style={{ marginLeft: '30px' }} />} label={<span className='Text-Dark'>Return Samples</span>} />
                        </td>
                    </tr>
                    <tr>

                        <td>
                            Shipping Method
                        </td>
                        <td className={formErrors['returnShippingMethod'] != undefined ? styles.Error : null}>
                            <input
                                type="text"
                                value={returnShippingMethod}
                                onChange={(e) => setReturnShippingMethod(e.target.value)}
                                className={styles.editableCell}
                            />
                        </td>
                    </tr>
                    <tr>

                        <td >
                            Charge to Account Number
                        </td>
                        <td className={formErrors['returnInfoAccountNumber'] != undefined ? styles.Error : null}>
                            <input
                                type="text"
                                value={returnInfoAccountNumber}
                                onChange={(e) => setReturnInfoAccountNumber(e.target.value)}
                                className={styles.editableCell}
                            />
                        </td>
                    </tr>
                    <tr>

                        <td>
                            Insurance Value
                        </td>
                        <td className={formErrors['returnInfoInsurance'] != undefined ? styles.Error : null}>
                            <input
                                type="text"
                                value={returnInfoInsurance}
                                onChange={(e) => setReturnInfoInsurance(e.target.value)}
                                className={styles.editableCell}
                            />
                        </td>
                    </tr>
                    <tr>

                        <td>
                            Other Instructions
                        </td>
                        <td>
                            <input
                                type="text"
                                value={returnInfoOther}
                                onChange={(e) => setReturnInfoOther(e.target.value)}
                                className={styles.editableCell}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div style={{display:'flex', justifyContent:'space-between', marginTop:'15px'}}>
            <OutlineButton onClick={() => props.onClose()}>Fill Later</OutlineButton>
            <ReactToPrint
                trigger={() => <YellowButton>Submit and Print</YellowButton>}
                content={() => printAreaRef.current}
                onBeforeGetContent={handleBeforePrint}
                pageStyle="@page { size: landscape; }"
            />
           
        </div>

    </div>)
}