import axios from 'axios';

class CustomerService {

    async send(method, postData, endPoint) {


        return new Promise(function (resolve, reject) {
            let AUTH_REST_API_URL = import.meta.env.VITE_APIURL;
            let authToken = localStorage.getItem("CustomerToken");
            axios({
                method: method,
                url: AUTH_REST_API_URL + endPoint,
                data: postData === '' ? postData = '{}' : postData,
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            }).then((response) => {
                resolve(response.data);
            }, (error) => {

                if (error.response !== undefined) {
                    if (error.response !== null && error.response.status !== null && error.response.status === 401) {
                        if (error.response.data != null && error.response.data.message != null) {
                            reject({ error: error.response.data.message });
                        }
                        else {
                            reject({ error: 'Unauthorized' });
                        }

                    }
                    else {
                        console.log(error);
                        reject({ error: 'Post error', response: error.response });
                    }
                }
                else {
                    console.log(error);
                    reject({ error: 'Post error', response: error });
                }

            });
        })
    }

    async file(method, postData, endPoint) {


        return new Promise(function (resolve, reject) {
            let AUTH_REST_API_URL = import.meta.env.VITE_APIURL;
            let authToken = localStorage.getItem("CustomerToken");
            axios({
                method: method,
                url: AUTH_REST_API_URL + endPoint,
                data: postData === '' ? postData = '{}' : postData,
                headers: {
                    'Authorization': `Bearer ${authToken}`
                },
                responseType: 'blob'
            }).then((response) => {
                resolve(response.data);
            }, (error) => {

                if (error.response !== undefined) {
                    if (error.response !== null && error.response.status !== null && error.response.status === 401) {
                        if (error.response.data != null && error.response.data.message != null) {
                            reject({ error: error.response.data.message });
                        }
                        else {
                            reject({ error: 'Unauthorized' });
                        }

                    }
                    else {
                        console.log(error);
                        reject({ error: 'Post error', response: error.response });
                    }
                }
                else {
                    console.log(error);
                    reject({ error: 'Post error', response: error });
                }

            });
        })
    }
}
export default new CustomerService(); 