/* eslint-disable react/prop-types */
import styles from './StoreView.module.css';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import IconButton from '../../IconButton/IconButton';
import { UserContext } from '../../UserContext';

// props:
// onCategoryChanged: event
// onFavoritesChanged: event
// onSearchValueChanged: event
export default function StoreSearchFilter(props) {
    const [searchValue, setSearchValue] = React.useState('');
    const [selectedCategory, setSelectedCategory] = React.useState('All');
    const [viewFavorites, setViewFavorites] = React.useState(false);
    const { storeItems, setStoreItems, categories, setCategories } = React.useContext(UserContext);

    function handleCategoryChanged(e) {
        setSelectedCategory(e.target.value);
        handleFilterChanged(searchValue, e.target.value, viewFavorites);
    }

    function handleViewFavoritesChanged(value) {
        setViewFavorites(value);
        handleFilterChanged(searchValue, selectedCategory , value);
    }

    function handleSearchValueChanged(e) {
        setSearchValue(e.target.value)
        handleFilterChanged(e.target.value, selectedCategory, viewFavorites);
    }


    function handleFilterChanged(searchValue, category, viewFavorites) {

        let temp = [];
        searchValue = searchValue.toLowerCase();
        for (let i = 0; i < storeItems.length; i++) {
            let isFiltered = false;
            if (storeItems[i].isDeleted)
            {
                isFiltered = true;
            }
            
            if (searchValue.trim() !== '') {
                if (storeItems[i].method.toLowerCase().indexOf(searchValue) === -1 &&
                    storeItems[i].standard.toLowerCase().indexOf(searchValue) === -1) {
                    isFiltered = true;
                }
            }

            if (!isFiltered && category !== 'All') {
                if (storeItems[i].categoryId !== category.id) {
                    isFiltered = true;
                }
            }

            if (!isFiltered) {
                // check viewFavorites
                if (storeItems[i].isFavorite === false && viewFavorites === true) {
                    // viewing favorites, but this one is not a favorite
                    isFiltered = true;
                }
            }

            if (!isFiltered) {
                temp.push(storeItems[i]);
            }

        }


        if (props.onFilterChanged !== undefined) {
            props.onFilterChanged(temp);
        }
    }


    return (<div componentname='StoreSearchFilter'>
        <div className={styles.SearchBar}>
            <TextField style={{ width: '440px', alignSelf: 'center' }} className='RoundTall'
                placeholder='Search our Testing Capabilities'
                onChange={(e) => handleSearchValueChanged(e)}
                value={searchValue}
                autoComplete='off'
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <img src={"/assets/images/search.svg"} width={19} height={19} />
                        </InputAdornment>
                    )
                }} />

            <Select
                value={selectedCategory}
                onChange={(e) => handleCategoryChanged(e)}
                inputProps={{ 'aria-label': 'Category' }}
                style={{backgroundColor:'#ffffff', height:'53px', width:'400px', alignSelf:'center'} }
            >
                <MenuItem value="All">
                    <em>All Categories</em>
                </MenuItem>

                {categories === undefined ? null :
                    categories.map((category, idx) => {
                        return (<MenuItem key={'storeCategory_' + idx} value={category}>
                            <em>{category.name}</em>
                        </MenuItem>)
                    })    
                }
               
            </Select>

            <div className={styles.FavoritesToggle }>
                <IconButton src={viewFavorites ? '/assets/images/beaker.png' : '/assets/images/beaker_mouseover.png'} mouseOver='/assets/images/beaker_mouseover.png' onClick={() => handleViewFavoritesChanged(false)} />
                <div className={'Text-Dark Font-Md'} style={{width:'95px', textAlign:'center'} }>{viewFavorites ? 'Favorites' : 'All Tests'}</div>
                <IconButton src={viewFavorites ? '/assets/images/favorite_mouseover.png' : '/assets/images/favorite.png'} mouseOver='/assets/images/favorite_mouseover.png' onClick={() => handleViewFavoritesChanged(true)} />
            </div>
        </div>
    </div>)
}