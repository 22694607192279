import styles from './StoreView.module.css';
/* eslint-disable react/no-unknown-property */
import LoggedInHeader from '../../Navbars/LoggedInHeader';
import LoggedInFooter from '../../Navbars/LoggedInFooter';
import { UserContext } from '../../UserContext'
import { Navigate, Link, useNavigate, useLocation } from 'react-router-dom';
import * as React from 'react';
import IntertekLoading from '../../Common/IntertekLoading';
import CustomerService from '../../../services/CustomerService';
import YellowButton from '../../Buttons/YellowButton';
import StoreSearchFilter from './StoreSearchFilter';
import StoreItem from './StoreItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import cloneDeep from 'lodash.cloneDeep';
import SlideInWrapper from '../../SlideInWrapper/SlideInWrapper';
import SlideInModal from '../../SlideInModal/SlideInModal';
import TextField from '@mui/material/TextField';
import OutlineButton from '../../Buttons/OutlineButton';
import OutlineYellowButton from '../../Buttons/OutlineYellowButton';
import { Tooltip } from '@mui/material';
import ConfirmDialog from '../../Common/ConfirmDialog';
import { useSnackbar } from 'notistack'
import QuoteDetailsPanel from '../QuoteDetails/QuoteDetailsPanel';
import { formatStatus } from '../../utils';
import QuoteNameDialog from '../QuoteNameDialog/QuoteNameDialog';
import LaunchIcon from '@mui/icons-material/Launch';

export default function StoreView(props) {
    const { user, onUserContextChanged, userContextChanged, storeItems, setStoreItems, categories, setCategories } = React.useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = React.useState(false);
    // const [categories, setCategories] = React.useState([]);
    // const [storeItems, setStoreItems] = React.useState([]);
    const [visibleStoreItems, setVisibleStoreItems] = React.useState([]);
    const [showQuoteDetail, setShowQuoteDetail] = React.useState(false);
    const [showQuoteNameDialog, setShowQuoteNameDialog] = React.useState(false);
    const [requestTest, setRequestTest] = React.useState('');
    const [confirmDialog, setConfirmDialog] = React.useState(null);
    const [showQuoteItemDetails, setShowQuoteItemDetails] = React.useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        // Parse the query parameters
        const queryParams = new URLSearchParams(location.search);
        const isOpen = queryParams.get('open') === 'true';

        
        if (isOpen) {
            setShowQuoteDetail(true);
        }

        // Check if there are any query parameters, and only navigate if there are
        if (location.search !== "") {
            navigate(location.pathname, { replace: true }); // Clear all query parameters
        }
    }, [location.search, location.pathname, navigate]); // Depend only on search and pathname parts of location


    React.useEffect(() => {
        // handles routing directly to this page without going to /Portal
        if (user !== null && user !== null) {

            if (storeItems.length == 0) {
                setIsLoading(true);
                CustomerService.send('GET', '', 'Category/Customer')
                    .then((response) => {
                        setIsLoading(false);
                        if (response !== null) {
                            if (response.result === "SUCCESS") {
                                setCategories(response.categories == null ? [] : response.categories);

                                // create a categoryName property on the storeItem
                                for (let i = 0; i < response.storeItems.length; i++) {
                                    for (let j = 0; j < response.categories.length; j++) {
                                        if (response.storeItems[i].categoryId == response.categories[j].id) {
                                            response.storeItems[i].categoryName = response.categories[j].name;
                                            break;
                                        }
                                    }
                                }

                                setStoreItems(response.storeItems == null ? [] : response.storeItems);
                                setVisibleStoreItems(response.storeItems == null ? [] : response.storeItems.filter(item => !item.isDeleted));
                            }
                        }

                    })
                    .catch((response) => {
                        setIsLoading(false);
                        if (response !== null) {
                            //toast.error(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred');
                        }
                        else {

                        }
                    });
            }
            else {
                setVisibleStoreItems(storeItems.filter(item => !item.isDeleted));
            }

            if (user.customerQuotes === undefined) {
                setIsLoading(true);
                CustomerService.send('GET', '', 'CustomerQuote')
                    .then((response) => {
                        setIsLoading(false);
                        if (response !== null) {
                            if (response.result === "SUCCESS") {
                                if (response.customerQuotes !== null) {
                                    user.customerQuotes = response.customerQuotes;
                                    onUserContextChanged();
                                }
                            }
                        }

                    })
                    .catch((response) => {
                        setIsLoading(false);
                        if (response !== null) {
                            //toast.error(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred');
                        }
                        else {

                        }
                    });
            }
            
        }
    }, [user])

    function handleFilterChanged(visibleItems) {
            setVisibleStoreItems(visibleItems);
            setRequestTest('');
    }

    function handleCloseQuoteDetailsPanel() {
        setShowQuoteDetail(false);
    }

    function handleToggleFavorite(storeItem) {
      
        // note, we don't have to set the visibleStoreItems because the object references are already in istoreItems
        let isFavorite = false;
        for (let i = 0; i < visibleStoreItems.length; i++) {
            if (visibleStoreItems[i].id == storeItem.id) {
                // if it's undefined, the it's not set. So make it true. Else flip it.
                visibleStoreItems[i].isFavorite = visibleStoreItems[i].isFavorite === undefined ? true : !visibleStoreItems[i].isFavorite;
                isFavorite = visibleStoreItems[i].isFavorite;
                break;
            }
        }
        setVisibleStoreItems(cloneDeep(visibleStoreItems));

        // also need to update storeItems on the userContext
        for (let i = 0; i < storeItems.length; i++) {
            if (storeItems[i].id == storeItem.id) {
                // if it's undefined, the it's not set. So make it true. Else flip it.
                storeItems[i].isFavorite = isFavorite;
                break;
            }
        }

        let postData = {
            id:0,
            customerId: user.id,
            storeItemId: storeItem.id
        }

        CustomerService.send(isFavorite ? 'POST' : 'DELETE', postData, 'CustomerFavoriteStoreItem')
            .then((response) => {
                setIsLoading(false);
                if (response !== null) {
                    if (response.result === "SUCCESS") {
                        if (response.customerFavoriteStoreItem !== null) {
                          
                        }
                    }
                }

            })
            .catch((response) => {
                setIsLoading(false);
                if (response !== null) {
                    //toast.error(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred');
                }
                else {

                }
            });
    }

    function handleSubmitNotFoundInquiry() {
        let postData = {
            inquiry: requestTest
        }

        CustomerService.send('POST', postData, 'Customer/TestInquiry')
                .then((response) => {
                    

                })
                .catch((response) => {
                    
                });
        
        setConfirmDialog({
            show: true,
            close: () => setConfirmDialog(null),
            acceptText: 'Close',
            title: 'Inquiry Received',
            content: <div>
                <div style={{ marginBottom: '25px' }}>We will get back to you shortly.</div>
            </div>,
            data: null
        })
    }

    function handleUpdateQuote(id, item, quantity, price, priority) {
            
            let postData = {
                id: id,
                storeItemId: item.id,
                customerQuoteId: user.viewingQuote.id,
                priority: priority,
                quantity: quantity,
                price: 0, // calculated on server
            }

            CustomerService.send('PUT', postData, 'CustomerQuoteItem')
                .then((response) => {
                    setIsLoading(false);
                    if (response !== null) {
                        if (response.result === "SUCCESS") {
                            if (response.customerQuoteItem !== null) {
                                for (let i = 0; i < user.viewingQuote.items.length; i++) {
                                    if (user.viewingQuote.items[i].id == response.customerQuoteItem.id) {
                                        user.viewingQuote.items[i] = response.customerQuoteItem;
                                        break;
                                    }
                                }
                                
                                onUserContextChanged();
                                
                            }
                        }
                    }

                })
                .catch((response) => {
                    setIsLoading(false);
                    if (response !== null) {
                        //toast.error(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred');
                    }
                    else {

                    }
                });

    }

    function handleAddToQuote(item, quantity, price, priority) {
        if (user.viewingQuote === undefined || user.viewingQuote === null) {
            // no quote being viewed, so create a new Draft Quote
            let postData = {
                id: 0,
                quoteNumber:0,
                status: 'Draft',
                items: [
                    {
                        id: 0,
                        storeItemId: item.id,
                        customerQuoteId: 0,
                        priority: priority,
                        quantity: quantity,
                        price:0, // calculated on server
                    }
                ]
            }

            CustomerService.send('POST', postData, 'CustomerQuote')
                .then((response) => {
                    setIsLoading(false);
                    if (response !== null) {
                        if (response.result === "SUCCESS") {
                            if (response.customerQuote !== null) {
                                user.viewingQuote = response.customerQuote;
                                // push this new quote
                                if (user.customerQuotes === undefined) { 
                                    user.customerQuotes = [];
                                }
                                user.customerQuotes.push(response.customerQuote);
                                onUserContextChanged();
                                setShowQuoteNameDialog(true);
                              
                            }
                        }
                        else {
                            if (response.message) {
                                enqueueSnackbar(response.message, { variant: 'error' });
                            }
                            else {
                                enqueueSnackbar('An error occurred', { variant: 'error' });
                            }
                        }
                    }

                })
                .catch((response) => {
                    setIsLoading(false);
                    console.log(response);
                    if (response !== null) {
                        //toast.error(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred');
                    }
                    else {

                    }
                });

        }
        else {
            // add this item to the Quote
            // find the item by id in the list of customerQuote.items
            let postData = {
                id: 0,
                storeItemId: item.id,
                customerQuoteId: user.viewingQuote.id,
                priority: priority,
                quantity: quantity,
                price: 0, // calculated on server
            }

            CustomerService.send('POST', postData, 'CustomerQuoteItem')
                .then((response) => {
                    setIsLoading(false);
                    if (response !== null) {
                        if (response.result === "SUCCESS") {
                            if (response.customerQuoteItem !== null) {
                                user.viewingQuote.items.push(response.customerQuoteItem);
                                onUserContextChanged();
                                
                            }
                        }
                    }

                })
                .catch((response) => {
                    setIsLoading(false);
                    if (response !== null) {
                        //toast.error(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred');
                    }
                    else {

                    }
                });

        }
    }

    function handleRemoveFromQuote(item) {
        if (user.viewingQuote === undefined || user.viewingQuote === null) {
            // no quote being viewed. This shouldn't really happen unless there is a race condition
        }
        else {
            // remove this item from the Quote
            for (let i = 0; i < user.viewingQuote.items.length; i++) {
                if (item.id == user.viewingQuote.items[i].storeItemId) {
                   

                    CustomerService.send('DELETE', user.viewingQuote.items[i], 'CustomerQuoteItem')
                        .then((response) => {
                            setIsLoading(false);
                            if (response !== null) {
                                if (response.result === "SUCCESS") {
                                   
                                }
                            }

                        })
                        .catch((response) => {
                            setIsLoading(false);
                            if (response !== null) {
                                //toast.error(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred');
                            }
                            else {

                            }
                        });

                    user.viewingQuote.items.splice(i, 1);
                    onUserContextChanged();
                    
                }
            }
        }
    }

    function handleNavigateToPortal() {
        user.viewingQuote = undefined;
        navigate('/Portal');
    }

    if (user == null) {
        return null;
    }

    function isQuoteNameUpdated() {
        if (user.viewingQuote !== undefined && user.viewingQuote.action === 'NameUpdated')
        {
            delete user.viewingQuote.action;
            return true;
        }
        return false;
    }

    return (<div componentname='StoreView'>

        <LoggedInHeader />
        <div className={styles.StoreHeader + ' ContentContainer'}>
            <div className='PortalContentArea'>
                <div style={{display:'flex', justifyContent:'space-between'} }>
                    <div className='Font-Xl' style={{ color: 'var(--intertek-blue-10)', marginTop: '25px' }}>Our Testing Capabilities</div>
                    <div style={{display:'flex'}}>
                        <YellowButton style={{alignSelf:'center'}} onClick={() => handleNavigateToPortal()}>My Portal</YellowButton>
                        {user === null || user.viewingQuote === undefined || user.viewingQuote === null ? 
                            null :
                            <SlideInWrapper style={{ padding: '15px' }}>
                                <div className='pulse-yellow-button'>
                                    <Tooltip title={user.viewingQuote.displayName.length < 20 ? '' : user.viewingQuote.displayName}>
                                        <OutlineYellowButton animationClass={isQuoteNameUpdated() ? 'yellow-fill-right' : ''} onClick={() => setShowQuoteDetail(true)}>
                                            <span style={{ maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display:'flex' }}>
                                                <span style={{ marginRight: '5px' }}>View Quote - </span> {user.viewingQuote.displayName}
                                                <LaunchIcon style={{marginLeft:'5px', fontSize: '1.5rem', alignSelf:'center' }} />
                                            </span>
                                           
                                        </OutlineYellowButton>
                                    </Tooltip>
                                </div>
                            </SlideInWrapper>
                        }
                        
                    </div>
                </div>
                <div className='Font-Lg' style={{ marginTop:'25px', padding: '20px', backgroundColor:'var(--intertek-grey-7)' } }>
                Explore our standard test methods below. For additional test methods or custom requests, please contact your Intertek sales representative.
                </div>
                <div style={{marginTop:'10px'} }>
                    <StoreSearchFilter storeItems={storeItems} categories={categories} onFilterChanged={(e) => handleFilterChanged(e)} />
                </div>
            </div>
        </div>

        <div className='ContentContainer' style={{ backgroundColor:'var(--intertek-grey-7)'} }>
            <div className='PortalContentArea'>
                <TableContainer>
                    <Table style={{backgroundColor:'white'} } >
                        <TableBody>
                            {(storeItems != null && storeItems.length > 0) &&
                                <TableRow style={{ backgroundColor: 'var(--intertek-grey-7)' }} className={'Text-Dark Font-Lg'}>
                                    <TableCell></TableCell>
                                    <TableCell>Test Name</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Method</TableCell>
                                    <TableCell>Details</TableCell>
                                    <TableCell>Revision</TableCell>
                                    <TableCell>Qty</TableCell>
                                    <TableCell>Options</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            }
                       
                        {visibleStoreItems.map((storeItem, idx) => {
                            return <StoreItem storeItem={storeItem} key={'StoreItem_' + storeItem.id}
                                showStoreItemDetails={(item) => setShowQuoteItemDetails(item)}
                                toggleFavorite={(item) => handleToggleFavorite(item)}
                                onUpdateQuote={(id, item, quantity, price, priority) => handleUpdateQuote(id, item, quantity, price, priority)}
                                onAddToQuote={(item, quantity, price, priority) => handleAddToQuote(item, quantity, price, priority)} onRemoveFromQuote={(item) => handleRemoveFromQuote(item)} />
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {(storeItems != null && storeItems.length > 0 && visibleStoreItems.length == 0) &&
                    <SlideInWrapper>
                    <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <div className='Font-Md Font-Neo'>Can't find your test? Submit in inquiry here and we'll get back to you.</div>
                                <TextField value={requestTest} onChange={(e) => setRequestTest(e.target.value)} style={{ minWidth: '330px' }}
                                    label="Test Name" />


                        </div>
                        <div>
                            <OutlineButton onClick={() => handleSubmitNotFoundInquiry()}>Submit</OutlineButton>
                        </div>
                    </div>
                    </SlideInWrapper>
                }
                {showQuoteDetail &&
                    <SlideInModal onClose={() => handleCloseQuoteDetailsPanel()}>
                        <QuoteDetailsPanel  storeItems={storeItems} />
                    </SlideInModal>    
                }
                {showQuoteNameDialog && 
                    <QuoteNameDialog onClose={() => setShowQuoteNameDialog(false)}/>
                }
            </div>
        </div>
      

        {
            confirmDialog !== null ?
                <ConfirmDialog Close={confirmDialog.close} Title={confirmDialog.title} Content={confirmDialog.content} CancelText={confirmDialog.cancelText} AcceptText={confirmDialog.acceptText} Data={confirmDialog.data} />
                : null
        }

        {showQuoteItemDetails &&
          <SlideInModal onClose={() => setShowQuoteItemDetails(null)}>
          <div style={{padding:'10px', minWidth:'300px'}}>
              <div>
                    <div className='Font-Md Font-Neo' style={{borderBottom:'1px solid black', paddingBottom:'5px'}}>Test Details</div>
                    <div className='' ><span className='Font-Neo'>Category:</span> <span style={{fontWeight:'100'}}>{showQuoteItemDetails.categoryName}</span></div>
                    
                    <div className='' ><span className='Font-Neo'>Method:</span> <span style={{fontWeight:'100'}}>{showQuoteItemDetails.method}</span></div>
                        <div className='' ><span className='Font-Neo'>Standard:</span> <span style={{fontWeight:'100'}}>{showQuoteItemDetails.standard}</span></div>
                        <div className='' ><span className='Font-Neo'>Revision:</span> <span style={{fontWeight:'100'}}>{showQuoteItemDetails.revision}</span></div>
                        {showQuoteItemDetails.unitDescription != '' &&
                            <div className='' ><span className='Font-Neo'>Unit:</span> <span style={{fontWeight:'100'}}>{showQuoteItemDetails.unitDescription}</span></div>
                        }
                        {showQuoteItemDetails.unitDescription != '' &&
                            <div className='' ><span className='Font-Neo'>Sample Dimensions:</span> <span style={{fontWeight:'100'}}>{showQuoteItemDetails.sampleDimensionDescription}</span></div>
                        }
                        {showQuoteItemDetails.testParameters != '' &&
                            <div className='' ><span className='Font-Neo'>Test Parameters:</span> <span style={{fontWeight:'100'}}>{showQuoteItemDetails.testParameters}</span></div>
                        }
                        {showQuoteItemDetails.customerNotes != '' &&
                            <div className='' ><span className='Font-Neo'>Notes:</span> <span style={{fontWeight:'100'}}>{showQuoteItemDetails.customerNotes}</span></div>
                        }

              </div>
              <div style={{display:'flex', justifyContent:'flex-end'}}>
                  <OutlineButton onClick={() => setShowQuoteItemDetails(null)}>Close</OutlineButton>
              </div>
          </div>
          </SlideInModal>
        }

        <LoggedInFooter />
    </div>)
}