import styles from './NoAccountHeader.module.css'


export default function NoAccountFooter() {
    return (<footer className={styles.Footer}>
        <div className='ContentContainer'>
            <div className='ContentArea'>
                <div style={{ display: 'flex', justifyContent: 'flex-start', flex: 1, position: 'relative', paddingTop:'25px', paddingBottom:'25px' }} className={styles.FooterLinks}>
                    <img className={styles.LargeScreen} src={"/assets/images/roundel.svg"} height={60} width={60} style={{ alignSelf: 'center', marginRight:'25px' }} />
                    <a href='https://intertek.com/disclaimer/'>Disclaimer</a>
                    <a href='https://intertek.com/legal/'>Terms</a>
                    <a href='https://intertek.com/privacy/'>Privacy</a>
                    <a href='https://www.intertek.com/cookie-policy/'>Cookies</a>
                    <a href='https://cdn.intertek.com/www-intertek-com/media/investors/2024/ar/pdf/Modern-Slavery-Act-Statement-2023.pdf'>Modern Slavery Act Statement</a>
                    <img className={styles.LargeScreen} src={"/assets/images/copyrightlogo.svg"} height={45} width={249} style={{ alignSelf: 'center', marginLeft:'auto' }} />
                </div>
                <div className={[styles.MobileOnly, styles.FooterLogos].join(' ')}>
                    <img src={"/assets/images/roundel.svg"} height={45} width={45} style={{ alignSelf: 'center' }} />
                    <img src={"/assets/images/copyrightlogo.svg"} height={45} width={249} style={{ alignSelf: 'center' }} />
                </div>
            </div>
        </div>
    </footer>)
}