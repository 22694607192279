import styles from './NotificationLinks.module.css';
import Button from '@mui/material/Button';
import * as React from 'react';
import { UserContext } from '../UserContext';
import NotificationLinkItem from './NotificationLinkItem';


export default function NotificationLinks(props) {
    const { user, onUserContextChanged, userContextChanged } = React.useContext(UserContext);

    React.useRef(()=>{
        buildNotifications();
    }, []);

    React.useEffect(() => {
        // also when userContext changes
        buildNotifications();
    }, [userContextChanged]);

    function buildNotifications() {
        
    }

    return (<div componentname='NotificationLinks' className={styles.Panel}>
        {props.quotes.length == 0 && 
            <div>
                You have no notifications at this time.
            </div>
        }
        {props.quotes.map((quote, idx) => {
            return (<div key={'NotificationLinks_' + quote.id} style={{marginTop:(idx == 0 ? '0px' : '30px')}}> 
                <NotificationLinkItem onClose={props.onClose} showQuoteDetail={props.showQuoteDetail} quote={quote}  />
            </div>)
        })}

    </div>)
}