import { useEffect, useState } from 'react';

export default function useWebSocket(token, userType) {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (!token) return; // Exit if token is not available

        // Initialize the WebSocket connection
        if (socket == null) {
            const webSocket = new WebSocket(`${import.meta.env.VITE_WEBSOCKETURL}?token=${encodeURIComponent(token)}&userType=${userType}`);
            setSocket(webSocket);

            webSocket.onopen = () => {
                console.log('WebSocket connection established');
            };

            webSocket.onmessage = (event) => {
                console.log('WebSocket message received:', event.data);

                let message = event.data;
                if (typeof message === 'string') {
                    try {
                        message = JSON.parse(message);
                    } catch (e) {
                        console.error('Error parsing message:', e);
                    }
                }
                if (message.messageType === 'QuoteUpdated') {
                    // Dispatch custom event for QuoteUpdated message
                    const event = new CustomEvent('quoteUpdated', { detail: message.quote });
                    window.dispatchEvent(event);
                }
            };

            webSocket.onclose = () => {
                console.log('WebSocket connection closed');
            };

            // Cleanup on component unmount or when token changes
            return () => {
                if (webSocket) {
                    webSocket.close();
                    console.log('WebSocket connection closed on cleanup');
                }
            };
        }
    }, [token, userType]);

    return socket;
}
