import styles from './SampleShipping.module.css';
import OutlineButton from '../../../Buttons/OutlineButton';
import YellowButton from '../../../Buttons/YellowButton';
import { UserContext } from '../../../UserContext';
import * as React from 'react';

export default function SampleShipping(props) {
    const { user, onUserContextChanged, userContextChanged } = React.useContext(UserContext);

    const [shipToAddresses, setShipToAddresses] = React.useState([]);

    React.useEffect(() => {
        let tempShipTo = [];

        // JUST FOR DEBUGGING of multiple ship to addresses
        //user.viewingQuote.items[2].shipToAddress.address1 = "500 Any St";
        //user.viewingQuote.items[2].shipToAddress.address2 = '';
        //user.viewingQuote.items[2].shipToAddress.city = 'Plymouth';
        //user.viewingQuote.items[2].shipToAddress.state = 'MI';
        //user.viewingQuote.items[2].shipToAddress.postalCode = '12345';

        let uniqueAddresses = user.viewingQuote.items.filter((value, index, self) => 
            index === self.findIndex((t) => (
              t.shipToAddress.address1 === value.shipToAddress.address1 &&
              t.shipToAddress.address2 === value.shipToAddress.address2 &&
              t.shipToAddress.city === value.shipToAddress.city &&
              t.shipToAddress.state === value.shipToAddress.state &&
              t.shipToAddress.postalCode === value.shipToAddress.postalCode
            ))
          );

        if (uniqueAddresses.length > 1) {
            // there are multiple ship to addresses.
            for (let addressIndex = 0; addressIndex < uniqueAddresses.length; addressIndex++)
            {
                let itemsForThisAddress = [];
                for (let itemIndex = 0; itemIndex < user.viewingQuote.items.length; itemIndex++)
                {
                    if (user.viewingQuote.items[itemIndex].shipToAddress.address1 == uniqueAddresses[addressIndex].shipToAddress.address1 &&
                        user.viewingQuote.items[itemIndex].shipToAddress.address2 == uniqueAddresses[addressIndex].shipToAddress.address2 &&
                        user.viewingQuote.items[itemIndex].shipToAddress.city == uniqueAddresses[addressIndex].shipToAddress.city &&
                        user.viewingQuote.items[itemIndex].shipToAddress.state == uniqueAddresses[addressIndex].shipToAddress.state &&
                        user.viewingQuote.items[itemIndex].shipToAddress.postalCode == uniqueAddresses[addressIndex].shipToAddress.postalCode)
                    {
                        itemsForThisAddress.push({item: user.viewingQuote.items[itemIndex], index: itemIndex + 1});
                    }
                    
                }
                tempShipTo.push({
                    address: uniqueAddresses[addressIndex].shipToAddress,
                    items:itemsForThisAddress
                })
            }
        }
        else {
            tempShipTo.push({
                address: uniqueAddresses[0].shipToAddress,
                items:[]
            })
        }

        setShipToAddresses(tempShipTo);
        
    },[]);

    return (<div componentname='SampleShipping'>
        <div className='Text-Dark Font-Neo Font-Sm' style={{marginLeft:'auto', marginRight:'auto', marginTop:'10px', padding:'25px'}}>
        Thank you for completing your quote acceptance, payment method and sample information. Our project team will update you on sample receipt and project initiation in 3 to 4 business days.  For further assistance, please contact your Intertek sales representative.
        </div>

        <div className={'Font-Lg Text-Dark'} style={{marginTop:'15px'}}>
            Please submit the samples to:
        </div>

        <div className={`Text-Dark Font-Neo Font-Md ' ${styles.container}`}
            
           
            >
               <div className={styles.textBox} style={{alignSelf:'center'}}>
                {shipToAddresses.length > 1 &&
                    <div style={{color:'var(--error-red)'}}>There are multiple shipping addresses for your samples.</div>
                }

                {shipToAddresses.map((shipTo, idx) => {
                    return (
                        <div className={styles.shipToAddress} key={'Address_' + idx}>
                            {shipTo.items.length > 0 && 
                                <div>
                                    <div className={styles.lineItemInstructions}>Please send the samples for the following line items</div>
                                    <div style={{marginLeft:'25px'}}>
                                        {shipTo.items.map((item, itemIdx) =>
                                        <span className={styles.shipToItems} key={'ShipToItem' + itemIdx}>Item {item.index} {itemIdx == shipTo.items.length - 1 ? '' : ','} </span>
                                        )}
                                    </div>
                                </div>
                            }
                            <div>Intertek</div>
                            <div>Attn: {user.salesRep.firstname + ' ' + user.salesRep.lastname}</div>
                            <div>{shipTo.address.address1}</div>
                            {shipTo.address.address2 != '' &&
                                <div>
                                    {shipTo.address.address2}
                                </div>
                            }
                            <div>{shipTo.address.city}, {shipTo.address.state} {shipTo.address.postalCode}</div>
                        </div>
                    )
                    })
                }
               </div>
               <div className={styles.imageBox}>
                
               </div>
           </div>

           <div style={{marginTop:'25px', display:'flex', justifyContent:'flex-end'}}>
                   <OutlineButton onClick={() => props.onClose()}>Back to Home</OutlineButton>
           </div>

    </div>)
}