import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialog(props) {
    

    const handleClose = (accepted) => {
        props.Close(accepted, props.Data);
    };

    return (

        <Dialog
          
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={true}
        >
            <DialogTitle id="alert-dialog-title">
                {props.Title === undefined ? null : props.Title}
            </DialogTitle>
            <DialogContent>
                {props.Content}
            </DialogContent>
            <DialogActions>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>

                    {props.AcceptText === undefined ? null :
                        <Button className='ActionButton'  onClick={() => handleClose(true, props.Data)} autoFocus >
                            {props.AcceptText}
                        </Button>
                    }

                    {props.CancelText === undefined ? null :
                        <Button className='CancelButton' sx={{ ml: '10px' }}  onClick={() => handleClose(false, null)}>{props.CancelText}</Button>
                    }
                </div>
            </DialogActions>
        </Dialog>

    );
}