import * as React from 'react';
import { UserContext } from '../../../UserContext';
import PdfFileViewer from '../../../PdfGenerator/PdfFileViewer';


export default function ReadyForAcceptance(props) {
    const { user, onUserContextChanged, userContextChanged } = React.useContext(UserContext);

    return <div componentname='ReadyForAcceptance'>
        
        <div style={{ overflow: 'hidden', border: '1px solid #ccc', width: '80%', height: '600px', margin: 'auto' }}>
            <PdfFileViewer
                src={import.meta.env.VITE_APIURL + 'CustomerQuote/GetPdf' + '?quoteId=' + user.viewingQuote.id}
            />
        </div>
    </div>
}