import { useState } from 'react';
import styles from './IconButton.module.css'
import Notification from '../Notification/Notification.jsx'

// props:
// src: required - the image to display
// mouseOver: optional - the mouseover image to display. If not provided, then the component will toggle the background color
// notification: optional - the value (integer?) used to include a Notification
// height: optional
// width: optional
// style: optional - applies to container
// imageStyle: optional - applies to image
// onClick: optional 
export default function IconButton(props) {
    const { children, src='', mouseOver=undefined,notification=undefined, height= undefined, width=undefined, style= undefined, imageStyle= undefined, onClick=undefined, ...otherProps } = props;
    const [isMouseOver, setIsMouseOver] = useState(false);

    function handleClick(e) {
        if (props.onClick) {
            props.onClick(e);
        }
    }

    return (
        <div style={{ display: 'flex', ...props.style }}
            onMouseEnter={() => setIsMouseOver(true)}
            onMouseLeave={() => setIsMouseOver(false)}
            onClick={(e) => handleClick(e)}>
            <div className={props.mouseOver !== undefined ? null : (isMouseOver ? styles.backgroundHover : styles.backgroundNormal)}
              
            >
                <img
                    className={styles.Icon} src={isMouseOver ? (props.mouseOver === undefined ? props.src : props.mouseOver) : props.src}
                    style={{ ...props.imageStyle, height: props.height === undefined ? '44px' : props.height, width: props.width === undefined ? '44px' : props.width }}
                 
                />
            </div>
            {props.notification === undefined ? null :
                <Notification value={props.notification} />    
            }
            {children }
        </div>
    )
}