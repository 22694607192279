import LoggedInHeader from '../../Navbars/LoggedInHeader';
import LoggedInFooter from '../../Navbars/LoggedInFooter';
import styles from './QuotesView.module.css';
import { UserContext } from '../../UserContext'
import { Navigate, Link, useNavigate, useLocation } from 'react-router-dom';
import * as React from 'react';
import SlideInWrapper from '../../SlideInWrapper/SlideInWrapper';
import YellowButton from '../../Buttons/YellowButton';
import OutlineYellowButton from '../../Buttons/OutlineYellowButton';
import QuoteSearchFilter from './QuoteSearchFilter';

import CustomerService from '../../../services/CustomerService';
import IntertekLoading from '../../Common/IntertekLoading';
import dayjs from 'dayjs';
import { formatStatus } from '../../utils';
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '../../IconButton/IconButton';
import cloneDeep from 'lodash.cloneDeep';
import SlideInModal from '../../SlideInModal/SlideInModal';
import QuoteDetailsPanel from '../QuoteDetails/QuoteDetailsPanel';
import ConfirmDialog from '../../Common/ConfirmDialog';

export default function QuotesView() {
    const { user, onUserContextChanged, onQuoteChanged, userContextChanged, storeItems, setStoreItems, categories, setCategories } = React.useContext(UserContext);
    const [viewQuotes, setViewQuotes] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [showQuoteDetail, setShowQuoteDetail] = React.useState(false);
    const [confirmDialog, setConfirmDialog] = React.useState(null);
    const [defaultSearch, setDefaultSearch] = React.useState('');
    const navigate = useNavigate();
    const filters = React.useRef({
        searchValue: '',
        dateRange: '',
        status: '',
        viewFavorites: false
    })

    React.useEffect(() => {
        // initial load
        buildRecentQuotes();

        if (storeItems.length == 0) {
            getStoreItems();
        }

    }, [])

    React.useEffect(() => {
        // Parse the query parameters
        const queryParams = new URLSearchParams(location.search);
        const searchQuote = queryParams.get('search');

        if (searchQuote !== null && searchQuote.trim() != '') {
            filters.current.searchValue = searchQuote;
            buildRecentQuotes();
            setDefaultSearch(searchQuote);
        }

        const view = queryParams.get('view');
        if (view !== null && view.trim() != '') {
            if (view == 'favorites') {
                filters.current = {
                    searchValue: '',
                    dateRange: 'All',
                    status: 'All',
                    viewFavorites: true
                };
            }
            buildRecentQuotes();
        }

   
    }, [location.search, location.pathname, navigate]); // Depend only on search and pathname parts of location

    React.useEffect(() => {
        // also when userContext changes
        buildRecentQuotes();

        if (storeItems.length == 0) {
            getStoreItems();
        }

    }, [userContextChanged, user])

    function getStoreItems() {
        setIsLoading(true);
        CustomerService.send('GET', '', 'Category/Customer')
            .then((response) => {
                setIsLoading(false);
                if (response !== null) {
                    if (response.result === "SUCCESS") {
                        setCategories(response.categories == null ? [] : response.categories);

                        // create a categoryName property on the storeItem
                        for (let i = 0; i < response.storeItems.length; i++) {
                            for (let j = 0; j < response.categories.length; j++) {
                                if (response.storeItems[i].categoryId == response.categories[j].id) {
                                    response.storeItems[i].categoryName = response.categories[j].name;
                                    break;
                                }
                            }
                        }

                        setStoreItems(response.storeItems == null ? [] : response.storeItems);
                    }
                }

            })
            .catch((response) => {
                setIsLoading(false);
                if (response !== null) {
                    //toast.error(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred');
                }
                else {

                }
            });
    }

    function handleSearchFilterChanged(searchValue, dateRange, status, viewFavorites) {
        filters.current = {
            searchValue: searchValue,
            dateRange: dateRange,
            status: status,
            viewFavorites: viewFavorites
        };
        buildRecentQuotes();
    }

    function buildRecentQuotes() {
        if (user !== null) {
            if (user.customerQuotes !== null && user.customerQuotes !== undefined) {

                let tempArray = [...user.customerQuotes];
                // order by date 
                tempArray.sort((a, b) => {
                    const dateA = new Date(a.statusDateTimeUTC);
                    const dateB = new Date(b.statusDateTimeUTC);
                    return dateA - dateB;
                });
                // reverse the order so most recente are on top
                tempArray.reverse();

                for (let i = tempArray.length - 1; i >= 0; i--) {
                    if (filters.current.searchValue.trim() != '') {
                        let search = filters.current.searchValue.toLowerCase();
                        let isFound = false;
                        if (!isFound && tempArray[i].quoteNumber != null && tempArray[i].quoteNumber.toString().indexOf(search) > -1) {
                            isFound = true;
                        }
                        else if (!isFound && tempArray[i].displayName != null && tempArray[i].displayName.toLowerCase().indexOf(search) > -1) {
                            isFound = true;
                        }
                        else if (!isFound && tempArray[i].additionalInformation != null && tempArray[i].additionalInformation.toLowerCase().indexOf(search) > -1) {
                            isFound = true;
                        }
                        else if (!isFound && tempArray[i].intertekQuoteNumber != null && tempArray[i].intertekQuoteNumber.toLowerCase().indexOf(search) > -1) {
                            isFound = true;
                        }
                        else if (!isFound && tempArray[i].pONumber != null && tempArray[i].pONumber.toLowerCase().indexOf(search) > -1) {
                            isFound = true;
                        }

                        if (!isFound) {
                            // check store items
                            for (let itemIdx = 0; itemIdx < tempArray[i].items.length; itemIdx++) {
                                let storeItemId = tempArray[i].items[itemIdx].storeItemId;

                                for (let storeItemIdx = 0; storeItemIdx < storeItems.length; storeItemIdx++)
                                {
                                    if (storeItems[storeItemIdx].id == storeItemId) {
                                        if (storeItems[storeItemIdx].method.toLowerCase().indexOf(search) > -1 ||
                                            storeItems[storeItemIdx].standard.toLowerCase().indexOf(search) > -1) {
                                            isFound = true;
                                            
                                        }
                                        break;
                                    }
                                }
                            }
                        }


                        if (!isFound) {
                            tempArray.splice(i, 1);
                            continue;
                        }
                    }

                    if (filters.current.dateRange == '' || filters.current.dateRange == 'All') {
                        // filter not set
                    }
                    else {
                        let today = dayjs();
                        if (filters.current.dateRange == 'Recent') {
                            // perform this after filtering
                        }
                        else if (filters.current.dateRange == 'CurrentMonth' && !dayjs(tempArray[i].statusDateTimeUTC).isSame(dayjs(), 'month')) {
                            tempArray.splice(i, 1);
                            continue;
                        }
                        else if (filters.current.dateRange == '6Months' && !dayjs().add(-6, 'month').isAfter(dayjs(tempArray[i].statusDateTimeUTC).isSame(dayjs(), 'month'))) {
                            tempArray.splice(i, 1);
                            continue;
                        }
                        else if (filters.current.dateRange == '12Months' && !dayjs().add(-12, 'month').isAfter(dayjs(tempArray[i].statusDateTimeUTC).isSame(dayjs(), 'month'))) {
                            tempArray.splice(i, 1);
                            continue;
                        }
                        else if (filters.current.dateRange == '24Months' && !dayjs().add(-24, 'month').isAfter(dayjs(tempArray[i].statusDateTimeUTC).isSame(dayjs(), 'month'))) {
                            tempArray.splice(i, 1);
                            continue;
                        }
                        else if (filters.current.dateRange == '36Months' && !dayjs().add(-36, 'month').isAfter(dayjs(tempArray[i].statusDateTimeUTC).isSame(dayjs(), 'month'))) {
                            tempArray.splice(i, 1);
                            continue;
                        }
                    }

                    if (filters.current.status == '' || filters.current.status == 'All') {
                        // filter not set
                    }
                    else {
                        let currentStatus = formatStatus(tempArray[i]);
                        if (filters.current.status != currentStatus) {
                            tempArray.splice(i, 1);
                            continue;
                        }
                    }

                    if (filters.current.viewFavorites && tempArray[i].isFavorite == false) {
                        tempArray.splice(i, 1);
                        continue;
                    }

                }

                if (filters.current.dateRange == 'Recent') {
                    setViewQuotes(tempArray.slice(0, 10));
                }
                else {
                    setViewQuotes(tempArray);
                }


            }
            else {
                setIsLoading(true);
                CustomerService.send('GET', '', 'CustomerQuote')
                    .then((response) => {
                        setIsLoading(false);
                        if (response !== null) {
                            if (response.result === "SUCCESS") {
                                if (response.customerQuotes !== null) {
                                    user.customerQuotes = response.customerQuotes;
                                    onUserContextChanged(); // will trigger the useEffect
                                }
                            }
                        }

                    })
                    .catch((response) => {
                        setIsLoading(false);
                        if (response !== null) {
                            //toast.error(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred');
                        }
                        else {

                        }
                    });
            }
        }


    }

    function isPaidorPO(quote) {
        if (quote.status == 'Paid' || quote.status == 'QuotationCompleted') {
            return true;
        }

        return false;
    }

    function hasSampleSubmission(quote) {
        if (quote.sampleSubmission != null) {
            return true;
        }

        return false;
    }

    function handleViewQuote(quote) {
        if (didClickDuplicate.current == false) {

            user.viewingQuote = quote;
            onUserContextChanged();

            // if Draft, go to the Store so they can edit their quote
            if (user.viewingQuote.status == 'Draft') {
                navigate('/Portal/Store?open=true');
            }
            else {
                setShowQuoteDetail(true);
            }

        }

        didClickDuplicate.current = false;
    }

    const didClickDuplicate = React.useRef(false);
    function handleConfirmDuplicateQuote(event, quote) {

        event.preventDefault(); // not seeming to work...

        didClickDuplicate.current = true;
        setConfirmDialog({
            show: true,
            close: handleDuplicateQuoteClosed,
            cancelText: 'Close',
            acceptText: 'Duplicate Quote',
            title: 'Duplicate Quote',
            content: <div>
                <div style={{ fontWeight: 'bold', marginBottom: '25px' }}>Are you sure you want to Duplicate this Quotes?</div>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                 
                </div>
            </div>,
            data: quote
        })

    }

    function handleDuplicateQuoteClosed(didAccept, data) {
        if (didAccept) {
            setIsLoading(true);
            let postData = {
                quote: data
            }
            CustomerService.send('POST', postData, 'CustomerQuote/Duplicate')
                .then((response) => {
                    setIsLoading(false);
                    if (response !== null) {
                        if (response.result === "SUCCESS") {
                            user.customerQuotes.push(response.customerQuote);
                            user.viewingQuote = response.customerQuote;
                            onUserContextChanged();
                            navigate('/Portal/Store?open=true');
                        }
                    }
    
                })
                .catch((response) => {
                    setIsLoading(false);
                    if (response !== null) {
                        //toast.error(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred');
                    }
                    else {
    
                    }
                });
        }
        setConfirmDialog(null);
    }


    function handleCloseQuoteDetailsPanel() {
        setShowQuoteDetail(false);
    }

    function toggleFavorite(event, quote) {
        event.preventDefault();
        event.stopPropagation();

        quote.isFavorite = !quote.isFavorite;
        
        let tempQuotes = cloneDeep(viewQuotes);
        for (let i =0; i < tempQuotes.length; i++) {
            if (tempQuotes[i].id == quote.id) {
                tempQuotes[i].isFavorite = quote.isFavorite;
                break;
            }
        }
        setViewQuotes(tempQuotes);
        let postData = {
            quote: quote,
            statusChange: 'Favorite'
        }
        CustomerService.send('PUT', postData, 'CustomerQuote')
            .then((response) => {
                if (response.customerQuote !== null) {
                    onQuoteChanged(response.customerQuote);  // find the quote in the customer's list and update that as well.
                    onUserContextChanged();
                }
            })
            .catch((response) => {
               
            })
    }

    function handleStartNewQuote() {
        user.viewingQuote = undefined;
        navigate('/Portal/Store');
    }


    return <div componentname='QuotesView'>
        <LoggedInHeader />
        <div className={styles.QuotesHeader + ' ContentContainer'}>
            <div className='PortalContentArea'>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className='Font-Xxl' style={{ color: 'var(--intertek-blue-8)', marginTop: '25px', fontFamily: 'Source Sans Pro' }}>All Quotations</div>
                    <div style={{ display: 'flex' }}>
                        <YellowButton onClick={handleStartNewQuote} style={{ alignSelf: 'center' }}>New Quote</YellowButton>
                        <YellowButton component={Link} to="/Portal" style={{ alignSelf: 'center' }}>My Portal</YellowButton>
                        {user === null || user.viewingQuote === undefined || user.viewingQuote === null ?
                            null :
                            <SlideInWrapper style={{ padding: '15px' }}>
                                <div className='pulse-yellow-button'>
                                    <OutlineYellowButton onClick={() => setShowQuoteDetail(true)}>{formatStatus(user.viewingQuote)} - {user.viewingQuote.displayName === '' ? user.viewingQuote.quoteNumber : user.viewingQuote.displayName}</OutlineYellowButton>
                                </div>
                            </SlideInWrapper>
                        }

                    </div>
                </div>
                <div className='Font-Lg' style={{ marginTop: '25px', padding: '20px', backgroundColor: 'var(--intertek-grey-7)' }}>
                Quote management is simple: view, update, duplicate, mark favorites, or filter quotes with ease!
                </div>
                <div style={{ marginTop: '10px' }}>
                    <QuoteSearchFilter handleFilterChanged={handleSearchFilterChanged} defaultSearch={defaultSearch} />
                </div>

            </div>
        </div>
        <div className='ContentContainer background-grey'>
            {isLoading ?
                <div style={{ minHeight: '50vh', display: 'flex', justifyContent: 'center', backgroundColor: 'var(--modal-body-light)' }}>
                    <div style={{ alignSelf: 'center' }}>
                        <IntertekLoading />
                    </div>
                </div>
                :
                <div className='PortalContentArea'>
                    <table className={styles.QuotesTable} >
                        <thead>
                            <tr>
                                <th colSpan='2'>
                                    Quote Number
                                </th>
                                <th style={{ textAlign: 'left' }}>
                                    Quote Name
                                </th>
                                <th style={{ textAlign: 'left' }}>
                                    Compiled By
                                </th>
                                <th style={{ textAlign: 'left' }}>
                                    Date
                                </th>
                                <th style={{ textAlign: 'right' }}>
                                    Price
                                </th>
                                <th style={{ textAlign: 'center' }}>
                                    Quote Status
                                </th>
                                <th style={{ textAlign: 'center' }}>Paid/PO</th>
                                <th style={{ textAlign: 'center' }}>SSF</th>
                                <th style={{ textAlign: 'center' }}>Duplicate</th>
                            </tr>
                        </thead>
                        <tbody>
                            {viewQuotes.map((quote, idx) => {
                                let total = 0;
                                for (let i = 0; i < quote.items.length; i++) {
                                    total += quote.items[i].price;
                                }
                                return <tr key={'recentQuotes_' + idx} onClick={() => handleViewQuote(quote)}>
                                    <td style={{ textAlign: 'center' }}><img onClick={(e) => toggleFavorite(e, quote)} src={quote.isFavorite == false ? '/assets/images/Favorite_None.png' : '/assets/images/favorite_mouseover.png'} style={{ height: '42px', width: '42px', marginTop: '3px' }} /></td>
                                    <td style={{ whiteSpace: 'nowrap' }}>{quote.intertekQuoteNumber== undefined ? 'Pending' : quote.intertekQuoteNumber}</td>
                                    <td style={{ textAlign: 'left', maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{quote.displayName == '' ? quote.quoteNumber : quote.displayName}</td>
                                    <td style={{ textAlign: 'left' }}>Sales Rep</td>
                                    <td>{dayjs(quote.statusDateTimeUTC).format('MMMM D, YYYY')}</td>
                                    <td style={{ textAlign: 'right' }}>${total.toFixed(2)}</td>
                                    <td style={{ textAlign: 'center' }}>{formatStatus(quote)}</td>
                                    <td style={{ textAlign: 'center' }}>{isPaidorPO(quote) ?
                                        <span style={{ position: 'relative' }}>
                                            <CircleIcon sx={{ color: 'var(--intertek-green-1)', fontSize: '30px' }} />
                                            <CheckIcon sx={{ fontSize: '20px', position: 'absolute', top: '-7px', left: '5px' }} />
                                        </span> :
                                        <RadioButtonUncheckedIcon sx={{ color: '#e6e6e6', fontSize: '30px' }} />
                                    }</td>
                                    <td style={{ textAlign: 'center' }}>{hasSampleSubmission(quote) ?
                                        <span style={{ position: 'relative' }}>
                                            <CircleIcon sx={{ color: 'var(--intertek-green-1)', fontSize: '30px' }} />
                                            <CheckIcon sx={{ fontSize: '20px', position: 'absolute', top: '-7px', left: '5px' }} />
                                        </span> :
                                        <RadioButtonUncheckedIcon sx={{ color: '#e6e6e6', fontSize: '30px' }} />
                                    }</td>
                                    <td style={{ textAlign: 'center' }}>
                                        <div style={{ display: 'flex', maxWidth: '45px', marginLeft: 'auto', marginRight: 'auto' }}>
                                            <IconButton style={{ alignSelf: 'center' }} src={"/assets/images/duplicateQuote.png"} mouseOver={"/assets/images/duplicateQuote_highlight.png"}
                                                height={42} width={42}
                                                onClick={(event) => { handleConfirmDuplicateQuote(event, quote) }} />
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            }
        </div>
        {showQuoteDetail &&
            <SlideInModal onClose={() => handleCloseQuoteDetailsPanel()}>
                <QuoteDetailsPanel storeItems={[]} onClose={() => handleCloseQuoteDetailsPanel()} />
            </SlideInModal>
        }
        {
            confirmDialog !== null ?
                <ConfirmDialog Close={confirmDialog.close} Title={confirmDialog.title} Content={confirmDialog.content} CancelText={confirmDialog.cancelText} AcceptText={confirmDialog.acceptText} Data={confirmDialog.data} />
                : null
        }
     
    </div>
}