import * as React from 'react';
import styles from './SlideInModal.module.css';
import SlideInWrapper from '../SlideInWrapper/SlideInWrapper';

// props:
// onClose: function requesting close
// canDismiss: bool, can use close by clicking the background
export default function SlideInModal(props) {
    const { children, onClose, delay=0, inOrOut = 'in', direction = 'down', ...otherProps } = props;

    const canDismiss = props.canDismiss == undefined ? true : props.canDismiss;

    // Inject the onClose function into the children components
    const childrenWithProps = React.Children.map(children, (child) => {
        // If the current child is a valid React element
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { onClose: handleClose });
        }
        return child;
    });

    function handleClose() {
        if (props.onClose !== undefined) {
            props.onClose();
        }
    }

    function handleDismiss() {
        if (canDismiss) {
            if (props.onClose !== undefined) {
                props.onClose();
            }
        }
    }

    return (
        <>
            <div className={styles.BackgroundCover} onClick={() => handleDismiss()}>
            </div>
            <div className={styles.ModalContainer}>
                <div style={{  }}>
                    <SlideInWrapper inOrOut={inOrOut} direction={direction} delay={delay }>
                        <div style={{ background: 'white', borderRadius:'5px' }}>
                            {childrenWithProps}
                        </div>
                    </SlideInWrapper>
                </div>

            </div>
        </>
    )
}