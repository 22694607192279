import * as React from 'react';
import NoAccountHeader from "../Navbars/NoAccountHeader"
import NoAccountFooter from "../Navbars/NoAccountFooter"
import NoAccountImageHeader from '../Navbars/NoAccountImageHeader';
import OutlineButton from '../Buttons/OutlineButton';
import SlideInWrapper from "../SlideInWrapper/SlideInWrapper";
import AccountRequestDialog from '../AccountRequestDialog/AccountRequestDialog'
import { useLocation } from 'react-router-dom';
import eventEmitter from '../Common/eventEmitter';
import CustomerVerificationDialog from './CustomerVerificationDialog';

export default function Landing(props) {
    let location = useLocation();
    const [showAccountRequestDialog, setShowAccountRequestDialog] = React.useState(false);
    const [loginEmail, setLoginEmail] = React.useState('');
    const [verificationToken, setVerificationToken] = React.useState(null);

    React.useState(() => {

        const searchParams = new URLSearchParams(location.search);

        // Check if 'token' exists in the query parameters
        const tokenParam = searchParams.get('token');
        if (tokenParam) {
            setVerificationToken(tokenParam); // Set the token value in the state
    
            // Create the URL object based on the current location
            const url = new URL(window.location.href);
    
            // Now, remove the query parameters
            url.search = ''; // This clears the query string
    
            // Update the URL without reloading the page
            window.history.replaceState({}, document.title, url.href);
    
            // if not removed, then after verifying and SSO login, the page will get the token again
        }
        eventEmitter.on('openAccountRequestDialog', handleOpenDialogFromUnknownLogin);

        // Clean up the event listener when the component unmounts
        return () => {
          eventEmitter.off('openAccountRequestDialog', handleOpenDialogFromUnknownLogin);
        };

      
    }, [])

    function handleOpenDialogFromUnknownLogin(data) {
        setLoginEmail(data.email);
        setShowAccountRequestDialog(true);
    }

    return (<>
        <div>
            <NoAccountHeader />
            <NoAccountImageHeader />
            <div>
                <div className='ContentContainer' style={{ height: '106px',backgroundColor:'var(--intertek-grey-2)' }}>
                    <div className='ContentArea' style={{alignSelf:'center'}}>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <div className='Text-Dark Font-Md' style={{alignSelf:'center'} }>Don't have an account yet?</div>
                            <OutlineButton onClick={() => {setLoginEmail(''); setShowAccountRequestDialog(true);} }>Account Request</OutlineButton>
                        </div>
                    </div>
                </div>

                <div className='ContentContainer'>
                    <div className='ContentArea' style={{ alignSelf: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                           
                           
                        
                        </div>
                    </div>
                </div>
            </div>
            <NoAccountFooter />
        </div>

        {verificationToken && <CustomerVerificationDialog verificationToken={verificationToken} onClose={() => setVerificationToken(null)} />}
        {showAccountRequestDialog && <AccountRequestDialog loginEmail={loginEmail} onClose={() => setShowAccountRequestDialog(false)} logoutRequested={props.logoutRequested} /> }
    </>)
}