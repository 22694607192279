/* eslint-disable react/prop-types */
import styles from './LatestNews.module.css';
import { Button } from '@mui/material';

// props
// title
// content
// link
export default function LatestNewsItem(props) {
    return (<div className={styles.LatestNewsItem}>
        <div className={styles.LatesNewsItemTitle + ' Font-Lg'}>{props.title}</div>
        <div className={styles.LatesNewsItemContent}>{props.content}</div>
        <div className={styles.LatesNewsItemFooter}>
            <Button>Learn more</Button> </div>
    </div>)
}