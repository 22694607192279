/* eslint-disable react/no-unknown-property */
import * as React from 'react';
import styles from './QuoteDetailsPanel.module.css';
import IconButton from '../../IconButton/IconButton';
import LimitedTextField from '../../Common/LimitedTextField';
import { UserContext } from '../../UserContext';
import CloseIcon from '@mui/icons-material/Close';

export default function QuoteDetailsHeader(props) {
    const { user, onUserContextChanged, userContextChanged } = React.useContext(UserContext);

    const [quoteName, setQuoteName] = React.useState(user.viewingQuote.displayName);
    const [formErrors, setFormErrors] = React.useState({});


    function handleSaveAndCloseClicked() {
        let isValid = true;
        let errors = {};
        if (quoteName.trim() == '')
        {
            isValid = false;
            errors.quoteName='Required'
        }

        setFormErrors(errors);

        if (isValid) {
            props.OnSaveAndClose(quoteName);
        }
    }
    return (<div className={`${styles.QuoteDetailsHeader} ${props.status === 'Submitted' ? styles.Submitted : ''}`}  componentname='QuoteDetailsHeader'>
        <div style={{display:'flex', justifyContent:'center', width:'100%', backgroundColor:props.status === 'QuotationCompleted' ? 'var(--intertek-blue-1)': 'inherit'} }>
            {props.status === 'Submitted' || props.status === 'QuotationCompleted' ?
                 <div className='Text-Dark Font-Lg' style={{ alignSelf: 'center', marginRight: '5px' }}>Thank You</div>
                :
                <>
                    <div style={{ display: 'flex' }}>
                        <div className='Text-Dark Font-Sm' style={{ alignSelf: 'center', marginRight: '5px' }}>Quote Name</div>
                        <div style={{ alignSelf: 'center' }}>
                            <LimitedTextField error={formErrors.quoteName != undefined} onChange={(e) => setQuoteName(e.target.value)} value={quoteName} maxLength={100} size='small' placeholder='Enter a Quote Name' style={{ maxWidth: '180px' }} sx={{ '& .MuiInputBase-root': { bgcolor: formErrors.quoteName == undefined ? '#ffffff' : '#D98989' } }} />
                        </div>
                    </div>

                    <div style={{ display: 'flex', marginLeft: '10px' }}>
                        <div className='Text-Dark Font-Sm' style={{ alignSelf: 'center', marginRight: '5px' }}>Quote Number</div>
                        <div style={{ alignSelf: 'center', width: '160px', height: '35px', paddingLeft: '5px', borderRadius: '4px', display: 'inline-flex' }}>
                            <div style={{ alignSelf: 'center' }}>{user.viewingQuote.intertekQuoteNumber == null ? 'Pending' : user.viewingQuote.intertekQuoteNumber }</div>
                        </div>
                    </div>

                    <div style={{ alignSelf: 'center', marginLeft: '10vw', display: 'flex' }}>
                        <div style={{ display: 'flex', alignSelf: 'center', marginLeft: '3vw' }} className='TextButton'>
                            <IconButton style={{ alignSelf: 'center', marginRight: '5px' }} src={"/assets/images/save_dark.svg"} mouseOver={"/assets/images/save_light.svg"} height={24} width={24}
                                onClick={() => handleSaveAndCloseClicked()}>
                                <div style={{ alignSelf: 'center', marginLeft: '6px' }} className='Text-Dark Font-Sm'>Save & Close</div>
                            </IconButton>
                        </div>
                     
                    </div>
                </>
            }
          
        </div>
        <div style={{display:'flex', backgroundColor:props.status === 'QuotationCompleted' ? 'var(--intertek-blue-1)': 'inherit'}}>
            <CloseIcon className={styles.CloseQuote} sx={{fontSize:'30pt'}} onClick={() => handleSaveAndCloseClicked()}/>
        </div>
    </div>)
}