export const formatStatus = (quote) => {
    if (quote.status == 'UnderReview') {
        return 'Under Review';
    }
    else if (quote.status == "ReadyForAcceptance") {
            return "Ready for Acceptance";
    }
    else if (quote.status == "Accepted") {
            return "Awaiting Payment";
    }
    else if (quote.status == "Paid" && quote.sampleSubmissionForm == null) {
        return "Awaiting SSF";
    }
    else if (quote.status == "QuotationCompleted") {
        return "Quotation Completed";
    }
    else if (quote.status == "CancelRequested") {
        return "Cancellation Requested";
    }
    else {
        return quote.status;
    }
};