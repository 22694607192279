import styles from '../AcceptanceForm/AcceptanceForm.module.css';
import DraftView from '../DraftView/DraftView';

export default function UnderReview(props) {
    return (<div componentname='UnderReview'>
        
        <div className={styles.section + ' Text-Dark Font-md'} style={{marginTop:'5px', textAlign:'center'}}>
            Your draft quote is under review and tentatively unavailable for changes. For questions, contact your Intertek sales representative.
        </div>
        <DraftView storeItems={props.storeItems} />
</div>)
}