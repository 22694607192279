import React from 'react';
import './StatusIndicator.css'; // Import the CSS file for styling
import { UserContext } from '../../UserContext';

// props
// required: steps - array of elements describing the step
const StatusIndicator = ({ steps, currentStep, onClicked, pendingStep }) => {

    const { user, onUserContextChanged, userContextChanged } = React.useContext(UserContext);

    function handleIndicatorClicked(step) {
        if (step.canClick) {
            onClicked(step);
        }
    }

    return (
        <div className="status-indicator-container">
           {/* <div className={`status-indicator-line`}></div>*/}
            <div className="status-indicator">
            {steps.map((step, index) => (
                <div
                    className={`step ${currentStep === index + 1 ? 'active' : ''} `}
                    key={index}
                >
                    <div className={`status-indicator-group`}>
                        <div onClick={() => handleIndicatorClicked(step)} className={`circle ${pendingStep === index + 1 ? 'pendingStep' : ''} ${currentStep === index + 1 ? 'current' : ''}  ${currentStep > index + 1 ? 'completed' : ''} ${step.canClick ? 'clickable' : 'noClick'}`}>
                            {currentStep > (index + 1)?
                                <img src='/assets/images/check_dark.svg' style={{width:'12px', height:'9px'} } />
                                :
                                <>{index + 1}</> 
                            }
                            
                        </div>
                        <div className="label">{step.stepName}</div>
                    </div>
                    <div className={`line ${currentStep > index + 1 ? 'completed' : ''}`}></div>
                </div>
            ))}
            </div>
          
        </div>
    );
};

export default StatusIndicator;