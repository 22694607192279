import styles from './NoAccountHeader.module.css'
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Drawer from '@mui/material/Drawer';



export default function NoAccountHeader() {

    const [isMouseOver, setIsMouseOver] = useState(false);
    const [globalMenuShow, setGlobalMenuShow] = useState(false);
    function toggleGlobalMenu() {
        setGlobalMenuShow(!globalMenuShow);
    }

    return (<>
        <div className='ContentContainer'>
        <div className='ContentArea'>
            <div className={styles.AppHeader}>

                <div className={styles.Container}>
                    <div className={styles.HeaderLinks}>
                        <a>
                            <span><a href="https://www.intertek.com/about/our-responsibility/">Responsibility</a></span>
                        </a>
                        <a>
                            <span><a href="https://www.intertek.com/investors/">Investors</a></span>
                        </a>
                        <a>
                            <span><a href="https://www.intertek.com/press/">Media</a></span>
                        </a>
                        <a>
                            <span><a href="https://www.intertek.com/careers/">Careers</a></span>
                        </a>
                        {/* <a>
                            <span style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}
                                onMouseEnter={() => setIsMouseOver(true)}
                                onMouseLeave={() => setIsMouseOver(false)}
                                onClick={() => toggleGlobalMenu() }
                            >
                                <img style={{ alignSelf: 'center' }} src={isMouseOver ? '/assets/images/global_light.svg' : '/assets/images/global.svg'} height='25px' width='25px' />
                                <div style={{ alignSelf: 'center', marginLeft: '3px', marginRight: '3px' }}>Global</div>
                                <KeyboardArrowDownIcon style={{ alignSelf: 'center', color: isMouseOver ? 'white' : 'var(--intertek-blue-7)' }} />
                            </span>
                        </a> */}
                    </div>


                </div>
            </div>
        </div>
    </div>
        <Drawer
            style={{ display: globalMenuShow ? 'inherit' : 'none'} }
            anchor="top"
            open={globalMenuShow}
            onClose={() => setGlobalMenuShow(false)}
            PaperProps={{
                sx: {
                    marginTop: '40px', // Adjust top margin to match Intertek.coms
                    // This targets the sliding in animation
                    ...(globalMenuShow && {
                        transform: 'translateY(0px) !important', // Ending state (open)
                    }),
                }
            }}
            BackdropProps={{
                style: {
                    opacity: 0,
                },
            }}
        >
            <div className={styles.Drawer}>
            <div class="col-12 col-xl-9 ww-flags-wrap">
                      <a href="https://www.intertek-ar.com">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for Argentina: Will take you to the Spanish intertek-ar.com">
                                    <title>Flag for Argentina</title>
                                    <desc>Visit intertek-ar.com in Spanish</desc>
                                    <use href="https://intertek.com/images/flags.svg#ar"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> Argentina </div>
                                  <div class="region-lang"> Español </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek-br.com">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for Brazil: Will take you to the Portuguese intertek-br.com">
                                    <title>Flag for Brazil</title>
                                    <desc>Visit intertek-br.com in Portuguese</desc>
                                    <use href="https://intertek.com/images/flags.svg#br"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> Brazil </div>
                                  <div class="region-lang"> Português </div>
                              </div>
                          </div>
                      </a>
                      <div className={styles.regionSelect}>
                          <div className={styles.regionFlag}>
                              <img className={styles.regionFlag} src="https://canada.intertek.com//globalassets/_media/flags/ca-flag-vector.svg" alt="Canadian Flag" />
                          </div>
                          <div className={styles.regionDetails}>
                              <div class="region"> Canada </div>
                              <div class="region-lang"> <a href="https://canada.intertek.com/" style={{color: '#C1CAD3', display:'contents!important'}}>English</a> | <a href="https://canada.intertek.com/fr/" style={{color: '#C1CAD3', display:'contents!important'}}>Français</a></div>
                          </div>
                      </div>
                      <img src="https://www.intertek.com/images/flags.svg#ar" />
                      <a href="https://www.intertek-cz.com">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <img src="https://intertek.com/images/flags.svg#cz" role="img" aria-label="Flag for the Czech Republic: Will take you to the Czech intertek-cz.com" />
                                  
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> Czech Republic </div>
                                  <div class="region-lang"> Čeština </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek.com.cn">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for China: Will take you to the Chinese intertek.com.cn">
                                    <title>Flag for China</title>
                                    <desc>Visit intertek.com.cn in Chinese</desc>
                                      <use href="https://intertek.com/images/flags.svg#cn"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> China (Mainland) </div>
                                  <div class="region-lang"> 中文 </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek.com.co">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for Colombia: Will take you to the Spanish intertek.com.co">
                                    <title>Flag for Colombia</title>
                                    <desc>Visit intertek.com.co in Spanish</desc>
                                      <use href="https://intertek.com/images/flags.svg#co"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> Colombia </div>
                                  <div class="region-lang"> Español </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek.com.do">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for Dominican Republic: Will take you to the Spanish intertek.com.do">
                                    <title>Flag for Dominican Republic</title>
                                    <desc>Visit intertek.com.do in Spanish</desc>
                                      <use href="https://intertek.com/images/flags.svg#do"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> Dominican Republic </div>
                                  <div class="region-lang"> Español </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek.fi">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for Finland: Will take you to the intertek.fi">
                                    <title>Flag for Finland</title>
                                    <desc>Visit intertek.fi</desc>
                                      <use href="https://intertek.com/images/flags.svg#fi"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> Finland </div>
                                  <div class="region-lang"> Suomi </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek-france.com">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for France: Will take you to the intertek-france.com">
                                    <title>Flag for France</title>
                                    <desc>Visit intertek-france.com</desc>
                                      <use href="https://intertek.com/images/flags.svg#fr"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> France </div>
                                  <div class="region-lang"> Français </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek.de">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for Germany: Will take you to the intertek.de">
                                    <title>Flag for Germany</title>
                                    <desc>Visit intertek.de</desc>
                                      <use href="https://intertek.com/images/flags.svg#de"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> German </div>
                                  <div class="region-lang"> Deutsch </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek.com.hk">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for Hong Kong SAR, China: Will take you to the intertek.com.hk">
                                    <title>Flag for Hong Kong SAR, China</title>
                                    <desc>Visit intertek.com.hk</desc>
                                      <use href="https://intertek.com/images/flags.svg#hk"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> Hong Kong SAR, China </div>
                                  <div class="region-lang"> 中文 </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek.it">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for Italy: Will take you to the intertek.it">
                                    <title>Flag for Italy</title>
                                    <desc>Visit intertek.it</desc>
                                      <use href="https://intertek.com/images/flags.svg#it"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> Italy </div>
                                  <div class="region-lang"> Italiano </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek.com.mx">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for Mexico: Will take you to the intertek.com.mx">
                                    <title>Flag for Mexico</title>
                                    <desc>Visit intertek.com.mx</desc>
                                      <use href="https://intertek.com/images/flags.svg#mx"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> Mexico </div>
                                  <div class="region-lang"> Español </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek.nl">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for The Netherlands: Will take you to the intertek.nl">
                                    <title>Flag for The Netherlands</title>
                                    <desc>Visit intertek.nl</desc>
                                      <use href="https://intertek.com/images/flags.svg#nl"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> The Netherlands </div>
                                  <div class="region-lang"> Nederlands </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek.no">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for Norway: Will take you to the intertek.no">
                                    <title>Flag for Norway</title>
                                    <desc>Visit intertek.no</desc>
                                      <use href="https://intertek.com/images/flags.svg#no"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> Norway </div>
                                  <div class="region-lang"> Norsk </div>
                              </div>
                          </div>
                      </a>
                     
                      <a href="https://www.intertek.com.pe">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for Peru: Will take you to the intertek.com.pe">
                                    <title>Flag for Peru</title>
                                    <desc>Visit intertek.com.pe</desc>
                                      <use href="https://intertek.com/images/flags.svg#pe"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> Peru </div>
                                  <div class="region-lang"> Español </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek.pt">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for Portugal: Will take you to the intertek.pt">
                                    <title>Flag for Portugal</title>
                                    <desc>Visit intertek.pt</desc>
                                      <use href="https://intertek.com/images/flags.svg#pt"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> Portugal </div>
                                  <div class="region-lang"> Português </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek.es">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for Spain: Will take you to the intertek.es">
                                    <title>Flag for Spain</title>
                                    <desc>Visit intertek.es</desc>
                                      <use href="https://intertek.com/images/flags.svg#es"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> Spain </div>
                                  <div class="region-lang"> Español </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek.se">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for Sweden: Will take you to the intertek.se">
                                    <title>Flag for Sweden</title>
                                    <desc>Visit intertek.se</desc>
                                      <use href="https://intertek.com/images/flags.svg#se"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> Sweden </div>
                                  <div class="region-lang"> Svenska </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek.co.th">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for Thailand: Will take you to the intertek.co.th">
                                    <title>Flag for Thailand</title>
                                    <desc>Visit intertek.co.th</desc>
                                      <use href="https://intertek.com/images/flags.svg#th"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> Thailand </div>
                                  <div class="region-lang"> ภาษาไทย </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek.ae">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for United Arab Emirates: Will take you to the intertek.ae">
                                    <title>Flag for United Arab Emirates</title>
                                    <desc>Visit intertek.ae</desc>
                                      <use href="https://intertek.com/images/flags.svg#ae"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> United Arab Emirates </div>
                                  <div class="region-lang"> عربى </div>
                              </div>
                          </div>
                      </a> <a href="https://www.intertek.vn">
                          <div className={styles.regionSelect}>
                              <div className={styles.regionFlag}>
                                  <svg role="img" aria-label="Flag for Vietnam: Will take you to the intertek.vn">
                                    <title>Flag for Vietnam</title>
                                    <desc>Visit intertek.vn</desc>
                                      <use href="https://intertek.com/images/flags.svg#vn"></use>
                                  </svg>
                              </div>
                              <div className={styles.regionDetails}>
                                  <div class="region"> Vietnam </div>
                                  <div class="region-lang"> Tiếng Việt </div>
                              </div>
                          </div>
                      </a>
                  </div>
            </div>
        </Drawer>
    </>)
}