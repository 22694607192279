/* eslint-disable react/no-unknown-property */
import LoggedInHeader from '../Navbars/LoggedInHeader';
import LoggedInFooter from '../Navbars/LoggedInFooter';
import { UserContext } from '../UserContext';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import * as React from 'react';
import IntertekLoading from '../Common/IntertekLoading';
import CustomerService from '../../services/CustomerService';
import YellowButton from '../Buttons/YellowButton';
import PortalLinkItem from './PortalLinkItem/PortalLinkItem';
import styles from './Home.module.css';
import QuoteQuickLinks from './QuoteQuickLinks/QuoteQuickLinks';
import RecentQuotes from './RecentQuotes/RecentQuotes'; 
import LatestNews from './LatestNews/LatestNews';
import SlideInModal from '../SlideInModal/SlideInModal';
import QuoteDetailsPanel from './QuoteDetails/QuoteDetailsPanel';

export default function Home() {
    const { user, onUserContextChanged, userContextChanged } = React.useContext(UserContext);
    const [customerQuotes, setCustomerQuotes] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [showQuoteDetail, setShowQuoteDetail] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (user !== null && user !== null) {
            setIsLoading(true);
            CustomerService.send('GET', '', 'CustomerQuote')
                .then((response) => {
                    setIsLoading(false);
                    if (response !== null) {
                        if (response.result === "SUCCESS") {
                            if (response.customerQuotes !== null) {
                                setCustomerQuotes(response.customerQuotes);

                                // set for global use
                                user.customerQuotes = response.customerQuotes;
                                onUserContextChanged();
                            }
                        }
                    }

                })
                .catch((response) => {
                    setIsLoading(false);
                    if (response !== null) {
                        //toast.error(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred');
                    }
                    else {

                    }
                });
        }
    }, []);

    React.useEffect(() => {
        // Parse the query parameters
        const queryParams = new URLSearchParams(location.search);
        const isOpen = queryParams.get('open') === 'true';

        
        if (isOpen) {
            setShowQuoteDetail(true);
        }

        // Check if there are any query parameters, and only navigate if there are
        if (location.search !== "") {
            navigate(location.pathname, { replace: true }); // Clear all query parameters
        }
    }, [location.search, location.pathname, navigate]); // Depend only on search and pathname parts of location

    if (user === null || user === undefined) {
        // no user logged in
        return <Navigate to="/" />;
    }

    function handleNewQuoteClicked() {
        user.viewingQuote = undefined;
        navigate('/portal/store');
    }

    function handleViewFavoriteQuotes() {
        user.viewingQuote = undefined;
        navigate('/portal/quotes?view=favorites');
    }

    function handleCloseQuoteDetailsPanel() {
        setShowQuoteDetail(false);
    }

    return (<div componentname='Home'>
        <LoggedInHeader />
        <div>
            {isLoading ?
                <div style={{ minHeight: '80vh', display: 'flex', justifyContent: 'center', backgroundColor: 'var(--modal-body-light)' }}>
                    <div style={{ alignSelf: 'center' }}>
                        <IntertekLoading />
                    </div>
                </div>
                :
                <div>
                    <div className='ContentContainer' style={{marginTop:'15px'}} >
                        <div className='PortalContentArea' style={{ alignSelf: 'center', padding:'2px',borderRadius:'4px', backgroundImage:'linear-gradient(-76.52974254928588deg, #06213d 29.38424294823227%, #1fb6d7 100%)' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', height: '102px', paddingLeft:'50px', paddingRight:'50px' }}>
                                <div style={{ alignSelf: 'center' }}>
                                    <div className='Font-Lg Font-Neo' style={{ color: 'var(--intertek-yellow-1)' }}>Welcome Back {user.firstname}</div>
                                    <div className='Font-Md Font-Neo' style={{color:'#ffffff'} }>Effortlessly create and manage your quotes</div>
                                </div>
                                <div style={{ alignSelf: 'center' }}>
                                    <YellowButton onClick={() => handleNewQuoteClicked()}>New Quote</YellowButton>
                                </div>
                            </div>
                        </div>
                    </div>  

                    <div className='ContentContainer' style={{ marginTop: '10px' }} >
                        <div className='PortalContentArea'>
                            <div className={styles.ContentContainer + ' ' + styles.MobileWrap}>

                               
                                <div style={{width:'100%'} }>
                                    <div className={styles.LinksContainer}>
                                        <div className={styles.LinksContainerItem } onClick={() => handleViewFavoriteQuotes()}>
                                            <PortalLinkItem imageSrc='/assets/images/FavoriteQuotations.jpg' message={'Favorite Quotations'} />
                                        </div>
                                        <div className={styles.LinksContainerItem } onClick={() => handleNewQuoteClicked()}>
                                            <PortalLinkItem imageSrc='/assets/images/TestingCapabilities.jpg' message={'Testing Capabilities'} />
                                        </div>
                                        <div className={styles.LinksContainerItem} >
                                            <PortalLinkItem imageSrc='/assets/images/ContactExpert.png' message={<div><div>Contact an Expert</div><div>(Coming Soon)</div></div>} style={{ backgroundColor: 'var(--intertek-grey-8)' }} />
                                        </div>
                                        <div className={styles.LinksContainerItem }>
                                            <PortalLinkItem imageSrc='/assets/images/LatestNews.jpg' message={<div><div>Our Latest News</div><div>(Coming Soon)</div></div>} />
                                        </div>
                                    </div>

                                    <div style={{display:'flex', minWidth:'400px'} }>
                                        <RecentQuotes />
                                        <div className={styles.NewsItems }>
                                           <LatestNews />
                                        </div>
                                    </div>

                                </div>

                                <div>
                                    <QuoteQuickLinks customerQuotes={customerQuotes} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>

        {showQuoteDetail &&
            <SlideInModal onClose={() => handleCloseQuoteDetailsPanel()}>
                <QuoteDetailsPanel  storeItems={[]} />
            </SlideInModal>    
        }

        <LoggedInFooter />
    </div>)
}