import React, { useEffect, useState, useRef } from 'react';
import { UserContext } from '../../UserContext';
import ConfirmDialog from '../../Common/ConfirmDialog';

const PaymentWindow = () => {
  const { user, onUserContextChanged, userContextChanged } = React.useContext(UserContext);
  const [confirmDialog, setConfirmDialog] = React.useState(null);
  const iframeRef = useRef(null); // Create a ref for the iframe

  useEffect(() => {
    // Check if the payment for this quote has been completed
    if (
      user.viewingQuote.status === 'Paid' &&
      user.viewingQuote.paymentData != null &&
      user.viewingQuote.paymentData !== ''
    ) {
      setConfirmDialog({
        show: true,
        close: handleClosePaymentConfirmed,
        acceptText: 'Continue to Sample Submission',
        title: 'Payment Confirmed',
        content: (
          <div>
            <div style={{ fontWeight: 'bold', marginBottom: '25px' }}>
              Your payment has been received.
            </div>
          </div>
        ),
        data: null,
      });
    }
  }, [userContextChanged]);

  function handleClosePaymentConfirmed() {
    setConfirmDialog(null);
  }

  function handleIframeLoad() {
    const iframe = iframeRef.current;
    if (iframe) {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      if (iframeDoc) {
        // Example: Access and manipulate an element inside the iframe
        const iframeBody = iframeDoc.body;
        console.log('iframe loaded. Body content:', iframeBody);

        // Example: Query an element inside the iframe
        const someElement = iframeDoc.querySelector('.example-class');
        if (someElement) {
          console.log('Found element:', someElement);
        }
      }
    }
  }

  return (
    <div>
      <iframe
        ref={iframeRef} // Attach the ref to the iframe
        src={import.meta.env.VITE_PAYMENT_PORTAL}
        style={{
          top: '0px',
          left: '0px',
          bottom: '0px',
          right: '0px',
          width: '100%',
          height: '100%',
          minHeight: '750px',
          minWidth: '300px',
          border: 'none',
          margin: '0px',
          padding: '0px',
          overflow: 'hidden',
          zIndex: '999999',
        }}
        id="paymentFrame"
        onLoad={handleIframeLoad} // Add the onLoad event handler
      ></iframe>
      {confirmDialog !== null ? (
        <ConfirmDialog
          Close={confirmDialog.close}
          Title={confirmDialog.title}
          Content={confirmDialog.content}
          CancelText={confirmDialog.cancelText}
          AcceptText={confirmDialog.acceptText}
          Data={confirmDialog.data}
        />
      ) : null}
    </div>
  );
};

export default PaymentWindow;
