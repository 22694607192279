/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import styles from './LimitedTextField.module.css';

function LimitedTextField({ maxLength, onChange,value, ...props }) {
    const [text, setText] = useState(value === undefined ? '' : value);

    React.useEffect(() => {
        setText(value === undefined ? '' : value);
    }, [value]);

    const handleChange = (event) => {
        const { value } = event.target;
        if (value.length <= maxLength) {
            setText(value);
            onChange(event);
        }
    };

    return (
        <div className={styles.Parent} style={{width:props.fullWidth === undefined ? 'auto' : '100%'}}>
            <TextField
                {...props}
                value={text}
                onChange={handleChange}
                inputProps={{
                    maxLength: maxLength,
                    ...props.inputProps
                }}
            />
            {maxLength - text.length < 10 &&
                <span className={styles.Child}>
                    {`${text.length} of ${maxLength}`}
                </span>    
            }
        </div>
    );
}

export default LimitedTextField;
