import * as React from 'react';
import styles from './StoreView.module.css'
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import BlueButton from '../../Buttons/BlueButton';
import OutlineButton from '../../Buttons/OutlineButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs'; 
import IconButton from '../../IconButton/IconButton';
import { UserContext } from '../../UserContext'

// props:
// storeItem: the store item we're bound to
// toggleFavorite: event
// onAddToQuote: event
// onRemoveFromQuote: event

export default function StoreItem(props) {
    const { user, onUserContextChanged, userContextChanged } = React.useContext(UserContext);
    const [quantity, setQuantity] = React.useState(getInitialQuantity());
    const [previousQuantity, setPreviousQuantity] = React.useState(getInitialQuantity()); 
    const [priority, setPriority] = React.useState(getInitialPriority());
    const [price, setPrice] = React.useState(0);
    const [formErrors, setFormErrors] = React.useState({}); // set to empty object

    const handleQuantityChange = (event) => {
        const inputValue = event.target.value;

        // Check if input is empty or a positive whole number using regex
        if (/^\d*$/.test(inputValue)) {
            setQuantity(inputValue);
            calculatePrice(inputValue);
        }

        // If input is empty, set quantity to 0
        if (inputValue === ''  || inputValue === '0') {
            setQuantity(0);
            calculatePrice(0);
            if (isAddedToQuote()) {
                // remove from quote
                props.onRemoveFromQuote(props.storeItem);
            }
            
        }
    };

    React.useEffect(() => {
        calculatePrice();
    }, [quantity, priority]); // recalculate when these values change

    React.useEffect(() => {
        // userContext changed
        setFormErrors({}); // forces a rerender
    }, [userContextChanged])


    function formatCurrency(value, currency = 'USD', locale = 'en-US') {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency
        }).format(value);
    }

    function calculatePrice() {
        if (quantity.toString().trim() === '' || quantity.toString().trim() === '0') {
            setPrice(0);
            return;
        }
        let discount = priority == 'Standard' ? props.storeItem.discountPercentStandardPriority : priority == 'Rush' ? props.storeItem.discountPercentRushPriority : priority == 'Expedite' ? props.storeItem.discountPercentExpeditePriority : '';
        let discountPercentStartDate = priority == 'Standard' ? props.storeItem.discountPercentStartDateStandardPriority : priority == 'Rush' ? props.storeItem.discountPercentStartDateRushPriority : priority == 'Expedite' ? props.storeItem.discountPercentStartDateExpeditePriority : null;
        let discountPercentEndDate = priority == 'Standard' ? props.storeItem.discountPercentEndDateStandardPriority : priority == 'Rush' ? props.storeItem.discountPercentEndDateRushPriority : priority == 'Expedite' ? props.storeItem.discountPercentEndDateExpeditePriority : null;

        if (props.storeItem.priceItems === undefined || props.storeItem.priceItems.length === 0) {
            //lineItems.push('No Price Items');
           // setResults(lineItems);
            return;
        }

        let items = Math.abs(quantity);
        let total = 0;

        const filteredRanges = props.storeItem.priceItems.filter(range => range.priceType === 'Quantity Range' && range.priority === priority);

        // Sort filtered ranges by startRange
        filteredRanges.sort((a, b) => parseInt(a.endQuantity) - parseInt(b.endQuantity));
        if (filteredRanges.length > 0) {
            // get the max range
            if (items > parseInt(filteredRanges[filteredRanges.length - 1].endQuantity)) {
                setPrice("Too many");
                return;
            }
        }

        for (let i = 0; i < props.storeItem.priceItems.length; i++) {
            if (props.storeItem.priceItems[i].priority === priority) { // all items are in the same list. Only consider this priority

                if (props.storeItem.priceItems[i].priceUnit.priceType === 'Flat') {
                    total += Math.abs(props.storeItem.priceItems[i].amount);
                }
                else if (props.storeItem.priceItems[i].priceType === 'Quantity') {
                    total += Math.abs(props.storeItem.priceItems[i].amount * items);
                }
                else if (props.storeItem.priceItems[i].priceType === 'Quantity Range') {
                    if (parseInt(props.storeItem.priceItems[i].startQuantity) === 0) {
                        props.storeItem.priceItems[i].startQuantity = 1;
                    }

                    if (items >= parseInt(props.storeItem.priceItems[i].startQuantity) && items <= parseInt(props.storeItem.priceItems[i].endQuantity)) {

                        let itemsInRange = Math.abs((items - props.storeItem.priceItems[i].startQuantity + 1));
                        if (itemsInRange > items) {
                            itemsInRange = items;
                        }
                        let rangeAmount = Math.abs(itemsInRange * props.storeItem.priceItems[i].amount);
                        total += rangeAmount;
                    }
                    else if (items > parseInt(props.storeItem.priceItems[i].endQuantity)) {
                        let itemsInRange = Math.abs((parseInt(props.storeItem.priceItems[i].endQuantity) - parseInt(props.storeItem.priceItems[i].startQuantity)));
                        itemsInRange += 1;
                        let rangeAmount = Math.abs(itemsInRange * props.storeItem.priceItems[i].amount);
                        total += rangeAmount;
                    }
                }
            }
        }

        if (discount !== null && discount.toString().trim() != '' && !isNaN(discount) && parseFloat(discount) != 0) {
            let isInDateRange = false;
            if (discountPercentStartDate !== null && discountPercentEndDate !== null) {
                // check if we're in range
                let currentDate = new dayjs();
                if (currentDate.isAfter(discountPercentStartDate) && currentDate.isBefore(discountPercentEndDate)) {
                    isInDateRange = true;
                }
            }
            else {
                isInDateRange = true;
            }
            if (isInDateRange) {
                if (!isNaN(discount)) {
                    if (parseFloat(discount) > 0 && parseFloat(discount) < 100) {
                        let discountPercent = parseFloat(discount);
                        total += (total * discountPercent / 100);
                    }
                }
                
            }
        }


        // set price;
        setPrice(formatCurrency(total));
    }

    
    function handlePriorityChanged(e, newPriority) {
        let temp = newPriority;
        if (e.target.checked) {
            setPriority(newPriority);
        }
        else {
            setPriority('Standard');
            temp = 'Standard';
        }

        let customerQuoteItem = getCustomerQuoteItem();
        if (customerQuoteItem != null) {
            if (isAddedToQuote()) {
                // update the quantity
                props.onUpdateQuote(customerQuoteItem.id, props.storeItem, quantity, price, temp);
            }
        }
    }

    function isAddedToQuote() {
        if (user.viewingQuote === undefined) {
            return false;
        }
       
        if (user.viewingQuote.items === undefined) {
            return null;
        }

        for (let i = 0; i < user.viewingQuote.items.length; i++) {
            if (user.viewingQuote.items[i].storeItemId === props.storeItem.id) {
                return true;
            }
        }

        return false;
        
    }

    function getInitialPriority() {
        let customerQuoteItem = getCustomerQuoteItem();
        if (customerQuoteItem == null) {
            return 'Standard';
        }
        return customerQuoteItem.priority;
    }

    function getInitialQuantity() {
        let customerQuoteItem = getCustomerQuoteItem();
        if (customerQuoteItem == null) {
            return 0;
        }
        return customerQuoteItem.quantity;
    }

    function getCustomerQuoteItem() {
        if (user.viewingQuote === undefined) {
            return null;
        }
       
        if (user.viewingQuote.items === undefined) {
            return null;
        }

        for (let i = 0; i < user.viewingQuote.items.length; i++) {
            if (user.viewingQuote.items[i].storeItemId === props.storeItem.id) {
                return user.viewingQuote.items[i];
            }
        }

        return null;
    }

    function handleCheckQuantityChanged() {
        let customerQuoteItem = getCustomerQuoteItem();
        if (customerQuoteItem != null) {
            let initialQuantity = customerQuoteItem.quantity;
            if (quantity.toString().trim() != '' && quantity !== '0') {
                if (parseInt(quantity) !== initialQuantity) {
                    // quantity changed
                    if (isAddedToQuote()) {
                        // update the quantity
                        props.onUpdateQuote(customerQuoteItem.id, props.storeItem, quantity, price, priority);
                    }
                }
            } 
        }
        
    }

    function handleAddToQuoteClicked() {
        if (isAddedToQuote()) {
            // remove it
            props.onRemoveFromQuote(props.storeItem);
            setFormErrors({});
        }
        else {
            // verify if quantity has been entered
            let formErrors = {};
            let isValid = true;
            if (quantity.toString().trim() === '' || quantity === 0) {
                formErrors['quantity'] = 'Required';
                isValid = false;
            }

            setFormErrors(formErrors);

            if (isValid) {
                props.onAddToQuote(props.storeItem, quantity, price, priority);
            }
        }
    }

    return (<TableRow componentname='StoreItem' className={`Text-Dark Font-Md ${styles.StoreItemRow}`}  >
        <TableCell className={styles.StoreItemCell} size='small'>
            <img src={props.storeItem.isFavorite ? '/assets/images/favorite_mouseover.png' : '/assets/images/Favorite_None.png'} style={{ height: '42px', width: '42px', cursor: 'pointer' }} onClick={() => props.toggleFavorite(props.storeItem)} />
        </TableCell>
        <TableCell className={styles.StoreItemCell} size='small'>{props.storeItem.standard}</TableCell>
        <TableCell className={styles.StoreItemCell} size='small'>{props.storeItem.categoryName}</TableCell>
        <TableCell className={styles.StoreItemCell} size='small'>{props.storeItem.method}</TableCell>
        <TableCell className={styles.StoreItemCell} size='small'>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ backgroundColor: 'var(--intertek-yellow-1)', display: 'flex', borderRadius: '50%', width:'30px', height:'30px', justifyContent:'center' }}>
                    <IconButton style={{ paddingTop:'4px' }} src={"/assets/images/view-details-dark.svg"} mouseOver={"/assets/images/view-details-light.svg"}
                        height={22} width={20}
                        onClick={() => props.showStoreItemDetails(props.storeItem)} />
                </div>
            </div>
        </TableCell>
        <TableCell className={styles.StoreItemCell} size='small'>{props.storeItem.revision}</TableCell>
        <TableCell className={styles.StoreItemCell} size='small'>
            <div className={formErrors['quantity'] !== undefined ? 'pulse-red-formfield' : ''}>
                <TextField sx={{ width: '75px' }} onBlur={handleCheckQuantityChanged} onChange={handleQuantityChange} autoComplete='off'
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} value={quantity == 0 ? '' : quantity} error={formErrors['quantity'] !== undefined} />
            </div>
        </TableCell>
        <TableCell className={styles.StoreItemCell} size='small'>
            <FormGroup>
            {props.storeItem.productIdRushPriority !== null && 
                    <FormControlLabel control={<Checkbox checked={priority == 'Rush'} onChange={(e) => handlePriorityChanged(e, 'Rush')} />} label="Rush" />
            }
                {props.storeItem.productIdExpeditePriority !== null &&
                    <FormControlLabel control={<Checkbox checked={priority == 'Expedite'} onChange={(e) => handlePriorityChanged(e, 'Expedite')} />} label="Expedite" />
                }
            </FormGroup>
        </TableCell>
        <TableCell className={[styles.StoreItemCell, styles.StoreItemCellPrice].join(' ')} size='small' >{price == 0 ? 'Price' : price} </TableCell>
        <TableCell className={styles.StoreItemCell} size='small'>
            {isAddedToQuote() ?
                <BlueButton>
                    <div style={{ whiteSpace: 'nowrap', fontSize: '12pt' }} onClick={() => handleAddToQuoteClicked()}>Added to Quote</div>
                </BlueButton>    
                :
                <OutlineButton>
                    <div style={{ whiteSpace: 'nowrap', fontSize: '12pt' }} onClick={() => handleAddToQuoteClicked()}>Add to Quote</div>
                </OutlineButton>
            }
           
        </TableCell>
    </TableRow>)
}