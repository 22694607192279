import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import styles from './Buttons.module.css';

// Styled version of Material UI's Button
const StyledColorButton = styled(Button)(({ theme }) => ({
    color: 'var(--intertek-yellow-1)',
    border: '2px solid var(--intertek-yellow-1)',
    backgroundColor: 'rgba(0,0,0,0)',
    '&:hover': {
        backgroundColor: 'var(--intertek-yellow-1)',
        boxShadow: 'none',
        color: 'var(--intertek-blue-7)',
    },
    padding: '13px 25px',
    margin: '2px',
    fontSize: '18px',
    letterSpacing: '-0.03em',
    borderRadius: '100px',
    minHeight: '50px',
    maxHeight: '60px',
    fontFamily: 'Neo Sans',
    minWidth: '140px',
    textTransform: 'none',
    boxShadow: 'none',
}));

// No need for React.forwardRef if you don't need to expose the ref to parent components
const OutlineYellowButton = (props) => {
    const { children, onClick, animationClass, ...otherProps } = props;
    const buttonRef = useRef(null); // Create a local ref for the button

    useEffect(() => {
        if (animationClass && buttonRef.current) {
            // Add the animation class immediately
            buttonRef.current.classList.add(animationClass);

            // Set up the timeout to remove the class after 1 second
            const timeoutId = window.setTimeout(() => {
                if (buttonRef.current) {
                    buttonRef.current.classList.remove(animationClass);
                }
            }, 1000); // 1 second (1000ms) matches your animation duration

            // Cleanup the timeout if the component unmounts before the timeout finishes
            return () => window.clearTimeout(timeoutId);
        }
    }, [animationClass]);

    function handleClick(e) {
        if (onClick) {
            onClick(e);
        }
    }

    return (
        <StyledColorButton
            ref={buttonRef} // Directly apply the ref here
            onClick={handleClick}
            className={`${styles.ButtonBase} ${styles.ButtonYellow}`} // Basic classes
            variant="contained"
            {...otherProps} // Pass down other props
        >
            {children}
        </StyledColorButton>
    );
};

export default OutlineYellowButton;
