import * as React from 'react';
import styles from './LoggedInHeader.module.css'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '../IconButton/IconButton.jsx'
import Avatar from '@mui/material/Avatar';
import Drawer from '@mui/material/Drawer';
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom';
import QuoteQuickLinks from '../Portal/QuoteQuickLinks/QuoteQuickLinks';
import SlideInModal from '../SlideInModal/SlideInModal';
import NotificationLinks from '../NotificationLinks/NotificationLinks.jsx';
import QuoteDetailsPanel from '../Portal/QuoteDetails/QuoteDetailsPanel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import CustomerService from '../../services/CustomerService.jsx';
import { Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import MySalesRepMenu from './MySalesRepMenu.jsx';


export default function LoggedInHeader() {
    const { user, setUser, onUserContextChanged, userContextChanged } = React.useContext(UserContext);
    let navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [showQuickLinksMenu, setShowQuickLinksMenu] = React.useState(false);
    const [showNotificationLinksMenu, setShowNotificationLinksMenu] = React.useState(false);

    const [showQuoteDetail, setShowQuoteDetail] = React.useState(false);
    const [notifications, setNotifications] = React.useState([]);
    const [openQuotes, setOpenQuotes] = React.useState([]);

    const searchByQuoteNumberRef = React.useRef(null);

    const [usermenuAnchorEl, setUsermenuAnchorEl] = React.useState(null);
    const userMenuOpen = Boolean(usermenuAnchorEl);
    const handleUserMenuClick = (event) => {
        setUsermenuAnchorEl(event.currentTarget);
    };
    const handleUserMenuClose = () => {
        setUsermenuAnchorEl(null);
    };

    function handleLogout() {
        // expire the AuthCookie
        CustomerService.send('GET', '', 'Customer/Logout')
            .then((response) => {
                sessionStorage.clear();
                localStorage.clear();
                setUser(null);
                onUserContextChanged();
                setUsermenuAnchorEl(null);
                instance.logoutRedirect({
                    postLogoutRedirectUri: import.meta.env.VITE_ENTRA_REDIRECTURL,
                  });
               // navigate('/');

            })
            .catch((response) => {

                sessionStorage.clear();
                localStorage.clear();
                setUser(null);
                onUserContextChanged();
                setUsermenuAnchorEl(null);
                //navigate('/');
                instance.logoutRedirect({
                    postLogoutRedirectUri: import.meta.env.VITE_ENTRA_REDIRECTURL,
                  });
            });
        
    }
  
    React.useEffect(() => {
        // handles routing directly to this page without going to /Portal
        if (user !== null && user !== null) {
            if (user.customerQuotes !== undefined) {
                // find the active quotes and quotes requiring notification
               
                let notificationsArr = [];
                let openQuotesArr = [];
                for (let i = 0; i < user.customerQuotes.length; i++) {
                    if (user.customerQuotes[i] !== undefined) {
                        // determine what is considered active
                        // determine what is considired a notification

                        if (user.customerQuotes[i].status === 'ReadyForAcceptance' && user.customerQuotes[i].abandonedDateTimeUTC === null) {
                            notificationsArr.push(user.customerQuotes[i]);
                        }

                        if (user.customerQuotes[i].status == 'Paid' || (user.customerQuotes[i].status == 'QuotationCompleted' && user.customerQuotes[i].sampleSubmission == null))
                        {
                            notificationsArr.push(user.customerQuotes[i]);
                        }

                        if (user.customerQuotes[i].status === 'Accepted' && user.customerQuotes[i].abandonedDateTimeUTC === null) {
                            notificationsArr.push(user.customerQuotes[i]);
                        }


                        if (user.customerQuotes[i].status !== 'Cancelled' && user.customerQuotes[i].abandonedDateTimeUTC === null && user.customerQuotes[i].status !== 'QuotationCompleted' && user.customerQuotes[i].abandonedDateTimeUTC === null) {
                            openQuotesArr.push(user.customerQuotes[i]);
                        }
                    }
                }
                setNotifications(notificationsArr);
                setOpenQuotes(openQuotesArr);
            }

        }
    }, [userContextChanged])

    function toggleQuickLinksMenu() {
        setShowQuickLinksMenu(!showQuickLinksMenu);
    }

    function toggleNotificationLinksMenu() {
        setShowNotificationLinksMenu(!showNotificationLinksMenu);
    }

    function handleShowQuoteDetail() {
        setShowQuoteDetail(true);
    }

    function handleCloseQuoteDetailsPanel() {
        setShowQuoteDetail(false);
    }

    function handleSearchByQuoteNumberChange(e) {
        if (e == 'search' || e.key === 'Enter') {
            let searchValue = searchByQuoteNumberRef.current.value;
            searchByQuoteNumberRef.current.value = '';
            if (searchValue.trim() != '') {
                navigate('/Portal/Quotes/?search=' + searchValue);
            }
        }
    }

    function handleQuickLinksClosed() {
        setShowQuickLinksMenu(false);
        setShowQuoteDetail(true);
    }


    return (<div className={styles.AppHeader + ' background-grey'}>

        <div className={styles.Container}>
            <Link to="/" className="button-link">
                {/* <img className={styles.Logo} src={"/assets/images/intertek_logo_ybb.svg"} style={{ height: 36, width: 104, alignSelf: 'center' }} /> */}
                <img className={styles.Logo} src={"/assets/images/logo/FastLaneLogo.svg"} style={{ height: 36, width: 'auto', alignSelf: 'center' }} />
            </Link>
            <div className={styles.SearchBar }>
                <TextField style={{ width: '440px' }} className='Round'
                    placeholder='Search by Quote Number'
                    inputRef={searchByQuoteNumberRef}
                    onKeyDown={(e) => handleSearchByQuoteNumberChange(e)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" style={{cursor:'pointer'}} onClick={() => handleSearchByQuoteNumberChange('search')}>
                                <img src={"/assets/images/search.svg"} width={19} height={19} />
                            </InputAdornment>
                        )
                    }}/>
            </div>
            <div className={styles.HeaderLinks}>
                <IconButton style={{ alignSelf: 'center' }} src={"/assets/images/bell.svg"} mouseOver={"/assets/images/bell_mouseover.svg"} 
                notification={notifications.length} height={28} width={25} 
                onClick={() => toggleNotificationLinksMenu() }/>
                 {showNotificationLinksMenu &&
                    <div className={notifications.length == 0 ? styles.NotificationsQuickLinksMenuNoItems : styles.NotificationsQuickLinksMenu} style={{top:'10px'}}>
                        <SlideInModal onClose={() => setShowNotificationLinksMenu(false)} >
                            <NotificationLinks showQuoteDetail={handleShowQuoteDetail} onClose={() => setShowNotificationLinksMenu(false)} quotes={notifications} />
                        </SlideInModal>
                    </div>
                }
                
                <IconButton style={{ alignSelf: 'center', marginLeft:'5px' }} src={"/assets/images/shopping-light.svg"}
                    notification={openQuotes.length}
                    height={20}
                    width={20}
                    onClick={() => toggleQuickLinksMenu() }
                    />
                {showQuickLinksMenu &&
                    <div className={styles.QuoteQuickLinksMenu}>
                        <SlideInModal onClose={() => setShowQuickLinksMenu(false)} >
                            <QuoteQuickLinks onQuoteSelected={handleQuickLinksClosed} />
                        </SlideInModal>
                    </div>
                }
                
                <Avatar style={{ alignSelf: 'center', height:'46px',width:'46px', cursor:'pointer', marginLeft:'5px' }} onClick={handleUserMenuClick} />
                <MySalesRepMenu />  
                    
                <Menu
                    anchorEl={usermenuAnchorEl}
                    open={userMenuOpen}
                    onClose={handleUserMenuClose}
                >
                    <MenuItem onClick={handleLogout}>
                        <div style={{display:'flex'}}>
                            <LogoutIcon /> <span style={{marginLeft:'10px', alignSelf:'center'}} className={'Font-Neo'}>Logout</span>   
                        </div>
                    </MenuItem>
                </Menu>
              
            </div>

            <div className={styles.ToggleMenu}>
                <IconButton style={{ alignSelf: 'center' }} src={"/assets/images/burger_menu.svg"} onClick={() => setMenuOpen(true) }
                    mouseOver={"/assets/images/burger_menu_mouseover.svg"} height={30} width={30} />

            </div>
        </div>
        <Drawer
            anchor={'top'}
            open={menuOpen}
        >
            <div className={styles.Drawer}>
                <div className={styles.DrawerHeader}>
                    <img className={styles.Logo} src={"/assets/images/intertek_logo_ywy.svg"} style={{height:36, width:104, alignSelf:'center' }} />
                    <img style={{ alignSelf: 'center', cursor:'pointer' }} src={"/assets/images/burger_menu_light.svg"} onClick={() => setMenuOpen(false)}
                         height={30} width={30} />
                </div>
                <div className={styles.MenuItem }>
                    <IconButton style={{ alignSelf: 'center' }} src={"/assets/images/bell_light.svg"} mouseOver={"/assets/images/bell_mouseover.svg"} notification={notifications.length} height={28} width={25} />
                    <div style={{ marginLeft: '15px' }}>Notifications</div>
                </div>
                <div className={styles.MenuItem}>
                <IconButton style={{ alignSelf: 'center' }} src={"/assets/images/shopping-light.svg"} mouseOver={"/assets/images/shopping-light.svg"} 
                    notification={openQuotes.length}
                    height={30}
                    width={30}
                   
                    />
                    <div style={{marginLeft:'15px'} }>My Cart</div>
                </div>
                <div className={styles.MenuItem}>
                    Log out
                </div>
            </div>
        </Drawer>
        {showQuoteDetail &&
            <SlideInModal onClose={() => handleCloseQuoteDetailsPanel()}>
                <QuoteDetailsPanel  storeItems={[]} />
            </SlideInModal>    
        }
        
    </div>)
}