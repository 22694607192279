import * as React from 'react';
import './App.css'
import { Route, Routes } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Landing from './components/Landing/Landing';
import Home from './components/Portal/Home';
import { UserProvider } from './components/UserContext';
import CustomerService from './services/CustomerService';
import StoreView from './components/Portal/StoreView/StoreView';
import QuotesView from './components/Portal/QuotesView/QuotesView';
import { SnackbarProvider, useSnackbar } from 'notistack'
import { useMsal } from "@azure/msal-react";
import ConfirmDialog from './components/Common/ConfirmDialog';
import eventEmitter from './components/Common/eventEmitter';
import { LocationOff } from '@mui/icons-material';
import UnderMaintenance from './components/Landing/UnderMaintenance';
import useWebSocket from './components/useWebSocket.jsx';
// overides to the MUI theme
const theme = createTheme({
    typography: {
        fontFamily: [
            'Source Sans Pro',
            'sans-serif', // Fallback to a generic sans-serif if the main font is not available
        ].join(','),
    },
    
});

function App() {
    const { instance, accounts, inProgress } = useMsal();
    const { enqueueSnackbar } = useSnackbar();
    const request = {
        scopes: ["User.Read"]
    }
    
    let location = useLocation();
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const [loadingMessage, setLoadingMessage] = React.useState('');
    const [loggedInUser, setLoggedInUser] = React.useState(null);
    const [locationUnderMaintenance, setLocationUnderMaintenance] = React.useState(false);

    const hasLoginToken = React.useRef(null);
    const hasAccounts = React.useRef(null);
    const [confirmDialog, setConfirmDialog] = React.useState(null);
    const [customerLoginToken, setCustomerLoginToken] = React.useState(null);


    React.useEffect(() => {
        checkTokenAndLogin();
    }, []);

    function checkTokenAndLogin() {
        let authToken = localStorage.getItem("CustomerToken");
        if (authToken) {
            if (isTokenExpired(authToken)) {
                sessionStorage.clear();
                localStorage.clear();
            }
        }
        CustomerService.send('GET', '', 'Customer/Me')
        .then((response) => {
            
            if (response !== null) {
                if (response.result === "SUCCESS") {
                    if (response.customer !== null) {
                        let user = response.customer;
                        setLoggedInUser(user);
                        if (location.pathname !== '/') {
                            navigate(location.pathname); // navigate back to the path they came from
                        }
                    }
                }
                else if (response.message !== undefined && response.message == 'Your Account is not yet verified. Click the link we emailed you.') {
                      // cancelling allowed by customer
                    setConfirmDialog({
                        show: true,
                        close: handleCloseAccountNotVerified,
                        cancelText: 'Close',
                        title: 'Account Verification Required',
                        content: <div>
                            <div style={{ fontWeight: 'bold', marginBottom: '25px' }}>Your Account is not yet verified. Click the link we emailed you.</div>
                        </div>,
                        data: null
                    })
                }
                else if (response.message = 'Under Maintenance')
                    {
                        setLocationUnderMaintenance(true);
                    }
            }

        })
        .catch((response) => {
           
           hasLoginToken.current = false;
           if (hasAccounts.current != null && hasAccounts.current == true) {
            checkAccount();
           }
        });
    }

    function handleCloseAccountNotVerified() {
        logoutRequested();
    }

    function logoutRequested() {
        setConfirmDialog(null);
        sessionStorage.clear();
        localStorage.clear();
        setLoggedInUser(null);
        instance.logoutRedirect({
            postLogoutRedirectUri: import.meta.env.VITE_ENTRA_REDIRECTURL,
        });
    }

    // Function to check if the token is expired
    function isTokenExpired(token) {
        try {
            const payloadBase64 = token.split('.')[1]; // JWT payload is the second part
            const decodedPayload = JSON.parse(atob(payloadBase64)); // Decode from Base64

            // exp is in seconds, so we compare with current time in seconds
            const currentTime = Math.floor(Date.now() / 1000);
            return decodedPayload.exp < currentTime;
        } catch (error) {
            console.error("Error checking token expiration:", error);
            return true; // If there's an error, assume token is expired
        }
    }

    React.useEffect(() => {
        hasAccounts.current = true;
        // no AuthCookie found, but call to /Me has returned
        if (hasLoginToken.current != null && hasLoginToken.current == false) {
            if (location.pathname.toLowerCase() != '/logout') {
                checkAccount();
            }
        }
    }, [accounts]);

    const checkAccount = async () => {
        if (accounts.length > 0) {
            let users = [];
            for (let i = 0; i < accounts.length; i++)
            {
                users.push({
                    name: accounts[i].name,
                    username: accounts[i].username,
                    idToken: accounts[i].idToken // Add the ID token here
                })
            }

            try {
                setLoadingMessage('Authenticating')
                const response = await CustomerService.send('POST', users, 'Customer/validate');
                setIsLoading(false);
                
                if (response !== null) {
                    if (response.success) {
                        // set local storage
                        localStorage.setItem("CustomerToken", response.token);
                        setCustomerLoginToken(response.token);
                        if (response.customer !== null) {
                            let user = response.customer;
                            setLoggedInUser(user);

                            const params = new URLSearchParams(location.search);
                            if (params.get('login') === 'Y') {
                              console.log('Login parameter is Y');
                               navigate('/Portal'); // navigate back to the path they came from
                            }
                            else if (location.pathname !== '/') {
                                navigate(location.pathname); // navigate back to the path they came from
                            }
                        }
                    }
                    else {
                        if (response.reason !== undefined && response.reason == 'expired')
                        {
                            await instance.loginRedirect(request);
                        }
                        else if (response.reason !== undefined && response.reason == 'Unknown Customer')
                        {
                            // pass email and Name details
                            let data = {
                                email: response.email
                            }
                            eventEmitter.emit('openAccountRequestDialog', data); // listener in Landing.jsx
                        }
                        else if (response.message = 'Under Maintenance')
                        {
                            setLocationUnderMaintenance(true);
                        }
                        
                    }
                }
            } catch (response) {
                setIsLoading(false);
                if (response !== null) {
                    enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred', { variant: 'error' });
                }
            }
        } else {
          //  await instance.loginRedirect(request);
        }
    }

    useWebSocket(localStorage.getItem('CustomerToken'), 'Customer');

  return (
      <>
          <UserProvider value={{ user: loggedInUser, setUser: setLoggedInUser }}>
            <SnackbarProvider>
              <ThemeProvider theme={theme}>
                  <CssBaseline />
                  {locationUnderMaintenance ?
                    <div>
                        <UnderMaintenance />
                    </div>
                          :
                          <Routes>

                              <Route path="/" element={<Landing logoutRequested={logoutRequested} />} />
                              <Route path="/Signup" element={<Landing />} />
                              <Route path="/Portal" element={<Home />} />
                              <Route path="/Portal/Store" element={<StoreView />} />
                              <Route path="/Portal/Quotes" element={<QuotesView />} />
                          </Routes>
                      }
                      {
                          confirmDialog !== null ?
                              <ConfirmDialog Close={confirmDialog.close} Title={confirmDialog.title} Content={confirmDialog.content} CancelText={confirmDialog.cancelText} AcceptText={confirmDialog.acceptText} Data={confirmDialog.data} />
                              : null
                      }
              </ThemeProvider>
              </SnackbarProvider>
          </UserProvider>
          
    </>
  )
}

export default App
