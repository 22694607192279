import MuiTextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";

const sizeMap = {
  small: "43px",
  medium: "50px"
};

export const StyledDatePicker = styled(MuiDatePicker)(({ theme, size, minWidth }) => ({
  minWidth: minWidth ? `${minWidth}px` : "auto"
}));

export const StyledTextField = styled(MuiTextField)(({ theme, size = "medium", error = undefined }) => ({
  "& .MuiInputBase-root": {
    height: sizeMap[size],
    backgroundColor:'#ffffff'
  },
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    padding: "6px 8px 6px 8px"
    
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
     display: error === undefined ? 'block' : error === true ? 'block' :  'none'
  },
}));

export const LabelWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "6px",
}));
