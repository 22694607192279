import React, { useState, useRef } from 'react';
import styles from './PaymentOptions.module.css';
import SlideInWrapper from '../../../SlideInWrapper/SlideInWrapper';
import UploadPO from './UploadPO';
import PaymentIframe from '../PaymentIframe';
import IntertekLoading from '../../../Common/IntertekLoading';
import { UserContext } from '../../../UserContext';
import CustomerService from '../../../../services/CustomerService';

export default function PaymentOptions(props) {
    const { user, onUserContextChanged, userContextChanged } = React.useContext(UserContext);
    const [isLoading, setIsLoading] = React.useState(true);
    const [selectedOption, setSelectedOption] = React.useState('');

    const newWindowRef = useRef(null);

    // Predefined values for form fields
    let fields = {
      "ctl00$cphBody$tbCompanyName": user.companyName,
      "ctl00$cphBody$tbIntertekContactLocation": user.salesRepEmailAddress,
      "ctl00$cphBody$tbIntertekAccountNumber": "n/a",
      "ctl00$cphBody$tbInvoiceNumber": user.viewingQuote.intertekQuoteNumber == null ? '' : user.viewingQuote.intertekQuoteNumber,
      "ctl00$cphBody$tbPaymentAmount": user.viewingQuote.intertekQuoteAmount == null ? '' : user.viewingQuote.intertekQuoteAmount,
      "ctl00$cphBody$ddlCreditCard": "001", // Assume "001" is Visa
      "__VIEWSTATE": "",
      "__EVENTVALIDATION": "",
    };
  
    const handleOpenPaymentWindow = async () => {
      try {
        // Initiate payment (make async call to get additional data)
        const response = await CustomerService.send('GET', '', 'Customer/InitiatePayment');
        fields["__VIEWSTATE"] = response.viewState;
        fields["__EVENTVALIDATION"] = response.eventValidation;
  
        // Create the form and open the new window
        newWindowRef.current = window.open('', '_blank');
        if (newWindowRef.current) {
          // Create the form HTML and write it into the new window
         
          const formHtml = `
          <html>
          <body>
            <form id="paymentForm" method="POST" action="${import.meta.env.VITE_PAYMENT_PORTAL}">
                ${Object.entries(fields).map(([name, value]) =>
                  `<input type="hidden" name="${name}" value="${value}">`
                ).join('')}
            </form>
            <script>
                window.onload = function() {
                    document.getElementById('paymentForm').submit();
                }
            </script>
          </body>
          </html>
        `;
  
          // Write form to new window and submit it
          newWindowRef.current.document.write(formHtml);
          newWindowRef.current.document.close(); // Close document writing to ensure the form is rendered
        }
  
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Failed to initiate payment', error);
      }
    };

    React.useEffect(() => {
        // check if the payment for this quote has been completed
        if (user.viewingQuote.status === 'Paid' && (user.viewingQuote.paymentData != null && user.viewingQuote.paymentData != '')) {
            if (newWindowRef.current) {
                newWindowRef.current.close();
                newWindowRef.current = null; // Reset the reference after closing
            }
        }
    }, [userContextChanged]);

    return (<div style={{padding:'16px 29px', backgroundColor:'#f5f5f5', marginTop:'10px', minHeight:'60vh'}}>
        {selectedOption == '' &&
            <>
                <div className='Font-Lg Text-Dark'>Payment Options</div>
                <div style={{ display: 'flex', marginTop: '12vh', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }} className={styles.paymentOptions}>
                        <div style={{ marginTop: '10px', backgroundColor: '#ffffff' }} onClick={() => setSelectedOption('PO')}>
                            <div style={{ height: '180px' }}>
                                <img src='/assets/images/uploadpo.png' style={{ height: '180px', width: '291px' }} />
                            </div>
                            <div style={{ height: '80px', backgroundColor: '#ffffff', display: 'flex', width: '100%', justifyContent: 'center' }}>
                                <div style={{ alignSelf: 'center' }} className='Font-Md Text-Dark'>Upload a Purchase Order</div>
                            </div>
                        </div>

                        <div style={{ marginTop: '10px', backgroundColor: '#ffffff', height: '180px', cursor:'pointer' }} onClick={() => setSelectedOption('Payment')}>
                            <div style={{ height: '180px' }} >
                                <img src='/assets/images/paycreditcard.png' style={{ height: '180px', width: '291px' }} />
                            </div>
                            <div style={{ height: '80px', backgroundColor: '#ffffff', display: 'flex', width: '100%', justifyContent: 'center' }}>
                                <div style={{ alignSelf: 'center' }} className='Font-Md Text-Dark'>Pay by Credit Card</div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        {selectedOption == 'PO' &&
            <SlideInWrapper seconds={1}>
                <UploadPO isLoading={props.isLoading}/>
            </SlideInWrapper>
        }
        {selectedOption == 'Payment' &&
            <SlideInWrapper seconds={1}>
                <PaymentIframe />
            </SlideInWrapper>
        }
</div>)

}