import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

function PdfDropzone(props) {
    const [errorMessage, setErrorMessage] = useState('');

    const onDrop = useCallback(acceptedFiles => {
        // Clear error if valid file is selected
        setErrorMessage('');
        props.onDrop(acceptedFiles);
    }, [props]);

    const onDropRejected = useCallback(() => {
        setErrorMessage('Only PDF files are accepted.');
    }, []);

    const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
        accept: {
            'application/pdf': ['.pdf']
        },
        maxFiles: 1,
        onDrop,
        onDropRejected
    });

    return (
        <div>
            <div {...getRootProps()} style={{
                border: isDragReject ? '2px dashed var(--error-red)' : isDragAccept ? '2px dashed green' : '2px dashed var(--intertek-blue-1)',
                padding: '20px',
                minHeight: '150px',
                justifyContent: 'center',
                display: 'flex',
                borderRadius: '5px',
                backgroundColor: isDragReject ? 'rgba(208, 0, 0, 0.25)' : isDragAccept ? 'var(--intertek-green-1)' : 'var(--intertek-blue-2)'
            }}>
                <div style={{ alignSelf: 'center', textAlign: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#ffffff', borderRadius: '100px', width: '77px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <img src='/assets/images/quoteIcon.png' style={{ height: '75px' }} alt="Icon" />
                    </div>
                    <input {...getInputProps()} />
                    <div>Drag and drop or <u>Browse</u> your files</div>
                    <div>Only PDFs are accepted</div>
                </div>
            </div>
            {(isDragReject || errorMessage != '') && (
                <div style={{ color: 'var(--error-red)', marginTop: '10px', textAlign: 'center' }}>
                    {errorMessage}
                </div>
            )}
        </div>
    );
}

export default PdfDropzone;
