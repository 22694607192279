import React from 'react';
import styles from './PaymentOptions.module.css';
import OutlineButton from '../../../Buttons/OutlineButton';
import { TextField } from '@mui/material';
import SlideInWrapper from '../../../SlideInWrapper/SlideInWrapper';
import LimitedTextField from '../../../Common/LimitedTextField';
import { IconButton as MuiIconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import PdfDropzone from '../../../PdfGenerator/PdfDropzone';
import IconButton from '../../../IconButton/IconButton';
import { UserContext } from '../../../UserContext';
import IntertekLoading from '../../../Common/IntertekLoading';

export default function UploadPO(props) {
    const { user, onUserContextChanged, onQuoteChanged } = React.useContext(UserContext);
    const [showUploadPanel, setShowUploadPanel] = React.useState(false);
    const [formErrors, setFormErrors] = React.useState([]);
    const [poNumber, setPoNumber] = React.useState('');
    const [acceptedFile, setAcceptedFile] = React.useState(null);

    function handleValidate() {
        let isValid = true;
        let errors = [];
        if (poNumber.trim() == '') {
            isValid = false;
            errors['poNumber'] = 'Required';
        }

        if (isValid) {
            setShowUploadPanel(true);
        }

        setFormErrors(errors);

    }

    function handleFileDrop(files) {
        if (files.length > 0) {
            setAcceptedFile(files[0]);
            user.viewingQuote.pOFilePath = files[0];
            user.viewingQuote.pONumber = poNumber;
            onUserContextChanged();
        }

    }

    function getFileSize() {
        if (acceptedFile) {
            const sizeInKB = (acceptedFile.size / 1024).toFixed(2);
            const sizeInMB = (acceptedFile.size / (1024 * 1024)).toFixed(2);
            return sizeInMB < 1 ? `${sizeInKB} KB` : `${sizeInMB} MB`;
        }
    }

    return (
        <div componentname='UploadPO'>
            {props.isLoading ?

                <div style={{ minHeight: '50vh', display: 'flex', justifyContent: 'center', backgroundColor: 'var(--modal-body-light)' }}>
                    <div style={{ alignSelf: 'center' }}>
                        <IntertekLoading />
                    </div>
                </div>

                :
                <>
                    <div className={StyleSheet.label}>
                        Purchase Order #
                    </div>
                    <div style={{ display: 'flex' }}>
                        <LimitedTextField error={formErrors['poNumber'] !== undefined} onChange={(e) => setPoNumber(e.target.value)} value={poNumber} maxLength={50} size='small' placeholder='Please insert PO #' style={{ maxWidth: '180px', marginRight: '25px', alignSelf: 'center' }} sx={{ '& .MuiInputBase-root': { bgcolor: '#ffffff' } }} />
                        <OutlineButton style={{ height: '40px', minHeight: '40px', paddingTop: '8px', paddingBottom: '8px', alignSelf: 'center' }} onClick={() => handleValidate()}>Upload PO</OutlineButton>
                    </div>
                    <div style={{}}>
                        {showUploadPanel &&
                            <SlideInWrapper seconds={1}>
                                <div style={{ backgroundColor: 'var(--intertek-blue-2)', width: '100%', marginTop: '5px', padding: '10px 20px', minHeight: '500px' }}>
                                    <div>
                                        <MuiIconButton size='small' onClick={() => setShowUploadPanel(false)}><ClearIcon /></MuiIconButton>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#ffffff', maxWidth: '800px', padding: '10px', marginLeft: 'auto', marginRight: 'auto', borderRadius: '5px' }}>
                                        <div style={{ alignSelf: 'center', width: '70%' }}>
                                            <div className='Text-Dark' style={{ fontSize: '30pt', textAlign: 'center' }}>File Upload</div>
                                            <PdfDropzone onDrop={handleFileDrop} />
                                            <div style={{ height: '60px' }}>
                                                {acceptedFile == null ? null :
                                                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '5px' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignSelf: 'center', backgroundColor: 'var(--intertek-blue-1)', width: '50px', height: '50px', borderRadius: '50px' }}>
                                                            <img src='/assets/images/quoteIcon_light.png' style={{ height: '35px', alignSelf: 'center' }} />
                                                        </div>
                                                        <div style={{ alignSelf: 'center', marginLeft: '5px' }}>
                                                            <div>{acceptedFile.name}</div>
                                                            <div>{getFileSize()}</div>
                                                        </div>
                                                        <div style={{ marginLeft: 'auto', alignSelf: 'center' }}>
                                                            <IconButton style={{ alignSelf: 'center', marginRight: '5px' }} src={"/assets/images/delete_dark.svg"} mouseOver={"/assets/images/delete_light.svg"} height={19} width={17}
                                                                onClick={() => setAcceptedFile(null)}>
                                                                <div style={{ alignSelf: 'center', marginLeft: '6px' }} className='Text-Dark Font-Sm'>Remove</div>
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SlideInWrapper>
                        }
                    </div>
                </>
            }

        </div>
    )
}