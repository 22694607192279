import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { UserContext } from '../../../UserContext';
import styles from './Timeline.module.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IntertekDatePicker from '../../../Common/IntertekDatePicker.jsx';
import LimitedTextField from '../../../Common/LimitedTextField.jsx';

const Timeline = forwardRef((props, ref) => {
    const { user, onUserContextChanged, userContextChanged } = React.useContext(UserContext);
    const [decisionTimeframe, setDecisionTimeframe] = useState(user.viewingQuote.decisionTimeframe !== undefined ? user.viewingQuote.decisionTimeframe : '');
    const [decisionTimeframeDate, setDecisionTimeframeDate] = useState(user.viewingQuote.decisionTimeframeDate !== undefined ? dayjs(user.viewingQuote.decisionTimeframeDate) : null);

    const [sampleSubmissionTimeframe, setSampleSubmissionTimeframe] = useState(user.viewingQuote.sampleSubmissionTimeframe !== undefined ? user.viewingQuote.sampleSubmissionTimeframe : '');
    const [sampleSubmissionTimeframeDate, setSampleSubmissionTimeframeDate] = useState(user.viewingQuote.sampleSubmissionTimeframeDate !== undefined ? dayjs(user.viewingQuote.sampleSubmissionTimeframeDate) : null);

    const [testCompletionTimeframe, setTestCompletionTimeframe] = useState(user.viewingQuote.testCompletionTimeframe !== undefined ? user.viewingQuote.testCompletionTimeframe : '');
    const [testCompletionTimeframeDate, setTestCompletionTimeframeDate] = useState(user.viewingQuote.testCompletionTimeframeDate !== undefined ? dayjs(user.viewingQuote.testCompletionTimeframeDate) : null);

    const [additionalInformation, setAdditionalInformation] = useState(user.viewingQuote.additionalInformation ? user.viewingQuote.additionalInformation : '');

    const [errors, setErrors] = useState({});

    

    function handleDecisionTimeframeDateChanged(selectedDate) {
        setDecisionTimeframeDate(selectedDate);
        setDecisionTimeframe(''); // remove the options value

        user.viewingQuote.decisionTimeframeDate = selectedDate;
        user.viewingQuote.decisionTimeframe = '';
    }

    function handleDecisionTimeframeOptionChanged(e) {
        setDecisionTimeframeDate(null); // remove the date value
        setDecisionTimeframe(e.target.value);

        user.viewingQuote.decisionTimeframeDate = null;
        user.viewingQuote.decisionTimeframe = e.target.value;

    }

    function handleSampleSubmissionTimeframeDateChanged(selectedDate) {
        setSampleSubmissionTimeframeDate(selectedDate);
        setSampleSubmissionTimeframe(''); // remove the options value

        user.viewingQuote.sampleSubmissionTimeframeDate = selectedDate;
        user.viewingQuote.sampleSubmissionTimeframe = '';
    }

    function handleSampleSubmissionTimeframeOptionChanged(e) {
        setSampleSubmissionTimeframeDate(null); // remove the date value
        setSampleSubmissionTimeframe(e.target.value);

        user.viewingQuote.sampleSubmissionTimeframeDate = null;
        user.viewingQuote.sampleSubmissionTimeframe = e.target.value;
    }

    function handleTestCompletionTimeframeDateChanged(selectedDate) {
        setTestCompletionTimeframeDate(selectedDate);
        setTestCompletionTimeframe(''); // remove the options value

        user.viewingQuote.testCompletionTimeframeDate = selectedDate;
        user.viewingQuote.testCompletionTimeframe = '';
    }

    function handleTestCompletionTimeframeOptionChanged(e) {
        setTestCompletionTimeframeDate(null); // remove the date value
        setTestCompletionTimeframe(e.target.value);

        user.viewingQuote.testCompletionTimeframeDate = null;
        user.viewingQuote.testCompletionTimeframe = e.target.value;
    }

    function handleAdditionalInformationChanged(e) {
        setAdditionalInformation(e.target.value);
        user.viewingQuote.additionalInformation = e.target.value;
    }

    useImperativeHandle(ref, () => ({
        validate() {
          const newErrors = {};
          // Add your validation logic here
          if (decisionTimeframe === '' && !decisionTimeframeDate) {
            newErrors.decisionTimeframe = 'Decision time frame is required';
          }
          if (sampleSubmissionTimeframe === '' && !sampleSubmissionTimeframeDate) {
            newErrors.sampleSubmissionTimeframe = 'Sample Submission time frame is required';
          }
          if (testCompletionTimeframe === '' && !testCompletionTimeframeDate) {
            newErrors.testCompletionTimeframe = 'Test Completion time frame is required';
          }
          setErrors(newErrors);
          return Object.keys(newErrors).length === 0;
        },
      }));

    return (
        <div componentname="Timeline">
            <div>
                <div className={styles.section}>
                    <div className={styles.header}>
                        Expected Decision Time Frame to Accept the Quote
                    </div>
                    <div  className={`${styles.instructions} ${errors.decisionTimeframe !== undefined ? styles.error : ''}`}>
                        Please select one of the options below
                    </div>
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                        <div>
                            <div className={styles.label}>Select a date</div>
                            <div style={{ display: 'flex' }}>
                                <div style={{maxWidth:'200px'}}>
                                <IntertekDatePicker
                                    disabled={user.viewingQuote.status != 'Draft'}
                                    selectedValue={decisionTimeframeDate}
                                    onChanged={handleDecisionTimeframeDateChanged}
                                    minDate={dayjs()}
                                />
                                </div>
                                <FormControl style={{ alignSelf: 'center', marginLeft: '15px' }}>
                                    <RadioGroup defaultValue="" row>
                                        <FormControlLabel
                                            value="This Week"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={decisionTimeframe === 'This Week'} onChange={(e) => handleDecisionTimeframeOptionChanged(e)} />}
                                            label="This Week"
                                        />
                                        <FormControlLabel
                                            value="Next Week"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={decisionTimeframe === 'Next Week'} onChange={(e) => handleDecisionTimeframeOptionChanged(e)} />}
                                            label="Next Week"
                                        />
                                        <FormControlLabel
                                            value="Current Month"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={decisionTimeframe === 'Current Month'} onChange={(e) => handleDecisionTimeframeOptionChanged(e)} />}
                                            label="Current Month"
                                        />
                                        <FormControlLabel
                                            value="Next Month"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={decisionTimeframe === 'Next Month'} onChange={(e) => handleDecisionTimeframeOptionChanged(e)} />}
                                            label="Next Month"
                                        />
                                         <FormControlLabel
                                            value="Next 3 Months"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={decisionTimeframe === 'Next 3 Months'} onChange={(e) => handleDecisionTimeframeOptionChanged(e)} />}
                                            label="Next 3 Months"
                                        />
                                        <FormControlLabel
                                            value="Unsure"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={decisionTimeframe === 'Unsure'} onChange={(e) => handleDecisionTimeframeOptionChanged(e)} />}
                                            label="Unsure"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.header}>
                        Expected Sample Submission Date 
                    </div>
                    <div  className={`${styles.instructions} ${errors.sampleSubmissionTimeframe !== undefined ? styles.error : ''}`}>
                        Please select one of the options below
                    </div>
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                        <div>
                            <div className={styles.label}>Select a date</div>
                            <div style={{ display: 'flex' }}>
                                <div style={{maxWidth:'200px'}}>
                                <IntertekDatePicker
                                    disabled={user.viewingQuote.status != 'Draft'}
                                    selectedValue={sampleSubmissionTimeframeDate}
                                    onChanged={handleSampleSubmissionTimeframeDateChanged}
                                    minDate={dayjs()}
                                />
                                </div>
                                <FormControl style={{ alignSelf: 'center', marginLeft: '15px' }}>
                                    <RadioGroup defaultValue="" row>
                                        <FormControlLabel
                                            value="This Week"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={sampleSubmissionTimeframe === 'This Week'} onChange={(e) => handleSampleSubmissionTimeframeOptionChanged(e)} />}
                                            label="This Week"
                                        />
                                        <FormControlLabel
                                            value="Next Week"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={sampleSubmissionTimeframe === 'Next Week'} onChange={(e) => handleSampleSubmissionTimeframeOptionChanged(e)} />}
                                            label="Next Week"
                                        />
                                        <FormControlLabel
                                            value="Current Month"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={sampleSubmissionTimeframe === 'Current Month'} onChange={(e) => handleSampleSubmissionTimeframeOptionChanged(e)} />}
                                            label="Current Month"
                                        />
                                        <FormControlLabel
                                            value="Next Month"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={sampleSubmissionTimeframe === 'Next Month'} onChange={(e) => handleSampleSubmissionTimeframeOptionChanged(e)} />}
                                            label="Next Month"
                                        />
                                          <FormControlLabel
                                            value="Next 3 Months"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={sampleSubmissionTimeframe === 'Next 3 Months'} onChange={(e) => handleSampleSubmissionTimeframeOptionChanged(e)} />}
                                            label="Next 3 Months"
                                        />
                                        <FormControlLabel
                                            value="Unsure"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={sampleSubmissionTimeframe === 'Unsure'} onChange={(e) => handleSampleSubmissionTimeframeOptionChanged(e)} />}
                                            label="Unsure"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.section}>
                    <div className={styles.header}>
                        Expected Test Completion Date 
                    </div>
                    <div className={`${styles.instructions} ${errors.testCompletionTimeframe !== undefined ? styles.error : ''}`}>
                        Please select one of the options below
                    </div>
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                        <div>
                            <div className={styles.label}>Select a date</div>
                            <div style={{ display: 'flex' }}>
                                <div style={{maxWidth:'200px'}}>
                                <IntertekDatePicker
                                    disabled={user.viewingQuote.status != 'Draft'}
                                    selectedValue={testCompletionTimeframeDate}
                                    onChanged={handleTestCompletionTimeframeDateChanged}
                                    minDate={dayjs()}
                                />
                                </div>
                                <FormControl style={{ alignSelf: 'center', marginLeft: '15px' }}>
                                    <RadioGroup defaultValue="" row>
                                    <FormControlLabel
                                            value="This Week"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={testCompletionTimeframe === 'This Week'} onChange={(e) => handleTestCompletionTimeframeOptionChanged(e)} />}
                                            label="This Week"
                                        />
                                        <FormControlLabel
                                            value="Next Week"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={testCompletionTimeframe === 'Next Week'} onChange={(e) => handleTestCompletionTimeframeOptionChanged(e)} />}
                                            label="Next Week"
                                        />
                                        <FormControlLabel
                                            value="Current Month"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={testCompletionTimeframe === 'Current Month'} onChange={(e) => handleTestCompletionTimeframeOptionChanged(e)} />}
                                            label="Current Month"
                                        />
                                        <FormControlLabel
                                            value="Next Month"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={testCompletionTimeframe === 'Next Month'} onChange={(e) => handleTestCompletionTimeframeOptionChanged(e)} />}
                                            label="Next Month"
                                        />
                                          <FormControlLabel
                                            value="Next 3 Months"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={testCompletionTimeframe === 'Next 3 Months'} onChange={(e) => handleTestCompletionTimeframeOptionChanged(e)} />}
                                            label="Next 3 Months"
                                        />
                                        <FormControlLabel
                                            value="Unsure"
                                            control={<Radio disabled={user.viewingQuote.status != 'Draft'} checked={testCompletionTimeframe === 'Unsure'} onChange={(e) => handleTestCompletionTimeframeOptionChanged(e)} />}
                                            label="Unsure"
                                        />

                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.section}>
                <div className={styles.header}>
                        Additional Information
                    </div>
                    <div>
                        <LimitedTextField disabled={user.viewingQuote.status != 'Draft'}  value={additionalInformation} onChange={(e) => handleAdditionalInformationChanged(e)} multiline rows={3} fullWidth  maxLength={1000} sx={{ '& .MuiInputBase-root': { bgcolor: '#ffffff' } }}  />
                    </div>
                </div>

            </div>
        </div>
    );
});

export default Timeline;
