/* eslint-disable react/no-unknown-property */
import styles from './QuoteQuickLinks.module.css';
import Button from '@mui/material/Button';
import * as React from 'react';
import { UserContext } from '../../UserContext'
import QuoteQuickLinksItem from './QuoteQuickLinkItem';
import SlideInModal from '../../SlideInModal/SlideInModal';
import QuoteDetailsPanel from '../QuoteDetails/QuoteDetailsPanel';

export default function QuoteQuickLinks(props) {
    const { user, onUserContextChanged, userContextChanged } = React.useContext(UserContext);
    const [viewAllOpenQuotes, setViewAllOpenQuotes] = React.useState(false);
    const [openQuotes, setOpenQuotes] = React.useState([]);
    const [showQuoteDetail, setShowQuoteDetail] = React.useState(false);
    /*
        This doesn't show all quotes. Only ones that still require action. And then, it will only show the first few unless View All is clicked.
    */

    React.useEffect(() => {
        // initial load
        buildOpenQuotes();
    }, [])

    React.useEffect(() => {
        // also when userContext changes
        buildOpenQuotes();
    }, [userContextChanged])

    function buildOpenQuotes() {
        if (user !== null && user !== null) {
            if (user.customerQuotes !== undefined) {
                // find the active quotes and quotes requiring notification
                
                let openQuotesArr = [];
                for (let i = 0; i < user.customerQuotes.length; i++) {
                    if (user.customerQuotes[i] !== undefined) {
                        // determine what is considered active
                        // determine what is considired a notification
                        if (user.customerQuotes[i].status !== 'Cancelled' && user.customerQuotes[i].status !== 'QuotationCompleted' && user.customerQuotes[i].abandonedDateTimeUTC === null) {
                            openQuotesArr.push(user.customerQuotes[i]);
                        }
                    }
                }
                // Sort the array by id in descending order
                openQuotesArr.sort((a, b) => b.id - a.id);
                setOpenQuotes(openQuotesArr);
            }

        }
    }

    function handleClose() {
        if (props.onClose !== undefined) {
            props.onClose();
        }
    }

    function handleShowQuoteDetail() {
        if (props.onQuoteSelected !== undefined) {
            props.onQuoteSelected();
        }
        setShowQuoteDetail(true);
    }

    function handleCloseQuoteDetailsPanel() {
        setShowQuoteDetail(false);
    }


    return <div componentname='QuoteQuickLinks' className={styles.Container}>
        <div>
            <div style={{ height: '60px', borderBottom: '1px solid white', display:'flex', paddingLeft:'15px' }}>
            
                <div style={{ backgroundColor: 'var(--intertek-blue-7)', borderRadius: '50%', height:'30px', width:'30px', display:'flex', justifyContent:'center', alignSelf:'center' }}>
                    <img src='/assets/images/shopping-light.svg' style={{ height:'20px', width:'20px', alignSelf:'center' } } />
                </div>
                <div className='Text-Dark' style={{ alignSelf: 'center', marginLeft:'15px' }}>
                    My Quote Quick Links
                </div>
               
            </div>

            <div style={{ paddingLeft: '15px', paddingRight: '15px', paddingTop: '15px', minHeight: '540px', maxHeight:'540px', overflowY:'auto' }}>
                {
                    (openQuotes === undefined || openQuotes.length == 0) ?
                        <div>
                            Your active quotes will show here.
                        </div>
                        :
                        openQuotes.slice(0, viewAllOpenQuotes ? openQuotes.length : 10)  // Slice the array to the first 10 items if viewAllOpenQuotes is false
                            .map((quote, idx) => {
                            return <div key={'quotequicklinks_' + idx}>
                                <QuoteQuickLinksItem quote={quote} onClose={() =>  handleClose() } showQuoteDetail={() => handleShowQuoteDetail()} />
                            </div>
                        })
                }
            </div>

            <div style={{ height: '60px', borderTop: '1px solid white', display: 'flex', paddingLeft: '15px' }}>
                {openQuotes.length > 10 &&
                    <Button onClick={() => setViewAllOpenQuotes(!viewAllOpenQuotes)} disabled={(openQuotes === undefined || openQuotes.length == 0)} style={{ color: (openQuotes === undefined || openQuotes.length == 0) ? 'grey' : 'var(--intertek-blue-8)', fontSize: '14px', alignSelf: 'center' }}>
                        {viewAllOpenQuotes ? 'Hide' : 'View All'}
                    </Button>
                }
            </div>
        </div>
        {showQuoteDetail &&
            <SlideInModal onClose={() => handleCloseQuoteDetailsPanel()}>
                <QuoteDetailsPanel  storeItems={[]} />
            </SlideInModal>    
        }
    </div>
}