import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { UserContext } from '../UserContext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

export default function MySalesRepMenu() {
    const { user, setUser, onUserContextChanged, userContextChanged } = React.useContext(UserContext);
    const [usermenuAnchorEl, setUsermenuAnchorEl] = React.useState(null);
    const userMenuOpen = Boolean(usermenuAnchorEl);
    const handleUserMenuClick = (event) => {
        setUsermenuAnchorEl(event.currentTarget);
    };
    const handleUserMenuClose = () => {
        setUsermenuAnchorEl(null);
    };

    // format a phone number.
    // for US numbers, omit the leadind 1 and format as (xxx) xxx-xxxx
    // for international numbers, return the number as is
    function formatPhoneNumber(phoneNumber) {
        if (phoneNumber == null || phoneNumber === '') {
            return '';
        }
        if (phoneNumber.startsWith('1')) {
            return `(${phoneNumber.substring(1, 4)}) ${phoneNumber.substring(4, 7)}-${phoneNumber.substring(7, 11)}`;
        }
        return phoneNumber
    }

    if (user === null || user === undefined) {
        return null;
    }

    return (
        <>
            <div style={{display:'flex', marginLeft:'5px'}}>
                <Avatar style={{ alignSelf: 'center', height:'46px',width:'46px', cursor:'pointer' }} onClick={handleUserMenuClick} alt="Profile Pic" src={"data:image/jpeg;base64," + user.salesRep.profileImage} />
                <div className={'Font-Sm Text-Dark'} style={{fontSize:'10pt', alignSelf:'center'}}>
                    <div>Sales Rep</div>
                    <div>{user.salesRep.firstname}</div>
                </div>
            </div>
                <Menu
                    anchorEl={usermenuAnchorEl}
                open={userMenuOpen}
                onClose={handleUserMenuClose}
            >
                <div style={{ padding: '8px' }}>
                    <div className={'Font-Md'} style={{ fontWeight: 'bold', color: 'var(--intertek-yellow-1)', paddingLeft: '5px', backgroundImage: 'linear-gradient(-76.5297deg, rgb(6, 33, 61) 29.3842%, rgb(31, 182, 215) 100%)' }}>Your Sales Rep</div>
                    <div style={{ display: 'flex', marginTop:'2px' }}>
                        <div style={{ marginRight: '5px', height: '40px', width: '40px', alignSelf: 'center', display: 'flex', justifyContent: 'center', borderRadius: '50%', backgroundColor: 'var(--intertek-yellow-1)' }}>
                            <img src='/assets/images/misc-customer-service.svg' style={{ height: '35px', alignSelf: 'center' }} className='Text-Dark' />
                        </div>

                        <div style={{ alignSelf: 'center' }}>{user.salesRep.firstname} {user.salesRep.lastname}</div>
                    </div>
                    <div style={{ display: 'flex', marginTop:'2px' }}>
                        <div style={{ marginRight: '5px', height: '40px', width: '40px', alignSelf: 'center', display: 'flex', justifyContent: 'center', borderRadius: '50%', backgroundColor: 'var(--intertek-yellow-1)' }}>
                            <img src='/assets/images/misc-email.svg' style={{ height: '35px', alignSelf: 'center' }} className='Text-Dark' />
                        </div>
                        <a style={{ alignSelf: 'center' }} href={'mailto:' + user.salesRep.emailAddress}>{user.salesRep.emailAddress}</a>
                    </div>
                    {user.salesRep.contactPhoneNumber != null && user.salesRep.contactPhoneNumber !== '' &&
                        <div style={{ display: 'flex', marginTop:'2px' }}>
                            <div style={{ marginRight: '5px', height: '40px', width: '40px', alignSelf: 'center', display: 'flex', justifyContent: 'center', borderRadius: '50%', backgroundColor: 'var(--intertek-yellow-1)' }}>
                                <CallIcon style={{ height: '35px', alignSelf: 'center' }} className='Text-Dark' />
                            </div>

                            <div style={{ alignSelf: 'center' }}>{formatPhoneNumber(user.salesRep.contactPhoneNumber)}</div>
                        </div>
                    }
                </div>
            </Menu>
        </>
    )
}