import styles from './LatestNews.module.css';
import LatestNewsItem from './LatestNewsItem';
export default function LatestNews() {

    let dummyItems = [
        // {
        //     title: 'New Intertek Testing Service',
        //     content: 'GMW18435 - Abusive Torch Flame Test of Thermal Barrier Materials',
        //     link: ''
        // },
        // {
        //     title: 'New Industry Test Method Released',
        //     content: 'Effortlessly create and manage your quotes',
        //     link: ''
        // }
    ]

    return (<div style={{paddingLeft:'10px'} }>
        {
            dummyItems.map((newsItem, idx) => {
                return <LatestNewsItem key={'newsItem_' + idx} title={newsItem.title} content={newsItem.content} link={newsItem.link } />
            }) 
        }
    </div>)
}