import React, { useState, useEffect, forwardRef } from 'react';
import './SlideInWrapper.css';

// props:
// seconds: optional float (default=2) the speed of the animation in seconds
// delay: optional float, the delay of the animation in seconds
const SlideInWrapper = forwardRef((props, ref) => {
    const { children, seconds = 1, delay = 0, inOrOut='in', direction = 'down', ...otherProps } = props;
    const [loadEffect, setLoadEffect] = useState('');
    const [shouldRender, setShouldRender] = useState(true);
    useEffect(() => {
        let transitionDelay = parseFloat(delay);

        const delayTimer = setTimeout(() => {
            setLoadEffect('slide-' + inOrOut + '-' + direction);
            if (inOrOut === 'out') {
                setTimeout(() => {
                    setShouldRender(false);
                }, seconds * 1000);
            }
        }, transitionDelay * 1000);

        // Clear timeout if the component is unmounted during the delay
        return () => clearTimeout(delayTimer);
    }, [delay]);

    // Set up the CSS class based on the state
    const animationClass = loadEffect ? 'slide-' + inOrOut + '-' + direction : '';

    if (!shouldRender) {
        return null;
    }

    return (
        <div style={{ overflow: 'hidden' }} {...otherProps}>
            <div
                ref={ref}
                className={animationClass}
                style={{
                    animation: `${'slide-' + inOrOut + '-' + direction} ${seconds}s forwards ease-out`,
                    animationDelay: `${delay}s`,
                    // Use 'loadEffect' to control the visibility
                    visibility: loadEffect ? 'visible' : 'hidden',
                }}
            >
                {children}
            </div>
        </div>
    );
});

SlideInWrapper.displayName = 'SlideInWrapper';

export default SlideInWrapper;
