import * as React from 'react';
import styles from './QuoteNameDialog.module.css';
import SlideInModal from '../../SlideInModal/SlideInModal';
import YellowButton from '../../Buttons/YellowButton';
import OutlineButton from '../../Buttons/OutlineButton';
import BlueButton from '../../Buttons/BlueButton';
import { TextField } from '@mui/material';
import { UserContext } from '../../UserContext';
import LimitedTextField from '../../Common/LimitedTextField';
import CustomerService from '../../../services/CustomerService';
import IntertekLoading from '../../Common/IntertekLoading';
import { useSnackbar } from 'notistack'

export default function QuoteNameDialog(props) {
    const [formModalSlideInOrOut, setFormModalSlideInOrOut] = React.useState('in'); // Manage slide in/out
    const [formModalSlideDirection, setFormModalSlideDirection] = React.useState('down'); // Manage slide in/out
    const [completedModalSlideInOrOut, setCompletedModalSlideInOrOut] = React.useState(''); // Manage slide in/out
    const [completedModalSlideDirection, setCompletedModalSlideDirection] = React.useState(''); // Manage slide in/out
    const [isLoading, setIsLoading] = React.useState(false);
    const { user, onUserContextChanged, userContextChanged ,onQuoteChanged} = React.useContext(UserContext);
    const [quoteName, setQuoteName] = React.useState(user.viewingQuote.displayName == null ? '' : user.viewingQuote.displayName);
    const { enqueueSnackbar } = useSnackbar();

    function handleSaveName() {
        user.viewingQuote.displayName = quoteName;

        let postData = {
            quote: user.viewingQuote,
            statusChange: ''
        }
        CustomerService.send('PUT', postData, 'CustomerQuote')
            .then((response) => {
                setIsLoading(false);
                if (response !== null) {
                    if (response.result === "SUCCESS") {
                        if (response.customerQuote !== null) {
                            user.viewingQuote = response.customerQuote;
                            // add a temporary custom property to child elements can act on it.
                            user.viewingQuote.action = 'NameUpdated';
                            onQuoteChanged(response.customerQuote);  // find the quote in the customer's list and update that as well.
                            onUserContextChanged();
                            props.onClose();
                        }
                    }
                    else {
                        if (response !== null) {
                            enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred', { variant: 'error' });
                        }
                        else {
                            enqueueSnackbar('An error occurred', { variant: 'error' });
                        }
                    }
                }

            })
            .catch((response) => {
                setIsLoading(false);
                if (response !== null) {
                    enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred', { variant: 'error' });
                }
                else {
                    enqueueSnackbar('An error occurred', { variant: 'error' });
                }
            });
    }

    return (
        <SlideInModal onClose={props.onClose} inOrOut={formModalSlideInOrOut} direction={formModalSlideDirection} canDismiss={false}>
            <div componentname='QuoteNameDialog' className={styles.Panel}>
                {props.isLoading ?

                    <div style={{ minHeight: '50vh', display: 'flex', justifyContent: 'center', backgroundColor: 'var(--modal-body-light)' }}>
                        <div style={{ alignSelf: 'center' }}>
                            <IntertekLoading />
                        </div>
                    </div>

                    :
                    <>
                        <div className='Font-Neo Font-Md'>Please enter a name for this Quote:</div>
                        <div style={{ marginTop: '10px' }}>
                            <LimitedTextField value={quoteName} onChange={(e) => setQuoteName(e.target.value)} fullWidth maxLength={100} size='small' />
                        </div>

                        <div style={{ marginTop: '10px', fontFamily: 'Neo Sans Light' }} className='Font-Neo' >
                            These typically include Standard, Test Plan, or Product Name for your company's recognition.
                        </div>

                        <div style={{ marginTop: '10px', fontFamily: 'Neo Sans Light' }} className='Font-Neo' >
                            <ul>
                                <li>
                                    This can be changed later.
                                </li>
                            </ul>

                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '25px' }}>
                            {
                                user.viewingQuote.displayName != '' &&
                                <OutlineButton onClick={() => props.onClose()}>Cancel</OutlineButton>
                            }

                            <BlueButton disabled={quoteName.trim() == ''} onClick={handleSaveName}>Save</BlueButton>
                        </div>
                    </>
                }
            </div>
        </SlideInModal>)
}

