import styles from './PortalLinkItem.module.css';

// props:
// imageSrc
// message
// linkTo
export default function PortalLinkItem(props) {
    const containerStyles = { ...props.style };

    return (<div componentname='PortalLinkItem' className={styles.Container} style={containerStyles}>
        <div style={{overflow:'hidden'} }>
            <img src={props.imageSrc} style={{ height: '180px', width: '291px' }} />
        </div>
        <div style={{ height: '81px', display:'flex', justifyContent:'center' }} className='background-grey'>
            <div style={{ alignSelf: 'center' }} className='Text-Dark'>{props.message}</div>
        </div>
    </div>)
}