import * as React from 'react';
import styles from './QuotesView.module.css';  
import { TextField,InputAdornment,Select, MenuItem  } from '@mui/material';
import IconButton from '../../IconButton/IconButton';

export default function QuoteSearchFilter(props) {

    const [searchValue, setSearchValue] = React.useState(props.defaultSearch);
    const [selectedDateRange, setSelectedDateRange] = React.useState('All');
    const [status, setStatus] = React.useState('All');
    const [viewFavorites, setViewFavorites] = React.useState(false);

    React.useEffect(() => {
        setSearchValue(props.defaultSearch);
    }, [props.defaultSearch]);
    
    function handleStatusChanged(e) {
        setStatus(e.target.value);
        props.handleFilterChanged(searchValue, selectedDateRange ,e.target.value, viewFavorites);
    }

    function handleDateRangeChanged(e) {
        setSelectedDateRange(e.target.value);
        props.handleFilterChanged(searchValue, e.target.value, status, viewFavorites);
    }

    function handleViewFavoritesChanged(value) {
        setViewFavorites(value);
        props.handleFilterChanged(searchValue, selectedDateRange ,status, value);
    }

    function handleSearchValueChanged(e) {
        setSearchValue(e.target.value)
        props.handleFilterChanged(e.target.value, selectedDateRange, status, viewFavorites);
    }

    return <div componentname='QuoteSearchFilter'>
        <div className={styles.SearchBar}>
            <TextField style={{ width: '440px', alignSelf: 'center' }} className='RoundTall'
                placeholder='Search All Quotations'
                onChange={(e) => handleSearchValueChanged(e)}
                value={searchValue}
                autoComplete='off'
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <img src={"/assets/images/search.svg"} width={19} height={19} />
                        </InputAdornment>
                    )
                }} />

            <Select
                value={selectedDateRange}
                onChange={(e) => handleDateRangeChanged(e)}
                inputProps={{ 'aria-label': 'Category' }}
                style={{backgroundColor:'#ffffff', height:'53px', width:'400px', alignSelf:'center'} }
            >
                <MenuItem value="All">
                    <em>All</em>
                </MenuItem>

                <MenuItem value="Recent">
                    <em>Most Recent (10)</em>
                </MenuItem>

                <MenuItem value="CurrentMonth">
                    <em>Current Month</em>
                </MenuItem>

                <MenuItem value="6Months">
                    <em>Last 6 Months</em>
                </MenuItem>

                <MenuItem value="12Months">
                    <em>Last 12 Months</em>
                </MenuItem>

                <MenuItem value="24Months">
                    <em>Last 24 Months</em>
                </MenuItem>

                <MenuItem value="36Months">
                    <em>Last 36 Months</em>
                </MenuItem>
               
            </Select>

            <Select
                value={status}
                onChange={(e) => handleStatusChanged(e)}
                inputProps={{ 'aria-label': 'Category' }}
                style={{backgroundColor:'#ffffff', height:'53px', width:'400px', alignSelf:'center'} }
            >
                <MenuItem value="All">
                    <em>All Statuses</em>
                </MenuItem>

                <MenuItem value="Draft">
                    <em>Draft</em>
                </MenuItem>

                <MenuItem value="Submitted">
                    <em>Submitted</em>
                </MenuItem>

                <MenuItem value="Under Review">
                    <em>Under Review</em>
                </MenuItem>

                <MenuItem value="Ready For Acceptance">
                    <em>Ready For Acceptance</em>
                </MenuItem>

                <MenuItem value="Declined">
                    <em>Declined</em>
                </MenuItem>

                <MenuItem value="Awaiting SSF">
                    <em>Awaiting Sample(s) Form</em>
                </MenuItem>

                <MenuItem value="Awaiting Payment">
                    <em>Awaiting Payment</em>
                </MenuItem>

                <MenuItem value="Quotation Completed">
                    <em>Quotation Completed</em>
                </MenuItem>
                
                <MenuItem value="CancelRequested">
                    <em>Cancellation Requested</em>
                </MenuItem>

                <MenuItem value="Cancelled">
                    <em>Cancelled</em>
                </MenuItem>
         
            </Select>

            <div className={styles.FavoritesToggle }>
                <IconButton src={viewFavorites ? '/assets/images/quoteIconBlack.png' : '/assets/images/quoteIconCerrelo.png'} mouseOver='/assets/images/quoteIconCerrelo.png' onClick={() => handleViewFavoritesChanged(false)} />
                <div className={'Text-Dark Font-Md'} style={{width:'95px', textAlign:'center'} }>{viewFavorites ? 'Favorites' : 'All Quotes'}</div>
                <IconButton src={viewFavorites ? '/assets/images/favorite_mouseover.png' : '/assets/images/favorite.png'} mouseOver='/assets/images/favorite_mouseover.png' onClick={() => handleViewFavoritesChanged(true)} />
            </div>
        </div>
    </div>
}