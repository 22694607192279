/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import * as React from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import styles from './QuoteQuickLinks.module.css';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../UserContext';
import Tooltip from '@mui/material/Tooltip';
import { formatStatus } from '../../utils';
import { useSnackbar } from 'notistack'
import ConfirmDialog from '../../Common/ConfirmDialog';
import BlueButton from '../../Buttons/BlueButton';
import YellowButton from '../../Buttons/YellowButton';
import CustomerService from '../../../services/CustomerService';

export default function QuoteQuickLinksItem(props) {
    const navigate = useNavigate();
    const { user, onUserContextChanged,onQuoteChanged } = React.useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [confirmDialog, setConfirmDialog] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleQuoteClicked = () => {
        if (props.quote.status == 'UnderReview')
        {
            setConfirmDialog({
                show: true,
                close: handleCloseUnderReviewPrompt,
                cancelText: 'Close',
                acceptText: undefined,
                title: 'Under Review',
                content: <div>
                    <div style={{ fontWeight: 'bold', marginBottom: '25px' }}>This Quote is Under Review and cannot be changed.</div>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <BlueButton onClick={handleCancelQuoteClicked}>Cancel the Quote</BlueButton>
                        <YellowButton onClick={handleQuoteToDraftClicked}>Back to Draft</YellowButton>
                    </div>
                </div>,
                data: null
            })
        }
        else {
            user.viewingQuote = props.quote;
            onUserContextChanged();
            if (props.onClose !== undefined) {
                props.onClose();
            }

            // if Draft, go to the Store so they can edit their quote
            if (user.viewingQuote.status == 'Draft') {
                navigate('/Portal/Store?open=true');
            }
            else {
                //show it here
                if (window.location.pathname.toLowerCase() == '/portal/store')
                {
                    // already viewing the store for a quote. We don't want the user adding to this quote accidentally.
                    navigate('/Portal?open=true');
                }
                else {
                    props.showQuoteDetail();
                }
                
            }
            
        }
    };

    function handleCloseUnderReviewPrompt(didAccept) {
        setConfirmDialog(null);
    }

    function handleCancelQuoteClicked() {
        setConfirmDialog(null);
        let postData = {
            quote: props.quote,
            statusChange: 'Cancelled'
        }
        CustomerService.send('PUT', postData, 'CustomerQuote')
            .then((response) => {
                setIsLoading(false);
                if (response !== null) {
                    if (response.result === "SUCCESS") {
                        if (response.customerQuote !== null) {
                           // user.viewingQuote = response.customerQuote;
                            onQuoteChanged(response.customerQuote);  // find the quote in the customer's list and update that as well.
                            onUserContextChanged();
                        }
                    }
                    else {
                        if (response !== null) {
                            enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred', { variant: 'error' });
                        }
                        else {
                            enqueueSnackbar('An error occurred', { variant: 'error' });
                        }
                    }
                }

            })
            .catch((response) => {
                setIsLoading(false);
                if (response !== null) {
                    enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred', { variant: 'error' });
                }
                else {
                    enqueueSnackbar('An error occurred', { variant: 'error' });
                }
            });
    }

    function handleQuoteToDraftClicked() {
        setConfirmDialog(null);
        setConfirmDialog(null);
        let postData = {
            quote: props.quote,
            statusChange: 'Draft'
        }
        CustomerService.send('PUT', postData, 'CustomerQuote')
            .then((response) => {
                setIsLoading(false);
                if (response !== null) {
                    if (response.result === "SUCCESS") {
                        if (response.customerQuote !== null) {
                           // user.viewingQuote = response.customerQuote;
                            onQuoteChanged(response.customerQuote);  // find the quote in the customer's list and update that as well.
                            onUserContextChanged();
                        }
                    }
                    else {
                        if (response !== null) {
                            enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred', { variant: 'error' });
                        }
                        else {
                            enqueueSnackbar('An error occurred', { variant: 'error' });
                        }
                    }
                }

            })
            .catch((response) => {
                setIsLoading(false);
                if (response !== null) {
                    enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred', { variant: 'error' });
                }
                else {
                    enqueueSnackbar('An error occurred', { variant: 'error' });
                }
            });
    }

    const buttonStyle = {
        backgroundColor: props.quote.status === 'Draft'
            ? 'none'
            : props.quote.status === 'Submitted' ? '#a3a6a9'
            : props.quote.status === 'UnderReview' ? 'var(--intertek-blue-8)'
                : 'none',
        border: '2px solid ' + (props.quote.status === 'Draft'
            ? '#a3a6a9'
            : props.quote.status === 'UnderReview'
                ? 'var(--itertek-blue-8)'
            :props.quote.status == 'ReadyForAcceptance' ? 
                'var(--intertek-green-1)'
            :props.quote.status == 'Accepted' ? 
                'var(--intertek-green-1)' 
            :props.quote.status == 'CancelRequested' ? 
                'var(--intertek-maroon-4)' 
            :props.quote.status === 'Paid' && props.quote.sampleSubmissionForm == null ? 
                'black' 
                : 'none'),
       
    }

    const arrowColor =
        props.quote.status === 'Draft' ? '#a3a6a9' :
        props.quote.status === 'Submitted' ? '#a3a6a9' :
        props.quote.status === 'UnderReview' ? 'var(--intertek-blue-8)' :
        props.quote.status ==='ReadyForAcceptance' ? 'var(--intertek-green-1)' :
        props.quote.status === 'Accepted' ? 'var(--intertek-green-1)' :
        props.quote.status === 'CancelRequested' ? 'var(--intertek-maroon-4)' :
        props.quote.status === 'Paid' && props.quote.sampleSubmissionForm == null ? 'black' 
            : 'black';
    

    return <div componentname='QuoteQuickLinksItems' className={styles.QuickLinkQuoteItem }>
        <img src='/assets/images/quoteIcon.png' style={{ height: '35px', width: '35px', alignSelf:'center' }} />
        <div className={styles.QuickLinksDisplayName} style={{ alignSelf: 'center', color: (user.viewingQuote === undefined ? 'inherit' : user.viewingQuote.id === props.quote.id ? 'var(--intertek-yellow-1)' : 'inherit') }}>
            <Tooltip title={props.quote.displayName.length >= 13 ? props.quote.displayName : ''}>
                <span>{props.quote.displayName === '' ? props.quote.quoteNumber : props.quote.displayName}</span>
            </Tooltip>
        </div>
        <div style={{display:'flex'} }>
            <ArrowLeftIcon style={{ alignSelf: 'center', position: 'relative', left: '10px' }} sx={{ color: arrowColor }} />
            <Button onClick={() => handleQuoteClicked()} style={{ alignSelf: 'center', minWidth: '155px', maxWidth:'155px', textTransform: 'none', ...buttonStyle }} sx={{ buttonStyle }}>
                <span className='Text-Dark'>{formatStatus(props.quote)}</span>
            </Button>

        </div>
        {
            confirmDialog !== null ?
                <ConfirmDialog Close={confirmDialog.close} Title={confirmDialog.title} Content={confirmDialog.content} CancelText={confirmDialog.cancelText} AcceptText={confirmDialog.acceptText} Data={confirmDialog.data} />
                : null
        }
    </div>
}