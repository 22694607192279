import CircularProgress from '@mui/material/CircularProgress';

export default function IntertekLoading(props) {

    return (
        <div style={{ position: 'relative', height: (props.height === undefined ? '50px' : props.height), width: (props.width === undefined ? '50px' : props.width) }} >
            <CircularProgress style={{ position: 'absolute', top: '0', left: '0', zIndex: 1, height: (props.height === undefined ? '50px' : props.height), width: (props.width === undefined ? '50px' : props.width) }} sx={{ color: 'black' }} />
            <img alt='Loading' style={{ position: 'absolute', top: '0', left: '0', zIndex: 1, height: (props.height === undefined ? '50px' : props.height), width: (props.width === undefined ? '50px' : props.width) }} src={'/assets/images/Roundel_Interior.png'} />
        </div>

    )
}