import React, { useState, useCallback } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { IconButton, InputAdornment, InputLabel as Label } from "@mui/material";
import {
  StyledDatePicker,
  LabelWrapper,
  StyledTextField
} from "./IntertekDatePicker.styles";

const WrappedMyTextField = (props) => {
  const {
    endAdornmentIcon,
    onEndAdormentClickHandler,
    size,
    setOpen,
    InputProps,
    ...other
  } = props;

  const endAdormentClick = () => {
    if (onEndAdormentClickHandler) {
      onEndAdormentClickHandler();
    }
  };

  return (
    <StyledTextField
      {...other}
      size={size}
      InputProps={{
        ...InputProps,
        startAdornment:  
          <InputAdornment position="start">
            <IconButton size='small' onClick={endAdormentClick} edge="start">
              <img src='/assets/images/calendar.png' style={{height:'48px'}} />
            </IconButton>
          </InputAdornment>
        ,endAdornment:null
      }}
      onClick={() => {
        setOpen(true);
      }}
    />
  );
};

const IntertekDatePicker = ({
  label,
  selectedValue,
  error,
  minWidth,
  isRequired = false,
  dataTestId,
  size = "small",
  dateFormat = "MM/DD/YYYY",
  helperText = " ",
  endAdornmentIcon,
  onEndAdormentClickHandler,
  onChanged,
  minDate,
  maxDate,
  ...rest
}) => {
  const [value, setValue] = useState(selectedValue);
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  return (
    <div>
      {label ? (
        <LabelWrapper>
          <Label required={isRequired} shrink={false} error={error === undefined ? false : true}>
            {error}
          </Label>
        </LabelWrapper>
      ) : null}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledDatePicker
          {...rest}
          minWidth={minWidth}
          open={open}
          minDate = {minDate}
          maxDate={maxDate}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          size={size}
          value={value}
          onChange={(newValue, context) => {
            setValue(newValue);
            if (onChanged) {
                onChanged(newValue);
            }
          }}
          format={dateFormat}
          slotProps={{
            textField: {
              helperText: error,
              placeholder: 'Select a Date',
              error: error === undefined ? false : true,
              onEndAdormentClickHandler,
              endAdornmentIcon,
              size,
              setOpen
            }
          }}
          slots={{
            textField: WrappedMyTextField
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default IntertekDatePicker;
