import styles from './QuoteDetailsPanel.module.css';
import QuoteDetailsHeader from './QuoteDetailsHeader';
import QuoteDetailsFooter from './QuoteDetailsFooter';
import IconButton from '../../IconButton/IconButton';
import React, { useState, useRef } from 'react';
import StatusIndicator from './StatusIndicator';
import OutlineButton from '../../Buttons/OutlineButton';
import YellowButton from '../../Buttons/YellowButton';
import DraftView from './DraftView/DraftView'
import Timeline from './Timeline/Timeline';
import Submitted from './Submitted/Submitted';
import PaymentOptions from './PaymentOptions/PaymentOptions';
import ReadyForAcceptance from './ReadyForAcceptance/ReadyForAcceptance';
import AcceptanceForm from './AcceptanceForm/AcceptanceForm';
import SampleSubmission from './SampleSubmission/SampleSubmission';
import SampleShipping from './SampleShipping/SampleShipping';
import SlideInWrapper from '../../SlideInWrapper/SlideInWrapper';
import { UserContext } from '../../UserContext';
import CustomerService from '../../../services/CustomerService';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation  } from 'react-router-dom';
import ConfirmDialog from '../../Common/ConfirmDialog';
import BlueButton from '../../Buttons/BlueButton';
import { formatStatus } from '../../utils'
import UnderReview from './UnderReview/UnderReview';


export default function QuoteDetailsPanel(props) {
    const { user, onUserContextChanged, userContextChanged, onQuoteChanged } = React.useContext(UserContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [confirmDialog, setConfirmDialog] = React.useState(null);
    const location = useLocation();
    

    const steps = [
        {stepName: 'Draft', canClick:false},
        {stepName: 'Timeline Expectations', canClick:false},
        {stepName: 'Draft Quote Submitted', canClick:false},
        {stepName: 'Under Review', canClick:false},
        {stepName: 'Ready for Acceptance', canClick:false},
        {stepName: 'Accepted', canClick:false},
        {stepName: 'Paid or PO', canClick:false},
        {stepName: 'Samples', canClick:false},
        {stepName: 'Completed', canClick:false}
    ];

    const timelineRef = useRef();
    const [currentStep, setCurrentStep] = useState(1);
    const [pendingStep, setPendingStep] = React.useState(1);

    React.useEffect(() => {
        initializeStep();
    },[]);

    React.useEffect(() => {
    
        initializeStep();
      }, [userContextChanged]);

    function initializeStep() {
        if (user !== null && user.viewingQuote !== null) {
            if (user.viewingQuote.status === 'Submitted')
            {
                setCurrentStep(3);
                setPendingStep(3);
            }
            else if (user.viewingQuote.status === 'UnderReview')
            {
                setCurrentStep(4);
                setPendingStep(4);
            }
            else if (user.viewingQuote.status === 'ReadyForAcceptance')
            {
                setCurrentStep(5);
                setPendingStep(5);
            }
            else if (user.viewingQuote.status === 'Accepted') {
                setCurrentStep(7);
                setPendingStep(7);
            }
            else if (user.viewingQuote.status === 'Paid') {
                setCurrentStep(8);
                setPendingStep(8);
            }
            else if (user.viewingQuote.status === 'QuotationCompleted') {
                setCurrentStep(9);
                setPendingStep(9);
            }
        
        }
    }

    function stepForward() {
        if (currentStep < steps.length - 2) {
            setCurrentStep(currentStep + 1);
        }
    }

    function stepBackward() {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    }

    function handleDeleteClicked() {

        // ReadyForAcceptance,
        // Accepted,
        // Paid,
        // QuotationCompleted,

        if (user.viewingQuote.status == 'Draft' || 
            user.viewingQuote.status == 'Submitted' ||
            user.viewingQuote.status == 'ReadyForAcceptance'
            
        )
        {
            // cancelling allowed by customer
            setConfirmDialog({
                show: true,
                close: handleCloseDeleteQuotePrompt,
                cancelText: 'Close',
                acceptText: 'Cancel Quote',
                title: 'Cancel Quote',
                content: <div>
                    <div style={{ fontWeight: 'bold', marginBottom: '25px' }}>Are you sure you want to Cancel this Quotes?</div>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                     
                    </div>
                </div>,
                data: null
            })
        }
       
        else if (user.viewingQuote.status == 'Accepted' || 
            user.viewingQuote.status == 'Paid' ||
            user.viewingQuote.status == 'UnderReview')
        {
            
            // cancelling must be requested
               // cancelling must be requested
               setConfirmDialog({
                show: true,
                close: handleCloseDeleteQuotePrompt,
                cancelText: 'Close',
                acceptText: 'Send Request',
                title: 'Cancellation Request',
                content: <div>
                    <div style={{ fontWeight: 'bold', marginBottom: '25px' }}>This Quote is {formatStatus(user.viewingQuote)} and cannot be changed. Would you like to send a cancellation request to your Sales Rep?</div>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                     
                    </div>
                </div>,
                data: null
            })
        }
       
        
    }

    function handleCloseDeleteQuotePrompt(didAccept) {

        if (didAccept) {
            let postData = {
                quote: user.viewingQuote,
                statusChange: 'Cancelled'
            }
            CustomerService.send('PUT', postData, 'CustomerQuote')
                .then((response) => {
                    setIsLoading(false);
                    if (response !== null) {
                        if (response.result === "SUCCESS") {
                            if (response.customerQuote !== null) {
                                user.viewingQuote = response.customerQuote;
                                onQuoteChanged(response.customerQuote);  // find the quote in the customer's list and update that as well.
                                onUserContextChanged();
                                props.onClose();
                                if (location.pathname.toLowerCase() == '/portal/store')
                                {
                                    navigate('/portal')
                                }
                            }
                        }
                        else {
                            if (response !== null) {
                                enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred', { variant: 'error' });
                            }
                            else {
                                enqueueSnackbar('An error occurred', { variant: 'error' });
                            }
                        }
                    }
    
                })
                .catch((response) => {
                    setIsLoading(false);
                    if (response !== null) {
                        enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred', { variant: 'error' });
                    }
                    else {
                        enqueueSnackbar('An error occurred', { variant: 'error' });
                    }
                });
        }

        setConfirmDialog(null);

    }

    function handleStepIndicatorClicked(step) {
        for (let i = 0; i < steps.length; i++) {
            if (steps[i].stepName === step.stepName) {
                setCurrentStep(i + 1); // 1 based
                break;
            }
        }
    }

    function handleUploadPO() {
        setIsLoading(true);
        if (typeof user.viewingQuote.pOFilePath === 'string')
        {
            // already converted to base64
            sendPaid();
        }
        else {
            const reader = new FileReader();
            reader.onload = function (event) {
                user.viewingQuote.pOFilePath = event.target.result.split(',')[1];
                sendPaid();
            };
            reader.readAsDataURL(user.viewingQuote.pOFilePath);
        }
     


    }

    function sendPaid() {
        let postData = {
            quote: user.viewingQuote,
            statusChange: 'Paid'
        }
        CustomerService.send('PUT', postData, 'CustomerQuote')
            .then((response) => {
                setIsLoading(false);
                if (response !== null) {
                    if (response.result === "SUCCESS") {
                        if (response.customerQuote !== null) {
                            user.viewingQuote = response.customerQuote;
                            onQuoteChanged(response.customerQuote);  // find the quote in the customer's list and update that as well.
                            onUserContextChanged();
                            setCurrentStep(8);
                        }
                    }
                    else {
                        if (response !== null) {
                            enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred', { variant: 'error' });
                        }
                        else {
                            enqueueSnackbar('An error occurred', { variant: 'error' });
                        }
                    }
                }

            })
            .catch((response) => {
                setIsLoading(false);
                if (response !== null) {
                    enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred', { variant: 'error' });
                }
                else {
                    enqueueSnackbar('An error occurred', { variant: 'error' });
                }
            });
    }

    function validateAndSubmitDraft() {
        let isTimelineValid = timelineRef.current.validate();
        if (isTimelineValid)
        {
            setIsLoading(true);

            let postData = {
                quote: user.viewingQuote,
                statusChange:'Submitted'
            }
            CustomerService.send('PUT', postData, 'CustomerQuote')
            .then((response) => {
                setIsLoading(false);
                if (response !== null) {
                    if (response.result === "SUCCESS") {
                        if (response.customerQuote !== null) {
                            user.viewingQuote = response.customerQuote;
                            onQuoteChanged(response.customerQuote);  // find the quote in the customer's list and update that as well.
                            onUserContextChanged();
                            setCurrentStep(3);
                        }
                    }
                    else  {
                        if (response !== null) {
                            enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred',{ variant: 'error' });
                        }
                        else {
                            enqueueSnackbar('An error occurred',{ variant: 'error' });
                        }
                    }
                }

            })
            .catch((response) => {
                setIsLoading(false);
                if (response !== null) {
                    enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred',{ variant: 'error' });
                }
                else {
                    enqueueSnackbar('An error occurred',{ variant: 'error' });
                }
            });
        }
    }

    function handleSaveAndClose(quoteName) {
        // No validation
        setIsLoading(true);
            user.viewingQuote.displayName = quoteName;
            let postData = {
                quote: user.viewingQuote,
                statusChange:'' //  no status change
            }
            CustomerService.send('PUT', postData, 'CustomerQuote')
            .then((response) => {
                setIsLoading(false);
                if (response !== null) {
                    if (response.result === "SUCCESS") {
                        if (response.customerQuote !== null) {
                            user.viewingQuote = response.customerQuote;
                            onQuoteChanged(response.customerQuote);  // find the quote in the customer's list and update that as well.
                            onUserContextChanged();
                            // Question: do we continue on the same quote?
                            props.onClose();
                        }
                    }
                    else  {
                        if (response !== null) {
                            enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred',{ variant: 'error' });
                        }
                        else {
                            enqueueSnackbar('An error occurred',{ variant: 'error' });
                        }
                    }
                }

            })
            .catch((response) => {
                setIsLoading(false);
                if (response !== null) {
                    enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred',{ variant: 'error' });
                }
                else {
                    enqueueSnackbar('An error occurred',{ variant: 'error' });
                }
            });
    }

    function handleStartNewQuote() {
        user.viewingQuote = undefined;
        props.onClose();
        navigate('/Portal/Store');
    }

    function handleCloseDialog() {
        user.viewingQuote = undefined;
        props.onClose();
    }

    function handleQuoteAccepted(signedBy, companyName, signaturePath, signedTimestamp) {
        setIsLoading(true);

        user.viewingQuote.signedBy = signedBy;
        user.viewingQuote.companyName = companyName;
        user.viewingQuote.signaturePath = signaturePath;
        user.viewingQuote.signedDateTimeUTC = signedTimestamp;

        let postData = {
            quote: user.viewingQuote,
            statusChange:'Accepted'
        }
        CustomerService.send('PUT', postData, 'CustomerQuote')
        .then((response) => {
            setIsLoading(false);
            if (response !== null) {
                if (response.result === "SUCCESS") {
                    if (response.customerQuote !== null) {
                        user.viewingQuote = response.customerQuote;
                        onQuoteChanged(response.customerQuote);  // find the quote in the customer's list and update that as well.
                        onUserContextChanged();
                        if (response.customerQuote.status == 'Paid')
                            {
                                // customers that don't require a PO:
                                setCurrentStep(8);
                            }
                            else {
                                setCurrentStep(7);
                            }
                        
                    }
                }
                else  {
                    if (response !== null) {
                        enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred',{ variant: 'error' });
                    }
                    else {
                        enqueueSnackbar('An error occurred',{ variant: 'error' });
                    }
                }
            }

        })
        .catch((response) => {
            setIsLoading(false);
            if (response !== null) {
                enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred',{ variant: 'error' });
            }
            else {
                enqueueSnackbar('An error occurred',{ variant: 'error' });
            }
        });
    }

    function handleSubmitSampleSubmissionForm() {
        user.viewingQuote.sampleSubmission.didCustomerPrint = true;

        let postData = {
            quote: user.viewingQuote,
            statusChange:'QuotationCompleted'
        }
        CustomerService.send('PUT', postData, 'CustomerQuote')
        .then((response) => {
            setIsLoading(false);
            if (response !== null) {
                if (response.result === "SUCCESS") {
                    if (response.customerQuote !== null) {
                        user.viewingQuote = response.customerQuote;
                        onQuoteChanged(response.customerQuote);  // find the quote in the customer's list and update that as well.
                        onUserContextChanged();
                        setCurrentStep(9);
                    }
                }
                else  {
                    if (response !== null) {
                        enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred',{ variant: 'error' });
                    }
                    else {
                        enqueueSnackbar('An error occurred',{ variant: 'error' });
                    }
                }
            }

        })
        .catch((response) => {
            setIsLoading(false);
            if (response !== null) {
                enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred',{ variant: 'error' });
            }
            else {
                enqueueSnackbar('An error occurred',{ variant: 'error' });
            }
        });
    }

    if (user.viewingQuote != null && user.viewingQuote != undefined)
    {
        /*
           Draft,
           Submitted,
           UnderReview,
           ReadyForAcceptance,
           Accepted,
           Paid,
           QuotationCompleted,
           Abandoned,
           CancelRequested,
           Cancelled
           */

        if (user.viewingQuote.status === 'Draft')
        {
            steps[0].canClick = true;
            steps[1].canClick = true;
        }
        else if (user.viewingQuote.status == 'Submitted')
        {
            steps[0].canClick = true;
            steps[1].canClick = true;
            steps[2].canClick = true;
        }
        else if (user.viewingQuote.status == 'UnderReview')
        {
            steps[1].canClick = true;
            steps[3].canClick = true;
        }
        else if (user.viewingQuote.status == 'ReadyForAcceptance') {
            steps[0].canClick = true;
            steps[1].canClick = true;
            steps[4].canClick = true;
        }
        else if (user.viewingQuote.status == 'Accepted') {
            steps[0].canClick = true;
            steps[1].canClick = true;
            steps[5].canClick = true;
            steps[6].canClick = true;
        }
        else if (user.viewingQuote.status == 'Paid') {
            steps[0].canClick = true;
            steps[1].canClick = true;
            steps[5].canClick = true;
            steps[7].canClick = true;
        }
        else if (user.viewingQuote.status == 'QuotationCompleted') {
            steps[0].canClick = true;
            steps[1].canClick = true;
            steps[4].canClick = true;
            steps[5].canClick = true;
            steps[7].canClick = true;
            if (user.viewingQuote.sampleSubmission != null)
            {
                steps[8].canClick = true;
            }
        }
    }

    return (<div className={styles.QuoteDetailsPanel} componentname='QuoteDetailsHeader'>
        <QuoteDetailsHeader status={user.viewingQuote.status} OnSaveAndClose={(quoteName) => handleSaveAndClose(quoteName)} />

        <div className={styles.PanelContentContainer }>
           
                <div className={styles.PanelSideColumn} style={{display:'flex', justifyContent:'flex-end', flexDirection:'column', paddingLeft:'27px'} } >
                {(user.viewingQuote.status != 'QuotationCompleted' &&
                    user.viewingQuote.status != 'Abandonded' &&
                    user.viewingQuote.status != 'CancelRequested' &&
                    user.viewingQuote.status != 'Cancelled') &&
                    <div className='TextButton'>
                        <IconButton style={{ alignSelf: 'center', marginRight: '5px' }} src={"/assets/images/delete_dark.svg"} mouseOver={"/assets/images/delete_light.svg"} height={19} width={17}
                            onClick={() => handleDeleteClicked()}>
                            <div style={{ alignSelf: 'center', marginLeft: '6px' }} className='Text-Dark Font-Sm'>Cancel Quote</div>
                        </IconButton>
                    </div>
                }
                </div>
            
            <div className={styles.PanelCenterColumn }>
                <div style={{paddingTop:'3px'} }>
                    <StatusIndicator steps={steps} currentStep={currentStep} pendingStep={pendingStep} onClicked={(step) => handleStepIndicatorClicked(step)} />
                    {(currentStep === 1) &&
                        <DraftView storeItems={props.storeItems} />
                    }
                    {(currentStep === 2) &&
                        <Timeline ref={timelineRef}/>
                    }
                    {(currentStep === 3) &&
                        <Submitted OnStartNewQuote={() => handleStartNewQuote()} onClose={handleCloseDialog}/>
                    }
                    {(currentStep === 4) &&
                        <UnderReview storeItems={props.storeItems} />
                    }
                    {(currentStep === 5) &&
                        <ReadyForAcceptance />
                    }
                    {(currentStep === 6) &&
                        <AcceptanceForm onAccepted={handleQuoteAccepted} />
                    }
                    {(currentStep === 7) &&
                        <PaymentOptions isLoading={isLoading} />
                    }
                    {(currentStep === 8) &&
                       <SampleSubmission onClose={() =>  props.onClose()} onSubmit={handleSubmitSampleSubmissionForm} />
                    }
                    {(currentStep === 9) &&
                       <SampleShipping onClose={() => props.onClose()} />
                    }
                   
                </div>
            </div>
            <div className={styles.PanelSideColumn} style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', paddingLeft: '27px' }}>
                <div style={{display:'flex'} }>
                    {(currentStep === 1) &&
                        <OutlineButton onClick={() => stepForward()}>Next</OutlineButton>
                    }
                     {(currentStep === 2 && user.viewingQuote.status == 'Draft') &&
                        <YellowButton style={{backgroundColor:isLoading?'#efefef': ''}} disabled={isLoading} onClick={() => validateAndSubmitDraft()}>Submit Draft</YellowButton>
                    }
                     {(currentStep === 5 && user.viewingQuote.status == 'ReadyForAcceptance') &&
                        <YellowButton style={{backgroundColor:isLoading?'#efefef': ''}} disabled={isLoading} onClick={() => setCurrentStep(6)}>Accept</YellowButton>
                    }
                     {(currentStep === 7 && user.viewingQuote.pOFilePath !== undefined && user.viewingQuote.pOFilePath !== null) &&
                        <OutlineButton onClick={() => handleUploadPO()} style={{backgroundColor:isLoading?'#efefef': ''}} disabled={isLoading}>Next</OutlineButton>
                    }
                    
                </div>
            </div>
        </div>

        <QuoteDetailsFooter />

        {
            confirmDialog !== null ?
                <ConfirmDialog Close={confirmDialog.close} Title={confirmDialog.title} Content={confirmDialog.content} CancelText={confirmDialog.cancelText} AcceptText={confirmDialog.acceptText} Data={confirmDialog.data} />
                : null
        }
    </div>)
}