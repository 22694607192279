import * as React from 'react';
import styles from './Submitted.module.css';
import OutlineButton from '../../../Buttons/OutlineButton';
import YellowButton from '../../../Buttons/YellowButton';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../UserContext';
import cloneDeep from 'lodash.cloneDeep';
import IntertekLoading from '../../../Common/IntertekLoading';
import { useSnackbar } from 'notistack'
import CustomerService from '../../../../services/CustomerService';

export default function Submitted(props) {
    const navigate = useNavigate();
    const { user, onUserContextChanged, userContextChanged, storeItems, setStoreItems, categories, setCategories } = React.useContext(UserContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    function startNewQuote() {
        props.OnStartNewQuote();
    }

    function handleNavigateToPortal() {
        if (props.onClose !== undefined) {
            props.onClose();
            navigate('/Portal')
        }
    }

    function editThisQuote() {
        let postData = {
            quote: user.viewingQuote,
            statusChange: 'Draft'
        }
        CustomerService.send('PUT', postData, 'CustomerQuote')
            .then((response) => {
                setIsLoading(false);
                if (response !== null) {
                    if (response.result === "SUCCESS") {
                        if (response.customerQuote !== null) {
                            user.viewingQuote = response.customerQuote;
                            onUserContextChanged();
                           
                            navigate('/Portal/Store');
                        }
                    }
                    else {
                        if (response !== null) {
                            enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred', { variant: 'error' });
                        }
                        else {
                            enqueueSnackbar('An error occurred', { variant: 'error' });
                        }
                    }
                }

            })
            .catch((response) => {
                setIsLoading(false);
                if (response !== null) {
                    enqueueSnackbar(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred', { variant: 'error' });
                }
                else {
                    enqueueSnackbar('An error occurred', { variant: 'error' });
                }
            });
        let temp = cloneDeep(user.viewingQuote);
        props.onClose();
        user.viewingQuote = temp;
       
    }    

    return (
        <div componentname='Submitted'>
            {isLoading ?
                <div style={{ minHeight: '50vh', display: 'flex', justifyContent: 'center', backgroundColor: 'var(--modal-body-light)' }}>
                    <div style={{ alignSelf: 'center' }}>
                        <IntertekLoading />
                    </div>
                </div>
                :
                <>
                    <div className='Text-Dark Font-Neo Font-Md' style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '50px', padding: '25px' }}>
                        Thank you for submitting your draft quote! Your Intertek sales representative will review and release it soon.
                    </div>

                    <div className={`Text-Dark Font-Neo Font-Md ' ${styles.container}`}


                    >
                        <div className={styles.textBox} style={{ alignSelf: 'center' }}>
                            Experience a quick and reliable 24/7 quote process with your trusted Intertek testing partner.
                        </div>
                        <div className={styles.imageBox}>

                        </div>
                    </div>

                    <div style={{ marginTop: '25px', display: 'flex', justifyContent: 'space-between' }}>
                        <OutlineButton onClick={() => handleNavigateToPortal()}>Back to My Portal</OutlineButton>
                        <YellowButton onClick={() => editThisQuote()}>Edit this quote</YellowButton>
                        <YellowButton onClick={() => startNewQuote()}>Create New Quote</YellowButton>

                    </div>
                </>
            }
        </div>
    );
}