
import * as React from 'react';
import { Button } from "@mui/material";
import { UserContext } from "../UserContext";

export default function NotificationLinkItem(props) {
    const { user, onUserContextChanged } = React.useContext(UserContext);

    const handleQuoteClicked = () => {
    
        user.viewingQuote = props.quote;
        onUserContextChanged();
        if (props.onClose !== undefined) {
            props.onClose();
        }

        // if Draft, go to the Store so they can edit their quote
        if (user.viewingQuote.status == 'Draft') {
            navigate('/Portal/Store?open=true');
        }
        else {
            //show it here
            props.showQuoteDetail();
            if (props.onClose !== undefined) {
                props.onClose();
            }
        }
            
        
    };

    return (<div componentname='NotificationLinkItem' >
        <div style={{display:'flex', width:'100%'}}>
            <img src='/assets/images/bell.svg' style={{height:'16px',width:'14px',alignSelf:'center'}} />
            <div style={{width:'100%', alignSelf:'center', marginLeft:'10px', fontSize:'10pt', fontWeight:'bold'}}>
                {props.quote.status == 'ReadyForAcceptance' ? props.quote.intertekQuoteNumber :
                    props.quote.displayName === '' ? props.quote.quoteNumber :
                        props.quote.displayName}
            </div>
        </div>
        <div style={{paddingLeft:'17px'}}>
            <div style={{borderBottom:'1px solid #e5e5e5', marginTop:'10px', marginBottom:'0px'}} />
            <div>
                {props.quote.status == 'ReadyForAcceptance'  &&
                    <>Your Quote is Ready for Acceptance</>
                }
                {(props.quote.status == 'Paid' || (props.quote.status == 'QuotationCompleted' && props.quote.sampleSubmission == null)) &&
                    <>Waiting for Sample Submission</>
                }
                {props.quote.status == 'Accepted'  &&
                    <>Your Quote is waiting for payment</>
                }
            </div>
            <div style={{display:'flex', justifyContent:'flex-end'}}>
                <Button onClick={() => handleQuoteClicked()}>Proceed to Quote</Button>
            </div>
        </div>
    </div>)
}