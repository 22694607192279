import * as React from 'react';
import styles from './RecentQuotes.module.css';
import { UserContext } from '../../UserContext';
import CustomerService from '../../../services/CustomerService';
import IntertekLoading from '../../Common/IntertekLoading';

import dayjs from 'dayjs';
import {formatStatus} from '../../utils'; 
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import SlideInModal from '../../SlideInModal/SlideInModal';
import QuoteDetailsPanel from '../QuoteDetails/QuoteDetailsPanel';
import { useNavigate } from 'react-router-dom';

export default function RecentQuotes(props) {
    const { user, onUserContextChanged, userContextChanged } = React.useContext(UserContext);
    const [recentQuotes, setRecentQuotes] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [showQuoteDetail, setShowQuoteDetail] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        // initial load
        buildRecentQuotes();
    }, [])

    React.useEffect(() => {
        // also when userContext changes
        buildRecentQuotes();

    }, [userContextChanged])

    function buildRecentQuotes() {
        if (user !== null) {
            if (user.customerQuotes !== null && user.customerQuotes !== undefined) {
                // order by date 
                let tempArray = [...user.customerQuotes];
                tempArray.sort((a, b) => {
                    const dateA = new Date(a.statusDateTimeUTC);
                    const dateB = new Date(b.statusDateTimeUTC);
                    return dateA - dateB;
                });
                // reverse the order so most recente are on top
                tempArray.reverse();
                // Call setRecentQuotes with the top 15 items
                setRecentQuotes(tempArray.slice(0, 15));
            }
            else {
                setIsLoading(true);
                CustomerService.send('GET', '', 'CustomerQuote')
                    .then((response) => {
                        setIsLoading(false);
                        if (response !== null) {
                            if (response.result === "SUCCESS") {
                                if (response.customerQuotes !== null) {
                                    user.customerQuotes = response.customerQuotes;
                                    onUserContextChanged();
                                }
                            }
                        }

                    })
                    .catch((response) => {
                        setIsLoading(false);
                        if (response !== null) {
                            //toast.error(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred');
                        }
                        else {

                        }
                    });
            }
        }


    }

    function handleTableRowClicked(quote) {
        user.viewingQuote = quote;
        onUserContextChanged();
        
        // if Draft, go to the Store so they can edit their quote
        if (user.viewingQuote.status == 'Draft') {
            navigate('/Portal/Store?open=true');
        }
        else {
            setShowQuoteDetail(true);
        }
        
    }

    function handleCloseQuoteDetailsPanel() {
        setShowQuoteDetail(false);
    }

    return (<div className={styles.Container} style={{height:'auto'}} componentname='RecentQuotes'>
            {isLoading ?
                <div style={{ minHeight: '50vh', display: 'flex', justifyContent: 'center', backgroundColor: 'var(--modal-body-light)' }}>
                    <div style={{ alignSelf: 'center' }}>
                        <IntertekLoading />
                    </div>
                </div>
            :
                <>
                    <div className='Font-Md Text-Dark' style={{marginTop:'30px', marginLeft:'30px'}}>Recent Quotes</div>
                    <div style={{ marginTop: '15px', marginLeft:'30px', marginRight:'30px' }}>
                        <table className={styles.recentQuotesTable}>
                            <thead>
                                <tr>
                                    <th colSpan={2}>
                                        Quote Number
                                    </th>
                                    <th>
                                        Quote Name
                                    </th>
                                    <th>
                                        Date
                                    </th>
                                    <th style={{textAlign:'right'}}>
                                        Price
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {recentQuotes.map((quote, idx) => {
                                    let total = 0;
                                    for (let i = 0; i < quote.items.length; i++)
                                    {
                                        total += quote.items[i].price;   
                                    }
                                    return <tr key={'recentQuotes_' + idx} onClick={() => handleTableRowClicked(quote)}>
                                        <td style={{textAlign:'center', paddingTop:'3px'}}>{quote.isFavorite == false ? null : <img src='/assets/images/favorite_mouseover.png' style={{height:'24px', width:'24px'}} />}</td>
                                        <td style={{whiteSpace:'nowrap'}}>{quote.intertekQuoteNumber== undefined ? 'Pending' : quote.intertekQuoteNumber}</td>
                                        <td style={{maxWidth:'120px', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>{quote.displayName == '' ? quote.quoteNumber : quote.displayName}</td>
                                        <td>{dayjs(quote.statusDateTimeUTC).format('MMMM D, YYYY')}</td>
                                        <td style={{textAlign:'right'}}>${total.toFixed(2)}</td>
                                        <td>{formatStatus(quote)}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ height: '60px', borderTop: '1px solid white', display: 'flex', paddingLeft: '15px' }}>
                        {recentQuotes.length > 0 &&
                            <Button component={Link} to="/Portal/Quotes" style={{color:'var(--intertek-blue-8)'}}>
                                View All
                            </Button>
                        }
                    </div>
                </>
            }
            {showQuoteDetail &&
                <SlideInModal onClose={() => handleCloseQuoteDetailsPanel()}>
                    <QuoteDetailsPanel  storeItems={[]} onClose={() => handleCloseQuoteDetailsPanel()} />
                </SlideInModal>    
            }
    </div>)
}