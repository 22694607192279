import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const PdfGenerator = ({ data }) => {
    const [pdfUrl, setPdfUrl] = React.useState(null);
    const iframeRef = React.useRef(null);

    React.useEffect(() => {
        if (data !== undefined && data !== null) {
            setPdfUrl(generatePdf(false));
        }
    }, [data]);

    const generatePdf = (shouldDownload) => {
        const doc = new jsPDF();

        const addWatermark = () => {
            doc.setFontSize(200);
            doc.setGState(new doc.GState({ opacity: 0.3 }));
            doc.setTextColor('#939393');
            doc.text('Draft', 70, 190, { angle: 45 });
            doc.setGState(new doc.GState({ opacity: 1 }));
        };

        const addHeader = () => {
            doc.setFontSize(18);
            doc.setTextColor(0, 0, 0);
            doc.text('Project Fees', 14, 22);
            doc.setFontSize(11);
            doc.text('The fees below are based on the information provided on your request, assumptions as noted previously', 14, 30);
            doc.text('and are valid from 90 days from date of issuance.', 14, 35);
            //doc.addImage('/mnt/data/image.png', 'PNG', 150, 8, 40, 15); // Add your logo here
        };

        // Define the columns we want and their titles
        const columns = [
            { header: 'Item #', dataKey: 'itemNumber' },
            { header: 'Evaluation Standard/Description', dataKey: 'method' },
            { header: 'Priority', dataKey: 'priority' },
            { header: 'QTY', dataKey: 'quantity' },
            { header: 'Net Price', dataKey: 'price' }
        ];

        // Define the rows
        const rows = [];
        let total = 0;
        
        data.forEach(item => {
            item.custItm.forEach(subItem => {
                rows.push({
                    itemNumber: item.ItemNumber,
                    method: item.Method,
                    quantity: subItem.Quantity,
                    priority: item.Priority,
                    price: `$${subItem.Price.toFixed(2)}`
                });
                total += subItem.Price;

                rows.push({
                    itemNumber: '', method: '', priority: '', quantity: '', price: '', // Empty cells
                    customCell: {
                        content: ('Standard: ' + (item.storeItem.standard == undefined ? '' : item.storeItem.standard)) + '; '
                         + ('Revision: ' + (item.storeItem.revision == undefined ? '' : item.storeItem.revision)),
                        colSpan: 5
                    }
                });

                // rows.push({
                //     itemNumber: '', method: '', priority: '', quantity: '', price: '', // Empty cells
                //     customCell: {
                //         content: 'Revision: ' + item.storeItem.revision == undefined ? '' : item.storeItem.revision,
                //         colSpan: 5
                //     }
                // });

                if (item.storeItem.testParameters != '' && item.storeItem.testParameters != undefined) {
                    rows.push({
                        itemNumber: '', method: '', priority: '', quantity: '', price: '', // Empty cells
                        customCell: {
                            content: 'Test Parameters: ' + (item.storeItem.testParameters == undefined ? '' : item.storeItem.testParameters),
                            colSpan: 4
                        }
                    });
                }

                if (item.storeItem.customerNotes != '' && item.storeItem.customerNotes != undefined) {
                    rows.push({
                        itemNumber: '', method: '', priority: '', quantity: '', price: '', // Empty cells
                        customCell: {
                            content: 'Notes: ' + item.storeItem.customerNotes,
                            colSpan: 4
                        }
                    });
                }

            });
        });

        // Add total row
        rows.push({
            itemNumber: '',
            method: '',
            priority: '',
            quantity: 'Total',
            price: `$${total.toFixed(2)}`
        });

        const formattedRows = rows.map(row => {
            if (row.customCell) {
                // Add the custom cell as a single cell row
                return [
                    { content: '' },
                    {
                        content: row.customCell.content,
                        colSpan: row.customCell.colSpan,
                        styles: row.customCell.styles
                    }
                ];
            }
            return [
                { content: row.itemNumber, styles: {fillColor: [220, 220, 220]} },
                { content: row.method , styles: {fillColor: [220, 220, 220]}},
                { content: row.priority, styles: {fillColor: [220, 220, 220]} },
                { content: row.quantity , styles: {fillColor: [220, 220, 220]}},
                { content: row.price , styles: {fillColor: [220, 220, 220]}},
            ];
        });

        // Create table
        doc.autoTable({
            startY: 50,
            head: [columns.map(col => col.header)],
            body: formattedRows,
            headStyles: { fillColor: '#ffc700', textColor: '#000000' },
            theme: 'grid',
            margin: { top: 60 },
            didDrawPage: function (data) {
                // Add watermark and header on each new page
                addHeader();
              //  addWatermark();
            },
            columnStyles: {
                4: { halign: 'right' },  // Right align Net Price
                3: { halign: 'right' }   // Right align QTY
            },
            didParseCell: function (data) {
                if (data.row.index === rows.length - 1) {  // Check if it's the last row
                    data.cell.styles.fontStyle = 'bold';
                }
            }
        });

        // Generate PDF Blob
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob) + '#navpanes=0';
        return pdfUrl;
    };

    return (
        <div componentname="PdfGenerator">
           <div style={{ overflow: 'hidden', border: '1px solid #ccc', maxWidth:'90%', width: '700px', height: '600px', margin: 'auto' }}>
                <iframe
                    ref={iframeRef}
                    src={pdfUrl}
                    width="100%"
                    height="100%"
                    title="PDF Viewer"
                    style={{ border: 'none' }}
                />
            </div>
        </div>
    );
};

export default PdfGenerator;
