import React, { useState, useCallback, useEffect } from 'react';

// Create the UserContext
const UserContext = React.createContext();

const UserProvider = ({ children, value }) => {
    const user = value === null ? null : value.user;
    const [userContextChanged, setUserContextChanged] = useState(null);
    const [storeItems, setStoreItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [token, setToken] = useState(localStorage.getItem('CustomerToken'));

    const onUserContextChanged = useCallback(() => {
        setUserContextChanged(new Date());
    }, []);

    const onQuoteChanged = useCallback((quote) => {
        if (user !== null) {
            if (user.customerQuotes !== null && user.customerQuotes !== undefined) {
                let isFound = false;
                for (let i = 0; i < user.customerQuotes.length; i++) {
                    if (user.customerQuotes[i].id === quote.id) {
                        isFound = true;
                        console.log('onQuoteChanged: quote ' + quote.quoteNumber + ' updated');
                        user.customerQuotes[i] = quote;
                        if (user.viewingQuote !== undefined && user.viewingQuote && user.viewingQuote.id === quote.id) {
                            user.viewingQuote = quote;
                            console.log('onQuoteChanged: viewingQuote updated');
                        }
                        break;
                    }
                }
                if (!isFound) {
                    console.log('onQuoteChanged: quote not found');
                }
                else {
                    setUserContextChanged(new Date());
                }
            }
            else {
                console.log('onQuoteChanged: user.customerQuotes is null or undefined');
            }
        }
        else {
            console.log('onQuoteChanged: user is null');
        }
    }, [user]);

    useEffect(() => {
        if (!user) return;

        const handleQuoteUpdated = (event) => {
            let quote = event.detail;
            if (typeof quote === 'string') {
                quote = JSON.parse(quote);
            }
            onQuoteChanged(quote);
        };

        window.addEventListener('quoteUpdated', handleQuoteUpdated);

        return () => {
            window.removeEventListener('quoteUpdated', handleQuoteUpdated);
        };
    }, [onQuoteChanged, user]);

    const contextValue = {
        user,
        setUser: value.setUser,
        onUserContextChanged,
        userContextChanged,
        onQuoteChanged,
        storeItems,
        setStoreItems,
        categories,
        setCategories,
    };

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };
