/* eslint-disable react/no-unknown-property */
import PdfGenerator from '../../../PdfGenerator/PdfGenerator';
import * as React from 'react';
import { UserContext } from '../../../UserContext';
import CustomerService from '../../../../services/CustomerService';

export default function DraftView(props) {

    const { user, onUserContextChanged, userContextChanged, storeItems, setStoreItems } = React.useContext(UserContext);
    const [isLoading, setIsLoading] = React.useState(false);
    

    React.useEffect(() => {
        // handles routing directly to this page without going to /Portal
        if (user !== null && user !== null) {

            if (storeItems.length == 0) {
                setIsLoading(true);
                CustomerService.send('GET', '', 'Category/Customer')
                    .then((response) => {
                        setIsLoading(false);
                        if (response !== null) {
                            if (response.result === "SUCCESS") {
                                // setCategories(response.categories == null ? [] : response.categories);

                                // create a categoryName property on the storeItem
                                for (let i = 0; i < response.storeItems.length; i++) {
                                    for (let j = 0; j < response.categories.length; j++) {
                                        if (response.storeItems[i].categoryId == response.categories[j].id) {
                                            response.storeItems[i].categoryName = response.categories[j].name;
                                            break;
                                        }
                                    }
                                }

                                setStoreItems(response.storeItems == null ? [] : response.storeItems);
                            }
                        }

                    })
                    .catch((response) => {
                        setIsLoading(false);
                        if (response !== null) {
                            //toast.error(response.error !== undefined ? response.error : response.message !== undefined ? response.message : 'An error occurred');
                        }
                        else {

                        }
                    });
            }
        }
    }, [])

    function getStoreItemById(storeItemId) {
        if (storeItems !== undefined) {
            for (let i = 0; i< storeItems.length; i++) {
                if (storeItems[i].id == storeItemId)
                    {
                        return storeItems[i];
                    }
            }
        }
        return null;
    }

    var data = [];
    if (user.viewingQuote !== null) {
        for (let i = 0; i < user.viewingQuote.items.length; i++) {
            let storeItem = getStoreItemById(user.viewingQuote.items[i].storeItemId);
            let dataItem = {
                ItemNumber: (i + 1),
                Method: storeItem === null ? 'Unknown' : storeItem.method,
                Priority: user.viewingQuote.items[i].priority == 'Standard' ? '' : user.viewingQuote.items[i].priority,
                custItm: [],
                storeItem: storeItem
            }
            dataItem.custItm.push({ Quantity: user.viewingQuote.items[i].quantity, Price: user.viewingQuote.items[i].price })

            data.push(dataItem);
        }
    }

   
    return (<div componentname='DraftView'>
        <div>
            {storeItems.length == 0 ? null :
                <PdfGenerator data={data} />
            }
        </div>
    </div>)
}