/* eslint-disable react/prop-types */
import Button from '@mui/material/Button';
import styles from './Buttons.module.css';
import { styled } from '@mui/material/styles';


const ColorButton = styled(Button)(({ theme }) => ({
    color: 'var(--intertek-blue-7)',
    backgroundColor: 'var(--intertek-blue-8)',
    '&:hover': {
        backgroundColor: 'var(--intertek-blue-8)',
        boxShadow: 'none'
    },
    padding: '10px 25px !important',
    margin: '2px',
  
    fontSize: '18px',
    letterSpacing: '-0.03em',
    borderRadius: '100px',
    minHeight: '58px',
    fontFamily: 'Neo Sans',
    minWidth: '140px',
    textTransform: 'none',
    boxShadow: 'none'
}));
export default function BlueButton(props) {
    const { children, onClick, ...otherProps } = props;

    function handleClick(e) {
        if (onClick) {
            onClick(e);
        }
    }

    return (
        <ColorButton
            onClick={handleClick}
            className={`${styles.ButtonBase} ${styles.ButtonYellow}`}
            variant="contained"
            {...otherProps} // Pass down all other props to the Button component
        >
            {children}
        </ColorButton>
    );
}
