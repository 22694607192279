import { useEffect, useState } from 'react';

/// props:
/// src: the URL of the PDF file to display
export default function PdfFileViewer(props) {
  const [pdfUrl, setPdfUrl] = useState(null);
  const token = localStorage.getItem('CustomerToken'); // Get the token from local storage

  useEffect(() => {
    const fetchPdf = async () => {
      const response = await fetch(
        props.src,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);
    };

    fetchPdf();
  }, []);

  if (!pdfUrl) {
    return <div>Loading...</div>;
  }

  return (
    <iframe
      src={pdfUrl}
      width="100%"
      height="100%"
      title="PDF Viewer"
      style={{ border: 'none' }}
    />
  );
};


